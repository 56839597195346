import createPersistedState from "vuex-persistedstate"

import {
  createStore
} from 'vuex'
import {
  getCommunication
} from "../api/topics";
import {
  readCommunication
} from "../api/topics";
export default createStore({
  state: {
    answer_data: {
      ancient_communication: {
        total: '0',
      },
      original_communication: {
        BaseData: '',
      },
      old_communication: {
        total: '',
        correct: '',
        error: '',

        BaseData: {
          total: '',
          correct: '',
          error: '',
          question_answer: '',
          total_question: '',
          correct_question: '',
          error_question: '',
          bricknum: '',
          count_total:'',
          count_correct:'',
          count_error:'',

        },

      },
      early_communication: {
        BaseData: '',
        question_sign_num: '',
        answer_sign_num: ''
      },
      early_modern_communication: {
        BaseData: '',
        connection: '',
        optimal: ''
      },
      modern_communication: {
        total: '',
        correct: '',
        error: '',
        correct_question: '',
        error_question: ''
      },
    }, //需要保存的答案
    task_id: 1, //下发任务
    template: 1, //导航栏进
    num: null, //远古通信点击次数
    code: null, //接口状态码
    taskId: null, //跳转后url传参
    template_name: null, //跳转后url传参
    template_id: null, //跳转后url传参
    user_token: null, //跳转后url传参
    user_id: null, //跳转后url传参
    grade_id: null, //跳转后url传参
    taskInfo: null, //跳转后url传参
    realname:null,//跳转后url传参 用户名
    EchoArrLiat: [], //
    pigeonEchoArr:[],
    special_plane:null,

  },
  mutations: {
    //远古通信信息存储
    sum_actions(state, val) {
      state.num = val
      state.answer_data.ancient_communication.total = val //点击总次数
      // console.log(state.answer_data)
    },
    //原始通信信息存储
    sum_original(state, val) {
      state.answer_data.original_communication.BaseData = val //原始通信数据
      // console.log(state.answer_data.original_communication.BaseData)
      // console.log(state.answer_data)
    },
    //古代通信信息存储
    sum_ancientpage(state, val) {
      console.log(state.answer_data.old_communication,val,'222')
      state.answer_data.old_communication.total = val[8]; //做过的总数
      state.answer_data.old_communication.correct = val[9]; //正确个数
      state.answer_data.old_communication.error = val[10]; //错误个数
      // console.log(state.answer_data.old_communication.BaseData)
      state.answer_data.old_communication.BaseData.total = val[0]; //做过的总数
      state.answer_data.old_communication.BaseData.correct = val[2]; //正确个数
      state.answer_data.old_communication.BaseData.error = val[1]; //错误个数
      state.answer_data.old_communication.BaseData.question_answer = val[6]; //做过的题数
      state.answer_data.old_communication.BaseData.total_question = val[3]; //总题库里剩下的提
      state.answer_data.old_communication.BaseData.correct_question = val[4]; //正确的
      state.answer_data.old_communication.BaseData.error_question = val[5]; //错误的
      state.answer_data.old_communication.BaseData.bricknum = val[7]; //砖块的数量
      // console.log(state.answer_data)
      state.answer_data.old_communication.BaseData.count_total = val[8]; //做过的总数
      state.answer_data.old_communication.BaseData.count_correct = val[9]; //正确个数
      state.answer_data.old_communication.BaseData.count_error = val[10]; //错误个数
    },
    //近代通信信息存储
    sum_modern(state, val) {
      state.answer_data.early_communication.question_sign_num = val[0]; //做过的能识别的总数
      state.answer_data.early_communication.answer_sign_num = val[1]; //做过的正确的总数
      state.answer_data.early_communication.BaseData = val[2]; //所有数据
      // console.log(state.answer_data)
    },
    //近现代信息存储
    sum_modernTimes(state, val) {
      // console.log(val)
      state.answer_data.early_modern_communication.BaseData = val[0] //后台返回的所有数据信息
      state.answer_data.early_modern_communication.connection = val[1] //是否能到达终点
      state.answer_data.early_modern_communication.optimal = val[2] //是否是最优路线
      // console.log(state.answer_data)
    },
    // 现代通信信息存储
    sum_modernCommuni(state, val) {
      state.answer_data.modern_communication.total = val[0] //做过的总题数
      state.answer_data.modern_communication.correct = val[1] //做过的正确题数
      state.answer_data.modern_communication.error = val[2] //做过的错误的题数
      state.answer_data.modern_communication.correct_question = val[3] //做过的正确的题目数量
      state.answer_data.modern_communication.error_question = val[4] //做过的错误的题目数量
      // console.log(state.answer_data)
    },

    // 保存数据发给后端接口
    sum_communication(state) {
      let datatimes = new FormData();
      datatimes.append("user_id", state.user_id);
      datatimes.append("data_sign", "communication_history");
      datatimes.append("answer_data", JSON.stringify(state.answer_data));
      datatimes.append("task_id", state.taskId);
      datatimes.append("template_id", state.template_id);
      //调用接口
      getCommunication(datatimes).then((res) => {
        // console.log(datatimes)
        state.code = res.status
        // console.log(res.status)
      });
    },


    //先取出总数据给到默认值 原始通信
    AggregateData(state) {
      let datatimes = new FormData();
      datatimes.append("user_id", state.user_id);
      datatimes.append("data_sign", "communication_history");
      datatimes.append("task_id", state.taskId);
      datatimes.append("template_id", state.template_id);
      datatimes.append("value_sign", "original_communication");
      readCommunication(datatimes).then((res) => {
        state.answer_data.original_communication.BaseData = res.data.data
        // console.log(res.data.data)
      });
    },


    //先取出总数据给到默认值 古代通信
    CommunicationData(state) {
      let datatimes = new FormData();
      datatimes.append("user_id", state.user_id);
      datatimes.append("data_sign", "communication_history");
      datatimes.append("task_id", state.taskId);
      datatimes.append("template_id", state.template_id);
      datatimes.append("value_sign", "old_communication");

      readCommunication(datatimes).then((res) => {
        state.answer_data.old_communication.BaseData.total = 0; //做过的总数
        state.answer_data.old_communication.BaseData.correct = 0; //正确个数
        state.answer_data.old_communication.BaseData.error = 0; //错误个数
        state.answer_data.old_communication.BaseData.total_question = res.data.data; //总题库里剩下的题
        state.answer_data.old_communication.BaseData.bricknum = [{}, {}, {}, {}, {}, {}]; //砖块的数量
        // console.log(res.data.data)
      });
    },

    //先取出总数据给到默认值 近代通信
    ModernData(state) {
      let datatimes = new FormData();
      datatimes.append("user_id", state.user_id);
      datatimes.append("data_sign", "communication_history");
      datatimes.append("task_id", state.taskId);
      datatimes.append("template_id", state.template_id);
      datatimes.append("value_sign", "early_communication");

      readCommunication(datatimes).then((res) => {
        state.answer_data.early_communication.BaseData = res.data.data; //所有数据
        // console.log(res.data.data.input_mose，)
      });
    },

  },
  actions: {

  },
  modules: {},
  plugins: [createPersistedState({

    storage: window.sessionStorage
  })]

})
