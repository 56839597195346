


// 时间格式转换 YYYY-MM-DD hh:mm:ss
//
//  getDayTimer (data) data 接收 当前时间
// 使用方法：
// import {getDayTimer} from "../utils/tools.js"
// getDayTimer(new Date())
function getDayTimer (data) {
  var date = new Date(data)
  var y = date.getFullYear()
  var m = date.getMonth() + 1
  m = m < 10 ? ('0' + m) : m
  var d = date.getDate()
  d = d < 10 ? ('0' + d) : d
  var currentdate = y + '-' + m + '-' + d;
  var hh = date.getHours()
  hh = hh < 10 ? ('0' + hh) : hh
  var mm = date.getMinutes()
  mm = mm < 10 ? ('0' + mm) : mm
  var ss = date.getSeconds()
  ss = ss < 10 ? ('0' + ss) : ss
  var time = hh + ':' + mm + ':' + ss;
  return currentdate + " " + time
}
export {getDayTimer};