<template>
  <!-- <span>古代通信</span> -->
  <div class="basemap" ref="back_box">
    <img
      class="baseimg"
      src="../../../assets/img/imgdevelopment/ancientpeople/basemap.jpg"
      alt=""
    />

    <!-- 烽火 -->
    <img class="flames" v-show="flames" :src="flamesimg" alt="" />

    <!-- 答题卡槽 -->
    <el-card class="box-card" v-show="boxshow">
      <img
        class="box-back"
        src="../../../assets/img/imgdevelopment/ancientpeople/backgroundimg.png"
        alt=""
      />
      <!-- 题目 -->
      <span class="characters">{{ tense.question }}</span>
      <!-- 选项 -->
      <div class="radiobox">
        <el-radio-group v-model="radio" class="ml-4">
          <el-radio
            :label="index"
            size="large"
            v-for="(item, index) in tenseArray"
            :key="index"
            >{{ tenseArray[index] }}
          </el-radio>
        </el-radio-group>
      </div>
      <el-button
      :loading="SSG"
        type="success"
        class="Butsubmit"
        :disabled="disa"
        @click="radioclick(tenseArray[radio])"
        >提&emsp;&emsp;交</el-button
      >

      <div class="incoleft">
        <!-- 正确数量 -->
        <div style="position: absolute; left: 64%; top: 82%">
          <img
            style="width: 18%"
            class="inco"
            src="../../../assets/img/imgdevelopment/ancientpeople/correct.png"
            align="bottom"
            alt=""
          />
          <span
            style="
              font-size: calc(100vw * 26 / 1920);
              font-weight: 600;
              left: 24%;
              top: 24%;
              position: absolute;
            "
            >{{ correct }}道</span
          >
        </div>
        <!-- 错误数量 -->
        <div style="position: absolute; left: 82%; top: 82%">
          <img
            style="width: 36%"
            class="inco"
            src="../../../assets/img/imgdevelopment/ancientpeople/mistake.png"
            align="absmiddle"
            alt=""
          />
          <span
            style="
              font-size: calc(100vw * 26 / 1920);
              font-weight: 600;
              left: 46%;
              top: 24%;
              position: absolute;
            "
            >{{ mistake }}道</span
          >
        </div>
      </div>
    </el-card>

    <!-- 砖块区域 -->
    <div class="citywall">
      <img
        class="brick"
        src="../../../assets/img/imgdevelopment/ancientpeople/rampart.png"
        alt=""
        v-for="(item, index) in pictureArray"
        :key="index"
      />
    </div>

    <!-- 开始图片 -->
    <!-- <el-button
      class="restartbut"
      type="primary"
      @click="record"
      v-if="dialogVisibles"
      >开&ensp;&ensp;&ensp;&ensp;始</el-button
    > -->
    <div>
      <!-- 小唐人logo -->
      <img
        class="dialong3"
        @click="showif"
        src="../../../assets/img/imgdevelopment/ancientpeople/logo.png"
        alt=""
      />
      <!-- 小唐人弹出框 -->
      <div class="logodialogue" v-show="dialogVisible">
        <span>
          在战乱频发的周朝，为将边疆战况快速地向皇城传递，人们发明了烽火台、飞鸽传书等通信方式。请你作为一名边疆将领，通过对右侧信息通信认知的正确选择，实现对左侧烽火台城墙不断加固，直至城墙砖砌满整个烽火台，你将取得这次战争的胜利。否则城墙砖被全部摧毁，战争失败。
        </span>
        <div class="logodialoguex"></div>
      </div>

      <!-- 结束 -->
      <!-- <div class="logodialogue" v-show="indialogVisible">
        <span>
          &emsp;
          古代的人们利用烽火台、锣鼓传递军情，利用飞鸽传书、驿站传递信息，采用烽烟、锣鼓、书信等作为媒介传递信息，一级一级的进行传递，能够传递很远的距离。然而这类通信的弊端也比较明显，例如烽火台的造价成本昂贵，信息传递易受天气、地形的影响，并且还难以做到迅速的传递。以烽火台为例，单个烽火台之间的距离大约是5里，是人眼眺望辨清事物的极限距离，以最快的速度计算，蓟镇总兵戚继光在《练兵纪实》中说烽火传遍整个蓟镇防线需要约六个小时的时间。因此凭借物体媒介，人们还是无法保证远距离信息传递的时效性。
          到了近代，随着科技的不断发展，人们又发现了一种看不见、摸不着的传递信息的方式。
        </span>
        <div class="logodialoguex"></div>
      </div> -->
    </div>

    <!-- 炮弹动画效果 -->
    <div v-if="animation">
      <img
        class="bullet"
        src="../../../assets/img/imgdevelopment/ancientpeople/bullet.png"
        alt=""
      />
      <!-- 爆炸效果 -->
      <img
        class="bullet2"
        src="../../../assets/img/imgdevelopment/ancientpeople/blast.png"
        alt=""
      />
    </div>
    <!-- 答题失败弹出框 -->
    <el-dialog
      v-model="defeated"
      title="小唐人提示"
      width="30%"
      :before-close="handleClose"
    >
      <img
        style="width: 50%; margin-left: 24%"
        src="../../../assets/img/imgdevelopment/ancientpeople/failure.png"
        alt=""
      />
      <hr style="width: 90%; background-color: #e4e4e3; margin: auto" />
      <h3 style="text-align: center; margin: 2%; color: #0074c5">
        您的堡垒已被击垮，战争失败！
      </h3>
      <template #footer>
        <span class="dialog-footer">
          <el-button
            style="
              background: #0074c5;
              width: 28%;
              margin: auto;
              display: block;
            "
            type="primary"
            @click="confirmbutna"
            >确&ensp;&ensp;&ensp;定</el-button
          >
        </span>
      </template>
    </el-dialog>

    <!-- 答题成功弹出框 -->
    <el-dialog
      v-model="defbox"
      title="小唐人提示"
      width="30%"
      :before-close="handleClose"
    >
      <img
        style="width: 50%; margin-left: 24%"
        src="../../../assets/img/imgdevelopment/ancientpeople/succeed.png"
        alt=""
      />
      <hr style="width: 90%; background-color: #e4e4e3; margin: auto" />
      <h3 style="text-align: center; margin: 2%; color: #0074c5">
        您的堡垒加固成功，战争胜利！
      </h3>
      <h3 style="margin: 2%">
        <span style="color: #ec4515; margin-left: 12.5%;font-size: calc(100vw * 20 / 1920);">古代通信优点：</span>
        能够远距离传递信息
      </h3>
      <h3 style="">
        <span style="color: #40b489; margin-left: 14%;font-size: calc(100vw * 20 / 1920);">古代通信缺点：</span>
        <p style="margin-left: 40%; margin-top: -4%">
          有距离限制，必须在可视距离内设置烽火；有天气限制，阴雨雾天传递效果不佳；传递信息不明确。
        </p>
      </h3>
      <template #footer>
        <span class="dialog-footer">
          <!-- <el-button
            style="margin-right: 22%; width: 28%; background: #549335"
            type="success"
            @click="restart"
            >重来一次</el-button
          > -->
          <el-button
            style="
              background: #0074c5;
              width: 28%;
              margin: auto;
              display: block;
            "
            type="primary"
            @click="confirmbut"
            >确&ensp;&ensp;&ensp;定</el-button
          >
        </span>
      </template>
    </el-dialog>

    <!-- 重新开始按钮 -->
    <!-- <el-button
      class="restartbut"
      type="primary"
      @click="restartbut"
      v-if="restartshow"
      >重&ensp;新&ensp;开&ensp;始</el-button
    > -->
    <!-- 答题按钮 -->
    <!-- <div>
      <button class="primary" @click="succeed">答题成功</button>
      <button class="primary1" @click="fail">答题失败</button>
    </div> -->
  </div>
</template>
<script>
// 引入接口
import { getBefore } from "../../../api/topics";
import { readCommunication } from "../../../api/topics";
import { holographic } from "../../../api/topics";
// import {Debounce,Throttle}  from '../../../componsables/clickOnceAtime'
export default {
  data() {
    return {
      SSG: false,
      boxshow: true, //答题显示隐藏
      defbox: false, //答题成功弹框
      radio: null,
      flames: true,
      dialogVisible: true,
      dialogVisibles: true,
      defeated: false, //答题失败弹框
      animation: false,
      flamesimg: require("../../../assets/img/imgdevelopment/ancientpeople/flames2.png"),
      pictureArray: [{}, {}, {}, {}, {}, {}],
      Amount: [], //题库总数量
      correctAmount: [], //正确的题库
      errAmount: [], //错误的题库
      Amountis: [], //做过的题库
      tense: [], //正在进行
      tenseArray: [], //答案数组
      box: "", //随机数
      mistake: 0, //错误的数量
      correct: 0, //正确的数量
      disa: false, //禁用按钮
      restartshow: false, //重新开始按钮
      tolo: null, //总数
      echodata: null,
      count_total:null,
      count_correct:0,
      count_error:0,
    };
  },

  components: {},

  methods: {
    // 点击提交答案
    radioclick(val) {
      if (this.radio != null) {
        this.SSG = true;
        // 正确时候
        if (this.tense.answer == val) {
          // 将题目从总题目中删除
          this.Amount.splice(this.box, 1);
          // 将删除的题目添加到正确的数组中
          this.correctAmount.push(this.tense);
          this.Amountis.push(this.tense);
          // 正确数量+1
          this.correct++;
          this.succeed();
          if (this.pictureArray.length != 20) {
            // 再次调用接口 获取新的题目
            setTimeout(this.newtopic, 2000);
          }
        }
        // 错误的时候
        if (this.tense.answer != val) {
          // 将题目从总题目中删除
          this.Amount.splice(this.box, 1);
          // 将删除的题目添加到错误的数组中
          this.errAmount.push(this.tense);
          this.Amountis.push(this.tense);
          // 错误数量+1
          this.mistake++;
          this.fail();
          // 再次调用接口 获取新的题目
          setTimeout(this.newtopic, 3000);
        }
        setTimeout(() => {
          this.SSG = false;
        }, 3000);
      }
      this.tolo = this.mistake + this.correct;
      // console.log(this.tolo)
      // 调用VUEX保存数据

      this.$store.commit("sum_ancientpage", [
        this.tolo,
        this.mistake,
        this.correct,
        this.Amount,
        this.correctAmount,
        this.errAmount,
        this.Amountis,
        this.pictureArray,
        this.count_total,
        this.count_correct,
        this.count_error,
      ]);
    },
    // 开始按钮的显示隐藏
    // record() {
    //   this.Answer();
    //   this.dialogVisibles = false;
    //   setTimeout(() => {
    //     this.boxshow = true;
    //   }, 1000);
    //   this.dialogVisible = false;
    // },
    //答题成功
    succeed() {
      if (this.pictureArray.length < 20) {
        this.pictureArray.push({});
        if (this.pictureArray.length <= 4) {
          this.flamesimg = require("../../../assets/img/imgdevelopment/ancientpeople/flames1.png");
        } else if (this.pictureArray.length <= 8) {
          this.flamesimg = require("../../../assets/img/imgdevelopment/ancientpeople/flames2.png");
        } else if (this.pictureArray.length <= 12) {
          this.flamesimg = require("../../../assets/img/imgdevelopment/ancientpeople/flames3.png");
        } else if (this.pictureArray.length <= 16) {
          this.flamesimg = require("../../../assets/img/imgdevelopment/ancientpeople/flames4.png");
        } else if (this.pictureArray.length >= 20) {
          // 给算分复赋值
          this.count_total = this.correct + this.mistake ;
          this.count_correct = this.correct;
          this.count_error = this.mistake;
          //弹框显示隐藏
          this.flames = false;
          this.defbox = true;
          this.disa = true;

        } else {
          this.flamesimg = require("../../../assets/img/imgdevelopment/ancientpeople/flames5.png");
        }
      }
      // this.$store.commit("sum_ancientpage", [
      //     this.tolo,
      //     this.mistake,
      //     this.correct,
      //     this.Amount,
      //     this.correctAmount,
      //     this.errAmount,
      //     this.Amountis,
      //     this.pictureArray,
      //     this.count_total,
      //     this.count_correct,
      //     this.count_error,
      //     ]);
    },
    //答题失败
    fail() {
      if (this.pictureArray.length >= 0) {
        setTimeout(this.getElevatorList, 4000);
        setTimeout(this.switchover, 2000);
        this.animation = true;
        // this.pictureArray.pop();
        if (this.pictureArray.length <= 1) {
          // 给算分复赋值
        this.count_total = this.correct + this.mistake ;
        this.count_correct = this.correct
        this.count_error = this.mistake
          setTimeout(this.displayshow, 3000);
          this.disa = true;

        } else if (this.pictureArray.length <= 5) {
          this.flamesimg = require("../../../assets/img/imgdevelopment/ancientpeople/flames1.png");
        } else if (this.pictureArray.length <= 9) {
          this.flamesimg = require("../../../assets/img/imgdevelopment/ancientpeople/flames2.png");
        } else if (this.pictureArray.length <= 13) {
          this.flamesimg = require("../../../assets/img/imgdevelopment/ancientpeople/flames3.png");
        } else if (this.pictureArray.length <= 17) {
          this.flamesimg = require("../../../assets/img/imgdevelopment/ancientpeople/flames4.png");
        } else {
          this.flamesimg = require("../../../assets/img/imgdevelopment/ancientpeople/flames5.png");
        }
      }
      // this.$store.commit("sum_ancientpage", [
      //     this.tolo,
      //     this.mistake,
      //     this.correct,
      //     this.Amount,
      //     this.correctAmount,
      //     this.errAmount,
      //     this.Amountis,
      //     this.pictureArray,
      //     this.count_total,
      //     this.count_correct,
      //     this.count_error,
      // ]);
    },
    // 调用答题接口
    Answer() {
      this.radio = null;
      //当总题库长度大于0,执行
      getBefore().then((res) => {
        //获取接口数据
        const obj = JSON.parse(res.data.data); //这个是正确的转换
        // 所有题数
        this.Amount = obj;
        if (this.Amount.length >= 1) {
          // 随机数  从50道题中随机抽取一题
          this.box = Math.floor(Math.random() * this.Amount.length);
          // 随机出的题目
          this.tense = this.Amount[this.box];
          // 答案伪数组转真数组
          this.tenseArray = this.tense.option;
        }
        if (this.Amount.length < 1) {
          // 随机数  从50道题中随机抽取一题
          this.box = Math.floor(Math.random() * this.errAmount.length);
          // 随机出的题目
          this.tense = this.errAmount[this.box];
          // 答案伪数组转真数组
          this.tenseArray = this.tense.option;
        }
      });
    },

    //获取新的题目
    newtopic() {
      this.radio = null;
      if (this.Amount.length >= 1) {
        // 随机数  从50道题中随机抽取一题
        this.box = Math.floor(Math.random() * this.Amount.length);
        // 随机出的题目
        this.tense = this.Amount[this.box];
        // 答案伪数组转真数组
        this.tenseArray = this.tense.option;
      }
      if (this.Amount.length < 1) {
        // 随机数  从50道题中随机抽取一题
        this.box = Math.floor(Math.random() * this.errAmount.length);
        // 随机出的题目
        this.tense = this.errAmount[this.box];
        // 答案伪数组转真数组
        this.tenseArray = this.tense.option;
      }
    },
    // 定时器函数   动画
    getElevatorList() {
      if (this.animation == true) {
        this.animation = false;
      }
    },
    //定时器函数  砖块删除
    switchover() {
      this.pictureArray.pop();
    },
    //定时器函数  弹框显示
    displayshow() {
      this.defeated = true;
    },
    //错误弹框
    restart() {
      this.defeated = false;
      this.$router.go(0);
    },
    // 成功弹框确定按钮
    confirmbut() {
      this.defbox = false;
      this.defeated = false;

      let data = "Replay?3;"
      let obj = new FormData();
      obj.append("data", data);
      holographic(obj).then((res) => {
        console.log(res)
      });
      // this.restartshow = true;
    },
    // 失败弹框确定按钮
    confirmbutna() {
      this.defbox = false;
      this.defeated = false;
      this.restartshow = true;
    },
    //小唐人对话显示隐藏
    showif() {
      this.dialogVisible = !this.dialogVisible;
      this.indialogVisible = false;
    },
    //重新开始按钮
    restartbut() {
      this.$router.go(0);
    },
    //回显接口
    connector() {
      let datatimes = new FormData();
      datatimes.append("user_id", this.$store.state.user_id);
      datatimes.append("data_sign", "communication_history");
      datatimes.append("task_id", this.$store.state.taskId);
      datatimes.append("template_id", this.$store.state.template_id);
      datatimes.append("value_sign", "old_communication");
      readCommunication(datatimes).then((res) => {
        // console.log(JSON.parse(res.data.data))
        // console.log(res.data)
        this.echodata = res.data.data;
        if (
          this.echodata != null &&
          this.echodata != "" &&
          // this.echodata.length != 83
          this.echodata.constructor == Object
        ) {
          this.mistake = this.echodata.error;
          this.correct = this.echodata.correct;
          this.Amount = this.echodata.total_question;
          this.pictureArray = this.echodata.bricknum;

          if (this.pictureArray.length == 0) {
            this.disa = true;
          } else if (this.pictureArray.length == 20) {
            this.disa = true;
            this.flames = false;
          }
          if (this.pictureArray.length < 20) {
            if (this.pictureArray.length <= 4) {
              this.flamesimg = require("../../../assets/img/imgdevelopment/ancientpeople/flames1.png");
            } else if (this.pictureArray.length <= 8) {
              this.flamesimg = require("../../../assets/img/imgdevelopment/ancientpeople/flames2.png");
            } else if (this.pictureArray.length <= 12) {
              this.flamesimg = require("../../../assets/img/imgdevelopment/ancientpeople/flames3.png");
            } else if (this.pictureArray.length <= 16) {
              this.flamesimg = require("../../../assets/img/imgdevelopment/ancientpeople/flames4.png");
            } else if (this.pictureArray.length >= 20) {
              this.flames = false;
              this.disa = true;
            } else {
              this.flamesimg = require("../../../assets/img/imgdevelopment/ancientpeople/flames5.png");
            }
          }
        }
      });
    },
  },
  created() {
    this.Answer();
  },
  mounted() {
    this.connector();
  },
};
</script>
<style  scoped lang="scss">
.basemap {
  width: 100%;
  /* height: 120%; */
  position: relative;
  box-sizing: border-box;
  padding: 10px;
}
/* 底图img */
.baseimg {
  width: 100%;
  /* height: 100%; */
  position: relative;
  box-sizing: border-box;
}
/* 烽火 */
.flames {
  width: 6%;
  height: 20%;
  left: 18%;
  top: 4%;
  position: absolute;
}
/* 答题卡片 */
.box-card {
  width: 50%;
  height: 44%;
  border-radius: 30px;
  position: absolute;
  right: 4%;
  top: 4%;
}
// 答题背景
.box-back {
  width: 103%;
  position: absolute;
  right: -2%;
  top: -2%;
  height: 106%;
}
/* 开始按钮 */
.start {
  position: absolute;
  width: 14%;
  right: 39%;
  top: 82%;
  cursor: pointer;
}
/* 小唐人 */
.dialong3 {
  position: absolute;
  left: 1%;
  top: 4%;
  width: 6%;
  cursor: pointer;
}
/* 城墙 */
.citywall {
  width: 30%;
  height: 50%;
  background: rgba(194, 61, 61, 0);
  position: absolute;
  left: 6.5%;
  top: 46%;
  transform: rotateX(180deg); /* 垂直镜像翻转 */
}

/* 砖 */
.brick {
  width: 24%;
  height: 18%;
}
/* 炮弹 */
.bullet {
  width: 5%;
  height: 12%;
  position: absolute;
  right: 19.4%;
  top: 55%;
  z-index: 9999;
  animation-name: example;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
}

@keyframes example {
  0% {
    right: 19.4%;
    top: 54%;
    background-image: url(../../../assets/img/imgdevelopment/ancientpeople/bullet.png)
      no-repeat;
    background-size: 100% 100%;
  }
  96% {
    opacity: 1;
  }
  100% {
    right: 62%;
    top: 38%;
    transform: rotate(-1460deg);
    opacity: 0;
  }
}

/* 爆炸效果 */
.bullet2 {
  width: 18%;
  height: 38%;
  position: absolute;
  right: 56%;
  top: 26%;
  animation-name: mymove;
  animation-duration: 1.8s;
  animation-timing-function: linear;
  opacity: 0;
}

@keyframes mymove {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  65% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
// 答题文字
.characters {
  position: absolute;
  margin: auto;
  left: 10%;
  top: 14%;
  font-size: calc(100vw * 24 / 1920);
  font-weight: 600;
  z-index: 10;
}
//提交按钮
.Butsubmit {
  position: absolute;
  left: 33%;
  top: 81%;
  width: 23%;
  height: 12%;
  background: #7c3929 !important;
  font-size: calc(100vw * 16 / 1920);
  border-radius: 3px !important;
  z-index: 10;
}
/deep/ .el-button:not(.is-text,.is-link,.el-button--text){
  border: 1px solid #7c3929;
  background: #7c3929 !important;
}
.el-button:not(.is-text,.is-link,.el-button--text):focus, .el-button:not(.is-text,.is-link,.el-button--text):hover{
  border: 1px solid #7c3929;
  background: #7c3929 !important;
}
/deep/.el-button > span {
  font-size: calc(100vw * 22 / 1920) !important;
}
.butsubmit:hover {
  background: #7c3929;
}
.butsubmit:visited {
  background: #7c3929;
}
// 对错图标
.inco {
  left: 0%;
  top: 0%;
}
.incoleft {
  position: absolute;
  width: 95%;
  height: 86%;
  // display: block;
}
//答题区域
.radiobox {
  width: 90%;
  height: 56%;
  position: absolute;
  top: 24%;
  left: 5%;
  overflow: hidden;
  overflow-y: auto;
  // overflow-x: auto;
  z-index: 99;
}
/* 滚动条样式 */
div::-webkit-scrollbar {
  height: 6px;
  width: 4px;
}
div::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background: #667bb4;
}
div::-webkit-scrollbar-track {
  background: skyblue;
}
/deep/.el-radio-group {
  // top: 30%;
  position: absolute;
  text-align: center;
  top: 0;
  bottom: 0;
  left: 12%;
  right: 0;
  margin: auto;
  z-index: 10;
}
/deep/ .el-dialog__header {
  background: #d7edfb !important;
  margin-right: 0;
}
/deep/ .el-radio__label {
  color: #24211e;
  font-weight: 600;
}

/deep/ .el-radio.el-radio--large .el-radio__label {
  font-size: calc(100vw * 22 / 1920) !important;
  text-align: center;
}
/deep/.el-radio.el-radio--large {
  height: 30px;
}

.logodialogue {
  position: absolute;
  right: 58%;
  top: 8%;
  width: 33%;
  background-color: #d7edfb;
  border-radius: 10px;
  border: 1px solid #eee;
  padding: 12px;
  font-size: 16px;
  letter-spacing: 1px;
  z-index: 999;
  text-indent: 2em;
}
.logodialoguex {
  position: absolute;
  top: 10%;
  right: 100%;
  width: 0px;
  height: 0px;
  border-top: 10px solid #d7edfb;
  border-left: 10px solid #d7edfb;
  border-right: 10px solid transparent;
  border-bottom: 10px solid transparent;
  transform: rotateY(180deg); /* 垂直镜像翻转 */
}

// 重新开始按钮
.restartbut {
  position: absolute;
  left: 47%;
  top: 83%;
  width: 14%;
  height: 7%;
  background: #007cc2;
  border-radius: 6px;
  font-size: 20px;
}
h3 {
  font-size: calc(100vw * 16 / 1920);
}
h3 > span {
  font-size: 16px;
}
h3 > p {
  font-size: calc(100vw * 16 / 1920);
}
* {
  font-size: calc(100vw * 20 / 1920);
}
/deep/ .el-button.is-loading{
  position: absolute;
}
</style>
