<template>
  <div class="cognition">
    <img alt="Vue logo" src="../assets/img/imgHomePage/home.jpg" />
    <!-- <HelloWorld msg="Welcome to Your Vue.js App"/> -->
    <div class="step step1" v-if="step1" @click="chuangLBtn"></div>
    <div class="step step2" v-if="step2" @click="chuangRBtn"></div>
    <!-- <router-link to="/Intelligent" class="step step2" tag="button" >Intelligent</router-link> -->
    <div class="step step3" v-if="step3" @click="toTelecomPage"></div>
    <div class="step step4" v-if="step4" @click="toEngineeringPractice"></div>
  </div>
  <!-- <img class="flag1" v-if="flag1"  src="../assets/img/imgdevelopment/flag.png" alt="">
      <img class="flag2" v-if="flag2"  src="../assets/img/imgdevelopment/flag.png" alt="">
      <img class="flag3" v-if="flag3"  src="../assets/img/imgdevelopment/flag.png" alt="">
      <img class="flag4" v-if="flag4"  src="../assets/img/imgdevelopment/flag.png" alt=""> -->
  <img class="flag_1" v-if="flag1" @click="chuangLBtn" src="../assets/img/imgdevelopment/flag_2.gif" alt="" />
  <img class="flag_2" v-if="flag2" @click="chuangRBtn" src="../assets/img/imgdevelopment/flag_2.gif" alt="" />
  <img class="flag_3" v-if="flag3" @click="toTelecomPage" src="../assets/img/imgdevelopment/flag_2.gif" alt="" />
  <img class="flag_4" v-if="flag4" @click="toEngineeringPractice" src="../assets/img/imgdevelopment/flag_2.gif"
    alt="" />
  <img draggable="false" class="ImagedsEntrance" @click="crnmedua" src="../assets/img/imgHomePage/xr.png" alt="" />
  <div class="qwertyEntrance" v-if="widuis">
    <div></div>
    <span> 界面中闪烁模块为考核模块。</span>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import { PageDispense } from "../api/topics.js";
export default {
  data() {
    return {
      diask: sessionStorage.getItem("diask"),
      templete_name: null,
      step1: false,
      step2: false,
      step3: false,
      step4: false,
      flag1: false,
      flag2: false,
      flag3: false,
      flag4: false,
      widuis: true,
    };
  },
  // name: "CognitionView",
  components: {
    // HelloWorld
  },
  created() {
    let templateName = this.$store.state.template_name;
    if (templateName == '通信网络认知-工程实践') {
      this.flag4 = true;
    } else if (templateName == '通信网络认知-电信业务') {
      this.flag3 = true;
    } else if (templateName == '通信网络认知-智能通信系统') {
      this.flag2 = true;
    } else if (templateName == '通信网络认知-人类通信发展简史') {
      this.flag1 = true;
    }

  },
  // created() {
  //   let taskId = this.$store.state.taskId;
  //   let taskInfoName = this.$store.state.template_name;
  //   let dataPageDispense = { taskId: taskId, taskInfoName: taskInfoName };
  //   console.log(dataPageDispense);
  //   PageDispense(dataPageDispense).then((res) => {
  //     this.templete_name = JSON.parse(res.data.taskInfo).templete_name;
  //     if (this.templete_name == "通信网络认知-人类通信发展简史") {
  //       this.step2 = false;
  //       this.step3 = false;
  //       this.step4 = false;
  //       this.flag1 = true;
  //       this.flag2 = true;
  //       this.flag3 = true;
  //     } else if (this.templete_name == "通信网络认知-电信业务") {
  //       this.step1 = false;
  //       this.step2 = false;
  //       this.step4 = false;
  //       this.flag1 = true;
  //       this.flag3 = true;
  //       this.flag4 = true;
  //     } else if (this.templete_name == "通信网络认知-智能通信系统") {
  //       this.step1 = false;
  //       this.step3 = false;
  //       this.step4 = false;
  //       this.flag4 = true;
  //       this.flag2 = true;
  //       this.flag1 = true;
  //     } else if (this.templete_name == "通信网络认知-工程实践") {
  //       this.step1 = false;
  //       this.step2 = false;
  //       this.step3 = false;
  //       this.flag4 = true;
  //       this.flag2 = true;
  //       this.flag3 = true;
  //     }
  //   });
  // },

  methods: {
    toTelecomPage() {
      this.$router.push({ name: "TelecomView" });
    },
    toEngineeringPractice() {
      this.$router.push({ name: "EngineeringView" });
    },
    chuangRBtn() {
      this.$router.push({ name: "Intelligent" });
    },
    chuangLBtn() {
      this.$router.push({ name: "ancientunication" });
      if (this.diask == "true") {
        return;
      }
      this.$store.commit("AggregateData");
      this.$store.commit("CommunicationData");
      this.$store.commit("ModernData");
      sessionStorage.setItem("diask", "true");
    },
    EntryBtn() {
      this.$router.push({ name: "EngineeringPractice" });
    },
    crnmedua() {
      this.widuis = !this.widuis;
    },
  },
  mounted() {
    localStorage.setItem("iserr", 0);
  },
};
</script>

<style lang="scss" scope>
.cognition {
  width: 100%;
  height: 100%;
  position: relative;
  box-sizing: border-box;

  img {
    display: block;
    display: block;
    width: 100%;
    height: 100%;
  }

  .step {
    position: absolute;
    width: 200px;
    height: 200px;
    border-radius: 50%;
    left: 0px;
    top: 0px;
    cursor: pointer;
  }

  .step1 {
    top: 68%;
    left: 48%;
  }

  .step2 {
    top: 55%;
    left: 7%;
  }

  .step3 {
    top: 32%;
    left: 12%;
  }

  .step4 {
    top: 7%;
    left: 31%;
  }
}

.flag1 {
  position: absolute;
  width: 7%;
  top: 2%;
  left: 33.2%;
}

.flag2 {
  position: absolute;
  width: 7%;
  top: 27.5%;
  left: 11%;
}

.flag3 {
  position: absolute;
  width: 7%;
  top: 56.5%;
  left: 10.5%;
}

.flag4 {
  position: absolute;
  width: 7%;
  top: 64%;
  left: 48.2%;
}

.flag_1 {
  position: absolute;
  width: 7%;
  top: 65%;
  left: 48.2%;
  cursor: pointer;
}

.flag_2 {
  position: absolute;
  width: 7%;
  top: 57.5%;
  left: 10.5%;
  cursor: pointer;
}

.flag_3 {
  position: absolute;
  width: 7%;
  top: 28%;
  left: 11%;
  cursor: pointer;
}

.flag_4 {
  position: absolute;
  width: 7%;
  top: 3%;
  left: 33.4%;
  cursor: pointer;
}

.ImagedsEntrance {
  width: 7%;
  height: 18%;
  position: absolute;
  right: 0.5%;
  bottom: 3%;
  cursor: pointer;
}

.qwertyEntrance {
  position: absolute;
  right: 8%;
  bottom: 14%;
  // width: 22%;
  background-color: #d7edfb;
  border-radius: 10px;
  border: 1px solid #eee;
  padding: 15px;
  font-size: 15.5px;
  letter-spacing: 1px;
  z-index: 999;

  div {
    position: absolute;
    top: 36%;
    right: -3%;
    width: 0px;
    height: 0px;
    border-top: 15px solid transparent;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-bottom: 15px solid #D7EDFB;
  }
}
</style>
