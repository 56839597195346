    <!-- <span>近现代通信</span> -->
<template>
  <div class="basemap">
    <!-- 底图 -->
    <img
      class="baseimg"
      src="../../../assets/img/imgdevelopment/modern/basemap.jpg"
      alt=""
    />
    <!-- 所有电话图片 -->
    <div>
      <img
        v-for="(item, index) in phonebox"
        :key="index"
        :style="{ left: item.left, top: item.top }"
        class="phone"
        :src="item.img"
        alt=""
        @click="cursorphone(item, index)"
      />
    </div>

    <!-- 左侧接线图 -->
    <transition name="el-fade-in-linear">
      <div v-show="show" class="transition-box" >
        <!-- 底图 -->
        <img
          v-show="show"
          class="wiring"
          src="../../../assets/img/imgdevelopment/modern/pop.png"
          alt=""
        />
        <span class="clsA">{{ origin }}</span>
        <!-- 箭头图片 -->
        <img
          class="imgarrows"
          src="../../../assets/img/imgdevelopment/modern/arrows.png"
          alt=""
        />
        <span class="clsB">{{ terminus }}</span>
        <!-- 接线孔 -->
        <div @click="jack = !jack" class="jackbox">
          <div
            v-for="(item, index) in pictureArray"
            :key="index"
            class="cityid"
          >
            <span>{{ item }}</span>

            <img
              v-if="item == busy"
              class="jackb"
              :class="`jackb${index}`"
              :src="
                require(`../../../assets/img/imgdevelopment/modern/${img_state1}.png`)
              "
              :title="item"
            />
            <img
              v-else
              class="jack"
              :class="`jack${index}`"
              :src="
                require(`../../../assets/img/imgdevelopment/modern/${img_state}.png`)
              "
              :title="item"
              @click="jackimg(item, index)"
            />
          </div>
        </div>
        <!-- 右侧路线 接线图 -->
        <div class="line">
          <div class="linebox" ref="chatContent">
            <el-steps direction="vertical" :active="record.length" space="60px">
              <el-step
                class="step"
                v-for="(item, index) in record"
                :key="index"
                :title="item"
              ></el-step>
            </el-steps>
          </div>
        </div>

        <el-button type="primary" class="btn" @click="accomplish()"
          >完&nbsp;&nbsp;&nbsp; 成</el-button
        >
      </div>
    </transition>

    <div>
      <!-- 小唐人logo -->
      <img
        class="dialong3"
        @click="dialogVisible = !dialogVisible"
        src="../../../assets/img/imgdevelopment/logo.png"
        alt=""
      />
      <!-- 小唐人弹出框 -->
      <div class="logodialogue" v-show="dialogVisible">
        <span>
          19世纪30年代之后，电磁波的发现开启了电话通信的时代，为满足更多用户接入电话网络，人工交换电话机应运而生。请你作为电话交换机接线员，按照界面提示完成从<span
            style="color: red"
            >{{ origin }}</span
          >到<span style="color: red">{{ terminus }}</span
          >之间的电话线路接通，在通信世界里重走长征路。
          操作提示：点击转接城市电话图标，打开电话交换机面板，选择下一跳城市，橙色插孔代表占线，白色插孔代表通行，直至到达目标接话城市。
        </span>
        <div class="logodialoguex"></div>
      </div>
    </div>

    <el-dialog
      v-model="dialogVisibleshow"
      title="小唐人提示"
      width="30%"
      :before-close="handleClose"
    >
      <img style="width: 50%; margin-left: 24%" :src="whetherAdd" alt="" />
      <hr style="width: 90%; background-color: #e4e4e3; margin: 10px auto" />
      <p style="font-size: 20px; text-align: center; color: #0074c5">
        <span>您选择的线路跳数为{{ record.length }}跳,</span>
        <span>最优线路的跳数为{{ excellent }}跳!</span>
      </p>

      <h3 style="margin: 2%" v-show="dishow">
        <span style="color: #ec4515; margin-left: 12.5%;font-size: calc(100vw * 20 / 1920);">电话通信优点：</span>
        实现实时双向通信，空间约束”
      </h3>
      <h3 v-show="dishow">
        <span style="color: #40b489; margin-left: 14%;font-size: calc(100vw * 20 / 1920);">电话通信缺点：</span>
        <p style="margin-left: 40%; margin-top: -4%">
          通信双方位置固定，不能随地通信
        </p>
      </h3>
      <template #footer>
        <span class="dialog-footer">
          <el-button
            style="
              background: #0074c5;
              width: 28%;
              margin: auto;
              display: block;
            "
            type="primary"
            @click="confirmbut"
            >确&ensp;&ensp;&ensp;定</el-button
          >
        </span>
      </template>
    </el-dialog>
    <!-- <canvas ref="line01" id="canvas" style="width:600px;height:600px"></canvas> -->
  </div>
</template>
<script>
// import { VerifyPath } from 'src/assets/js/modernTimes/verifyPath.ts';
import { readCommunication } from "../../../api/topics";
import $ from "jquery"; //先引入再使用
import { VerifyPath } from "../../../assets/js/modernTimes/verifyPath.ts";
import phoneimag from "../../../assets/img/imgdevelopment/modern/telephonefalse.png";
import flickerimg from "../../../assets/img/imgdevelopment/modern/telephone2.gif";
import redcity from "../../../assets/img/imgdevelopment/modern/telephone1.png";
import finishcity from "../../../assets/img/imgdevelopment/modern/telephone4.png";
import { holographic } from "../../../api/topics";

export default {
  data() {
    return {
      dishow: false,
      whetherAdd: "",
      dialogVisibleshow: false,
      img_state: "phonejack",
      img_state1: "phonejack2",
      img_state2: "phonejack3",
      img_type: "",
      dialogVisible: true,
      show: false,
      jack: false,
      Array: [],
      phonebox: [
        { top: "3%", left: "51%", id: "延安", img: phoneimag },
        { top: "50%", left: "8%", id: "拉萨", img: phoneimag },
        { top: "6%", left: "32%", id: "西宁", img: phoneimag },
        { top: "12%", left: "38%", id: "兰州", img: phoneimag },
        { top: "20%", left: "50%", id: "西安", img: phoneimag },
        { top: "19%", left: "61%", id: "郑州", img: phoneimag },
        { top: "9%", left: "69%", id: "济南", img: phoneimag },
        { top: "38%", left: "68%", id: "合肥", img: phoneimag },
        { top: "41%", left: "79%", id: "上海", img: phoneimag },
        { top: "30%", left: "73%", id: "南京", img: phoneimag },
        { top: "50%", left: "76%", id: "杭州", img: phoneimag },
        { top: "47%", left: "62%", id: "武汉", img: phoneimag },
        { top: "61%", left: "58%", id: "长沙", img: phoneimag },
        { top: "61%", left: "66%", id: "南昌", img: phoneimag },
        { top: "67%", left: "74%", id: "福州", img: phoneimag },
        { top: "88%", left: "60%", id: "广州", img: phoneimag },
        { top: "88%", left: "49%", id: "南宁", img: phoneimag },
        { top: "39%", left: "39%", id: "成都", img: phoneimag },
        { top: "53%", left: "45%", id: "重庆", img: phoneimag },
        { top: "71%", left: "45%", id: "贵阳", img: phoneimag },
        { top: "79%", left: "36%", id: "昆明", img: phoneimag },
        { top: "81%", left: "80%", id: "台北", img: phoneimag },
        { top: "81%", left: "53%", id: "桂林", img: phoneimag },
        { top: "50%", left: "66%", id: "九江", img: phoneimag },
        { top: "63%", left: "50%", id: "遵义", img: phoneimag },
        { top: "24%", left: "39%", id: "腊子口", img: phoneimag },
        { top: "32%", left: "44%", id: "松潘", img: phoneimag },
        { top: "52%", left: "36%", id: "泸定", img: phoneimag },
        { top: "46%", left: "38%", id: "雅安", img: phoneimag },
        { top: "75%", left: "65.5%", id: "瑞金", img: phoneimag },
        { top: "84%", left: "72.5%", id: "厦门", img: phoneimag },
      ],
      pictureArray: null,
      origin: "", //开始城市
      origin2: "", //变化的开始城市
      terminus: "", //结束城市
      jakblock: require("../../../assets/img/imgdevelopment/modern/phonejack2.png"),
      img_state3: require("../../../assets/img/imgdevelopment/modern/phonejack3.png"),
      img_statere: require("../../../assets/img/imgdevelopment/modern/phonejack.png"),
      busy: "", //占线城市
      num: 0,
      record: "", //这个是线路
      brightcity: null, //需要高亮的城市
      initialize: null, //后台数据
      begincity: null, //点击后的起始城市
      cityid: "",
      excellent: "", //最优线路
      present: "", //当前线路
      kky: false, //有弹框时不能点击电话
      lock: false, //锁定全局
      ctx: null, //canvas画线
      whether: false, //判断是否成功抵达终点
      optimal: false, //判断是否是最优路线
      echo: null, //回显
    };
  },
  created() {
    this.$nextTick(() => {

      let datatimes = new FormData();
      datatimes.append("user_id", this.$store.state.user_id);
      datatimes.append("data_sign", "communication_history");
      datatimes.append("task_id", this.$store.state.taskId);
      datatimes.append("template_id", this.$store.state.template_id);
      datatimes.append("value_sign", "early_modern_communication");
      readCommunication(datatimes).then((res) => {
        this.echo = res.data.data;
        // console.log(this.echo);
        if (res.data.data == null) {
          // console.log("11");
          let greeter = new VerifyPath();
          // let greeter = new VerifyPath();
          this.initialize = greeter;
          let optionCitys = greeter.GetOptionCitys(greeter.beginCity);
          this.pictureArray = optionCitys[0];
          // console.log(this.pictureArray);
          this.busy = optionCitys[1];
          optionCitys[0]; //这个是5个城市
          optionCitys[1]; //这个是占线城市
          this.greeter = optionCitys[1];
          this.origin = greeter.beginCity; //这个是开始城市
          this.terminus = greeter.endCity; //这个是结束城市
          this.record = greeter.path; //这个是线路
          // console.log(this.record); //这个是线路
          // greeter.SwitchCity(this.origin, this.brightcity); //这个是传值
          // console.log(greeter.optimalPath)
          this.excellent = greeter.optimalPath; //最优线路
          // console.log(greeter);
          //接口数据
          console.log(this.record.length);

        }
        if (res.data.data != null) {
          // console.log(res.data.data.beginCity)
          let begingCity = res.data.data.beginCity;
          let endCity = res.data.data.endCity;
          let path = res.data.data.path;
          let dicBusy = res.data.data.dicBusy;

          let greeter = new VerifyPath(begingCity, endCity, path, dicBusy);
          // let greeter = new VerifyPath();
          this.initialize = greeter;
          let optionCitys = this.initialize.GetOptionCitys(greeter.beginCity);
          this.pictureArray = optionCitys[0];
          // console.log(this.pictureArray);
          this.busy = optionCitys[1];
          optionCitys[0]; //这个是5个城市
          optionCitys[1]; //这个是占线城市
          this.greeter = optionCitys[1];
          this.origin = greeter.beginCity; //这个是开始城市
          this.terminus = greeter.endCity; //这个是结束城市
          this.record = greeter.path; //这个是线路
          // console.log(this.record.length);

          // console.log(this.record); //这个是线路
          // greeter.SwitchCity(this.origin, this.brightcity); //这个是传值
          // console.log(greeter.optimalPath)
          this.excellent = greeter.optimalPath; //最优线路
          // console.log(greeter);
          //接口数据
          this.accomplish()
        }
      });
    });
  },
  mounted() {
    setTimeout(() => {
      //开始城市标记
      this.phonebox.map((item, index) => {
        if (this.record[this.record.length - 1] == this.phonebox[index].id) {
          this.phonebox[index].img = flickerimg;
        }
      });
      //结束城市标记
      this.phonebox.map((item, index) => {
        if (this.terminus == this.phonebox[index].id) {
          this.phonebox[index].img = finishcity;
        }
      });
    }, 500);

    // this.draw();
  },
  components: {},
  updated() {
    this.scrollToBottom();
  },
  methods: {
    //点击电话图标
    cursorphone(item, index) {
      // lock 点击确定后 锁住页面
      if (this.lock == true) {
        return false;
      }
      //有弹框时 不能点击电话
      if (this.show == true) {
        this.kky = true;
        //没有弹框时 能点击电话
      } else if (this.show != true) {
        this.kky = false;
      }
      if (this.kky == true) {
        return false;
      }
      this.dialogVisible = false;
      this.cityid = item.id;
      this.begincity = item.id;
      if (this.origin == item.id) {
        this.show = true;
      }
      if (this.brightcity == item.id) {
        this.show = true;
      }
      this.record.map((val, index) => {
        // console.log(val);
        if (val == item.id) {
          this.show = true;
          let Array = this.initialize.GetOptionCitys(item.id); //发送请求获取城市
          this.pictureArray = Array[0]; //重新获取城市
          this.busy = Array[1]; //占线城市
        }
      });
    },
    //点击完成按钮
    accomplish() {
      this.show = false;
      this.record.map((val, i) => {
        if (val == this.terminus) {
          this.dialogVisibleshow = true;
          this.whether = true;
          // this.lock = true;
          if (this.excellent == this.record.length) {
            this.optimal = true;
            this.dishow = true;
            // console.log(this.optimal);
            this.whetherAdd = require("../../../assets/img/imgdevelopment/ancientpeople/succeed.png");
          } else if (this.excellent != this.record.length) {
            this.dishow = false;
            this.whetherAdd = require("../../../assets/img/imgdevelopment/ancientpeople/failure.png");
          }
        }
      });

      //可以点击的城市
      this.phonebox.map((item, index) => {
        this.record.map((val, i) => {
          if (item.id != val) {
            this.phonebox[index].img = phoneimag;
          }
          if (item.id == val) {
            this.phonebox[index].img = redcity;
          }
        });
      });
      //清空不在线路中的图标
      this.phonebox.map((item, index) => {
        this.record.map((val, i) => {
          if (item.id == val) {
            this.phonebox[index].img = redcity;
          }
        });
      });

      let Array = this.initialize.GetOptionCitys(this.brightcity); //发送请求获取城市
      this.pictureArray = Array[0]; //重新获取城市
      this.busy = Array[1]; //占线城市
      $(".jack").attr("src", this.img_statere);

      // 需要高亮的城市
      this.phonebox.map((item, index) => {
        if (this.brightcity == this.phonebox[index].id) {
          this.phonebox[index].img = flickerimg;
        }
      });

      //结束城市标记
      this.phonebox.map((item, index) => {
        if (this.terminus == this.phonebox[index].id) {
          this.phonebox[index].img = finishcity;
        }
      });
      this.present = this.record.lenght;
      //调用VUEX保存数据
      this.$store.commit("sum_modernTimes", [
        this.initialize,
        this.whether,
        this.optimal,
      ]);
    },
    //点击插孔
    jackimg(item, index) {
      $(".jack").attr("src", this.img_statere);
      $(".jack" + index).attr("src", this.img_state3);
      this.brightcity = item; //点击后获取到城市
      this.origin2 = this.begincity; //点击电话后起始城市发生变化
      let Array = this.initialize.GetOptionCitys(this.brightcity); //发送请求获取城市
      this.initialize.SwitchCity(this.origin2, this.brightcity); //这个是传值
      this.record = this.initialize.path; //这个是线路
      this.scrollToBottom();
      // console.log(this.record);
    },
    //更新最新的数据
    scrollToBottom() {
      this.$nextTick(() => {
        this.$refs.chatContent.scrollTop = this.$refs.chatContent.scrollHeight;
      });
    },
    //弹框重来一次
    finishclick() {
      this.$router.go(0);
    },
    // 弹框确定按钮
    confirmbut() {
      this.dialogVisibleshow = false;

      let data = "Replay?5;"
      let obj = new FormData();
      obj.append("data", data);
      holographic(obj).then((res) => {
        console.log(res)
      });

      if (this.dialogVisibleshow == false) {
        return;
      }
    },
    //canvas画线
    draw() {
      var c = document.getElementById("canvas");

      var ctx = c.getContext("2d");
      ctx.beginPath();

      ctx.moveTo(25, 25);

      ctx.lineTo(75, 75);

      ctx.lineWidth = 1;

      ctx.strokeStyle = "red";

      ctx.stroke();

      /* this.ctx = this.$refs.line01.getContext("2d");
      this.ctx.lineWidth = 1;
      this.ctx.strokeStyle  = "red";
      this.ctx.moveTo(244, 126);
      this.ctx.lineTo(220, 132);
      this.ctx.stroke(); */
    },
  },
};
</script>
<style  scoped lang="scss">
.basemap {
  width: 100%;
  /* height: 120%; */
  position: relative;
  box-sizing: border-box;
  padding: 10px;
}
// 小唐人logo
.dialong3 {
  position: absolute;
  left: 93%;
  top: 4%;
  width: 6%;
  // height: 22%;
  cursor: pointer;
}
/* 底图 */
.baseimg {
  width: 100%;
  /* height: 100%; */
  position: relative;
  box-sizing: border-box;
  // padding: 15px;
}
// 电话图片
.phone {
  width: 3%;
  position: absolute;
  top: 0%;
  left: 0%;
  cursor: pointer;
}
.phone2 {
  width: 3%;
  position: absolute;
  top: 75%;
  left: 65.5%;
  cursor: pointer;
}
// 接线图
.wiring {
  width: 50%;
  height: 67%;
  position: absolute;
  left: 2%;
  top: 22%;
}
// 插孔背景
.jackbox {
  position: absolute;
  left: 6%;
  top: 50%;
  width: 26%;
  height: 16%;
}
// 接线图插孔
.jack {
  width: 10%;
  cursor: pointer;
  position: absolute;
  top: 60%;
  margin-left: -10%;
}
.jackb {
  width: 10%;
  cursor: pointer;
  position: absolute;
  top: 60%;
  margin-left: -10%;
}
//接线图按钮
.btn {
  position: absolute;
  left: 38%;
  top: 78%;
  width: 8%;
  height: 6%;
  background: #007cc2;
  font-size: 20px;
}
//弹框箭头
.imgarrows {
  position: absolute;
  left: 14%;
  top: 38%;
}

//起点城市
.clsA {
  position: absolute;
  left: 8%;
  top: 38%;
  font-size: 26px;
  font-weight: 600;
}
//终点城市
.clsB {
  position: absolute;
  left: 24%;
  top: 38%;
  font-size: 26px;
  font-weight: 600;
}
//城市名称
.cityid {
  float: left;
  font-size: 18px;
  margin: 4%;
}
//通信线路
.line {
  position: absolute;
  left: 33.5%;
  top: 30%;
  width: 16%;
  height: 46%;
  z-index: 10;
}
.linebox {
  width: 76%;
  height: 95%;
  margin-top: 24px;
  margin-left: 22%;
  overflow: hidden;
  overflow-y: auto;
  overflow-x: auto;
}
/deep/.el-step__title {
  font-size: 26px;
}
/deep/ .el-step__title {
  line-height: 24px;
  padding-bottom: 8px;
}
/deep/.el-step.is-vertical .el-step__main {
  padding-left: 32px;
}
.step{
  cursor: pointer;
}
/deep/.el-steps--vertical {
  height: auto !important;
}
//右侧记录表
.itemleft {
  left: 34%;
  top: 38%;
  font-size: 20px;
  font-weight: 600;
}
.itemright {
  left: 45%;
  top: 38%;
  font-size: 20px;
  font-weight: 600;
}
//弹框箭头右侧
.imgarrowsright {
  width: 38%;
  margin: 0 20px;
}
/deep/ .el-dialog__header {
  background: #d7edfb !important;
  margin-right: 0 !important;
}
/deep/ .el-button {
  /* padding: 0; */
  color: rgb(255, 255, 255);
}

.logodialogue {
  position: absolute;
  right: 8%;
  top: 11%;
  width: 30%;
  background-color: #d7edfb;
  border-radius: 10px;
  border: 1px solid #eee;
  padding: 12px;
  font-size: 16px;
  letter-spacing: 1px;
  z-index: 999;
  text-indent: 2em;
}
.logodialoguex {
  position: absolute;
  top: 26%;
  right: -3%;
  width: 0px;
  height: 0px;
  border-top: 10px solid #d7edfb;
  border-left: 10px solid #d7edfb;
  border-right: 10px solid transparent;
  border-bottom: 10px solid transparent;
}

/* 滚动条样式 */
div::-webkit-scrollbar {
  height: 6px;
  width: 4px;
}
div::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background: #667bb4;
}
div::-webkit-scrollbar-track {
  background: skyblue;
}

/* #canvas {
  width: 100%;
  height: 100%;
  border: 1px solid #000;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
} */
h3{
  font-size: calc(100vw * 16 / 1920);
}
h3>span{
  font-size: 16px;
}
h3>p{
  font-size: calc(100vw * 16 / 1920);
}
*{
   font-size: calc(100vw * 20 / 1920);
}
</style>
