<template>
  <!-- <span>远古通信</span> -->
  <!-- 底图 -->
  <div :class="this.ssk == 100 ? 'basemap2' : 'basemap'">
    <img class="baseimg" :src="kky" alt="" />
    <div :style="pathshow">
      <img :class="imgpath" :src="imgepath" alt="" />
    </div>
    <!-- 原始人动态事件 -->
    <div class="leftside">
      <div class="head" @click="clikchead"></div>
      <div class="hand" @click="clickhand"></div>
      <div class="foot" @click="clickfoot"></div>
    </div>
    <!-- 对话框 -->
    <div v-show="hidden">
      <img
        class="dialong"
        src="../../../assets/img/imgdevelopment/leftbox.png"
        alt=""
      />
      <span class="dialong" style="height: 20%; width: 26%">
        <span class="dialogue">{{ dialogue1 }}</span></span
      >
      <img
        class="dialong2"
        src="../../../assets/img/imgdevelopment/rightbox.png"
        alt=""
      />
      <span class="dialong2" style="height: 20%; width: 26%">
        <span class="dialogue">{{ dialogue2 }}</span>
      </span>
    </div>
    <div>
      <!-- 小唐人logo -->
      <img
        class="dialong3"
        @click="showif"
        src="../../../assets/img/imgdevelopment/logo.png"
        alt=""
      />
      <!-- 开始语 -->
      <div class="logodialogue" v-show="dialogVisible">
        <span
          >在原始社会文字产生之前，人与人之间的交流形式非常简单。通常采用声音或肢体语言进行基本信息的传递。传递的信息也基本围绕着威胁生命安全的事情进行。请在远古通信学习实验界面点击左侧三个身体部位，以原始人的视角通过不同肢体动作向“部落族长”传递
          <span style="color: red">6次</span> 信息，并获得反馈。
        </span>
        <div class="logodialoguex"></div>
      </div>

      <!-- 结束语 -->
      <!-- <div class="logodialogue" v-show="indialogVisible">
        <span>
          &nbsp; &nbsp; &nbsp; &nbsp;
          原始社会的原始人仅能采用吼叫及肢体语言进行信息传递，只能进行实时的、面对面的交流，随着协同劳动、想法交流逐渐增多，简单吼叫和肢体语言无法表达更多含义时，语言就诞生了，这是人类第一次信息技术革命，是从猿进化到人的重要标志。
          然而语言与肢体动作一样，具有实时性、近距离交互的特点，无法随着时空保留下来，无法远距离传输，为了把发生的重要事件等信息记录下来，人们发明了结绳记事。
        </span>
        <div class="logodialoguex"></div>
      </div> -->
    </div>

    <!-- 答题全部正确时弹框 -->
    <el-dialog
      v-model="dialogVisibleshow"
      title="小唐人提示"
      width="30%"
      :before-close="confirmbut"
    >
      <img
        style="width: 44%; margin-left: 28%; margin-top: -20px"
        src="../../../assets/img/imgdevelopment/ancientpeople/succeed.png"
        alt=""
      />
      <hr style="width: 90%; background-color: #e4e4e3; margin: auto" />
      <h3 style="text-align: center; margin: 2%">
        <span style="color: #ec4515;font-size: calc(100vw * 20 / 1920);">远古通信优点：</span>
        <span>&ensp;能够进行简单实时的信息传递</span>
      </h3>
      <h3 style="text-align: center">
        <span style="color: #40b489; margin-left: 4%;font-size: calc(100vw * 20 / 1920);">远古通信缺点：</span>
        <span>&ensp;无法保留信息，不能远距离传输</span>
      </h3>
      <template #footer>
        <span class="dialog-footer">
          <el-button
            style="
              background: #0074c5;
              width: 30%;
              margin-right: 34%;
              font-size: 16px;
            "
            type="primary"
            @click="confirmbut"
            >确&ensp;&ensp;&ensp;定</el-button
          >
        </span>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import drag1 from "../../../assets/img/imgdevelopment/primitive01.gif";
import drag2 from "../../../assets/img/imgdevelopment/primitive03.gif";
import drag3 from "../../../assets/img/imgdevelopment/primitive02.gif";
import { getAncient } from "../../../api/topics";
import { holographic } from "../../../api/topics";

export default {
  data() {
    return {
      dialogVisibleshow: false,
      imgepath: "",
      pathshow: "display:none",
      imgpath: "",
      ssk: "",
      dialogVisible: true,
      indialogVisible: false,
      finishclick: false,
      hidden: false,
      dialogue1: "",
      dialogue2: "",
      finish: true,
      kky: require("../../../assets/img/imgdevelopment/basemap.jpg"),
      num: 0,
      click_num: "0",
      clicknum: true,
    };
  },
  mounted() {
    this.$store.commit("sum_actions", this.click_num);
  },
  components: {},

  methods: {
    clikchead() {
      if (this.clicknum == true) {
        this.clicknum = false;
        this.hidden = false;
        this.ssk = 1;
        this.imgepath = drag1;
        this.pathshow = "display:block";
        this.imgpath = "hominid";
        this.kky = require("../../../assets/img/imgdevelopment/basemap2.jpg");
        this.dialogue();
        setTimeout(() => {
          this.hidden = true;
        }, 1000);
        this.num++;
        this.click_num++;
        if (this.num > 5) {
          this.dialogVisibleshow = true;
          this.dialogVisible = false;
          this.num = 0;
        }
        this.dialogVisible = false;
        // 调用VUEX保存数据
        this.$store.commit("sum_actions", this.click_num);
        setTimeout(() => {
          this.clicknum = true;
        }, 4000);
      }
    },
    clickhand() {
      if (this.clicknum == true) {
        this.clicknum = false;
        this.hidden = false;
        this.ssk = 1;
        this.imgepath = drag2;
        this.pathshow = "display:block";
        this.imgpath = "hominid2";
        this.kky = require("../../../assets/img/imgdevelopment/basemap2.jpg");
        this.dialogue();
        setTimeout(() => {
          this.hidden = true;
        }, 1000);
        this.num++;
        this.click_num++;
        if (this.num > 5) {
          this.dialogVisibleshow = true;
          this.dialogVisible = false;
          this.num = 0;
        }
        this.dialogVisible = false;
        // 调用VUEX保存数据
        this.$store.commit("sum_actions", this.click_num);
      }
      setTimeout(() => {
        this.clicknum = true;
      }, 4000);
    },
    clickfoot() {
      if (this.clicknum == true) {
        this.clicknum = false;
        this.hidden = false;
        this.ssk = 1;
        this.imgepath = drag3;
        this.pathshow = "display:block";
        this.imgpath = "hominid3";
        this.kky = require("../../../assets/img/imgdevelopment/basemap2.jpg");
        this.dialogue();
        setTimeout(() => {
          this.hidden = true;
        }, 1000);
        this.num++;
        this.click_num++;
        if (this.num > 5) {
          this.dialogVisibleshow = true;
          this.dialogVisible = false;
          this.num = 0;
        }
        this.dialogVisible = false;
        // 调用VUEX保存数据
        this.$store.commit("sum_actions", this.click_num);
        setTimeout(() => {
          this.clicknum = true;
        }, 4000);
      }
    },
    handleClose() {
      this.dialogVisible = false;
      this.finishclick = false;
    },
    dialogue() {
      getAncient().then((res) => {
        const obj = JSON.parse(res.data.data); //这个是正确的转换
        this.dialogue1 = obj.question;
        this.dialogue2 = obj.answer;
      });
    },
    // 点击小唐人后显示隐藏
    showif() {
      this.dialogVisible = !this.dialogVisible;
      this.indialogVisible = false;
    },
    //弹框显示隐藏
    confirmbut() {
      this.dialogVisibleshow = false;
      let data = "Replay?1;"
      let obj = new FormData();
      obj.append("data", data);
      holographic(obj).then((res) => {
        console.log(res)
      });
    },
  },
};
</script>
<style  scoped lang="scss">
/* 底图 */
.baseimg {
  width: 100%;
  /* height: 100%; */
  position: relative;
  box-sizing: border-box;
  // padding: 15px;
}
/* 最上层的div */
.basemap {
  width: 100%;
  /* height: 120%; */
  position: relative;
  box-sizing: border-box;
  padding: 10px;
}
/* .basemap2 {
  background-image: url("../../../assets/img/imgdevelopment/远古通信底图2.jpg");
  background-size: cover;
  width: 100%;
  height: 120%;
  position: relative;
  padding: 15px;
} */
/* 左侧盒子 */
.leftside {
  width: 12.5%;
  height: 87%;
  background: rgba(190, 124, 124, 0);
  position: absolute;
  left: 3%;
  top: 6%;
  border-radius: 30%;
}
/* 点击头部 */
.head {
  width: 62%;
  height: 23%;
  background: rgba(190, 124, 124, 0);
  border-radius: 50%;
  position: absolute;
  left: 18%;
  top: 6%;
  cursor: pointer;
}
/* 点击手部 */
.hand {
  width: 62%;
  height: 23%;
  background: rgba(190, 124, 124, 0);
  border-radius: 50%;
  position: absolute;
  left: 18%;
  top: 38%;
  cursor: pointer;
}
/* 点击腿部 */
.foot {
  width: 62%;
  height: 23%;
  background: rgba(190, 124, 124, 0);
  border-radius: 50%;
  position: absolute;
  left: 18%;
  top: 70%;
  cursor: pointer;
}
/* 头部动态图 */
.hominid {
  width: 19%;
  height: 73%;
  position: absolute;
  left: 25%;
  top: 20%;
}
/* 手部动态图 */
.hominid2 {
  width: 19%;
  height: 73%;
  position: absolute;
  left: 25%;
  top: 20%;
}
/* 腿部动态图 */
.hominid3 {
  width: 19%;
  height: 73%;
  position: absolute;
  left: 25%;
  top: 20%;
}
/* 对话框1 */
.dialong {
  width: 26%;
  height: 20%;
  position: absolute;
  right: 35%;
  top: 10%;
  font-size: 20px;
  font-weight: 500;
  animation-name: mymove;
  animation-duration: 2s;
  animation-timing-function: linear;
}

@keyframes mymove {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  65% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
/* 对话框2 */
.dialong2 {
  width: 26%;
  height: 20%;
  position: absolute;
  right: 32%;
  top: 32%;
  font-size: 20px;
  font-weight: 500;
  animation-name: mymove2;
  animation-duration: 4s;
  animation-timing-function: linear;
}
@keyframes mymove2 {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  65% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
/* 小唐人logo */
.dialong3 {
  position: absolute;
  left: 93%;
  top: 4%;
  width: 6%;
  // height: 22%;
  cursor: pointer;
}
//结束按钮
.begin {
  width: 12%;
  height: 6%;
  position: absolute;
  right: 4%;
  top: 87%;
  background: #037dc6;
  font-size: 18px;
}
/* 文字 */
.dialogue {
  margin: 20px !important;
  top: -10px;
  position: absolute;
}
/deep/ .el-dialog__header {
  background: #d7edfb !important;
  margin-right: 0 !important;
}

.logodialogue {
  position: absolute;
  right: 9%;
  top: 14%;
  width: 36%;
  background-color: #d7edfb;
  border-radius: 10px;
  border: 1px solid #eee;
  padding: 12px;
  font-size: calc(100vw * 16 / 1920);
  letter-spacing: 1px;
  z-index: 999;
  text-indent: 2em;
  // letter-spacing: 4px;
  line-height: 26px;
}
// .logodialogue {
//   position: absolute;
//   right: 9%;
//   top: 14%;
//   width: 36%;
//   background-color: #d7edfb;
//   border-radius: 10px;
//   border: 1px solid #eee;
//   padding: 20px;
//   // font-size: calc(100vw * 16 / 1920);
//   font-size: 16px;
//   z-index: 999;
//   text-indent: 2em;
//   letter-spacing: 4px;
//   line-height: 26px;
// }
.logodialoguex {
  position: absolute;
  top: 9%;
  right: -6%;
  width: 0px;
  height: 0px;
  border-top: 20px solid #d7edfb;
  border-left: 20px solid transparent;
  border-right: 20px solid #d7edfb;
  border-bottom: 20px solid transparent;
  transform: rotateY(180deg);
}
h3 {
  font-size: calc(100vw * 16 / 1920);
}
h3 > span {
  font-size: 16px;
}
h3 > p {
  font-size: calc(100vw * 16 / 1920);
}
* {
  font-size: calc(100vw * 20 / 1920);
}
</style>
