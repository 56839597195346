import { el } from "element-plus/lib/locale";
import { ListFormat } from "typescript";
import { toHandlers } from "vue";
import { stringifyQuery } from "vue-router";

export class VerifyPath {

    beginCity: string;
    endCity: string;
    path: string[] = [];
    currentCity: string;
    citys: string[];
    // dic: { [key: string]: string[] };
    // 原始数据字典集合
    dic: { [key: string]: string[]; } = {};
    // 每个城市对应的占线信息集合
    dicBusy: { [key: string]: string; } = {};

    optimalPath: number;
    // 用户的操作选择城市记录
    // dicOption:{[key:string]:string;} ={};


    constructor(beginCity?:String,endCity?:String,path?:any,dicBusy?:{[key: string]: string;}) {

        this.CreateData();
        this.optimalPath =0;
        while (this.optimalPath == 0) {
            //  生成开始结束地址
            if(beginCity != null && beginCity != undefined)
            {
                this.beginCity =  beginCity.toString();
                this.endCity = endCity.toString();
                this.path = path;
                this.dicBusy = dicBusy;
            }
            else{
              if(sessionStorage.getItem("Storage") == null){
                let beginCitys = ["瑞金","广州","厦门","南宁","昆明"];   //
                this.beginCity = beginCitys[Math.round(Math.random() * (beginCitys.length - 1))];
                sessionStorage.setItem("Storage", this.beginCity);
                this.path.push(this.beginCity);
              }else {
                this.beginCity = sessionStorage.getItem("Storage")
                this.path.push(this.beginCity);
              }


              if(sessionStorage.getItem("session") == null){
                let endCitys = ["延安", "济南", "西宁","兰州","郑州","西安"];
                this.endCity = endCitys[Math.round(Math.random() * (endCitys.length - 1))];
                sessionStorage.setItem("session", this.endCity);
              }else {
                this.endCity = sessionStorage.getItem("session")
              }
                // this.path = ["瑞金","九江","南昌"]
                // 生成每个面板下的已连接城市
                this.GenerateBusyCity();
            }

            // 获取最优路径
            this.GetOptimalPath();
        }
        // console.log(this.optimalPath);

    }

    SwitchCity(currentCity: string, selectedCity: string) {
        // 找到当前开始城市的所属路径
        // debugger;
        let index = this.path.indexOf(currentCity);
        this.path = this.path.slice(0, index + 1);
        this.path.push(selectedCity);
        return;
    }

    GetOptionCitys(city: string) {

        // debugger;

        let options = [];
        let selectCity = "";
        if (this.dic.hasOwnProperty(city)) {
            options = this.dic[city];
        }
        //默认连接状态信息
        if (this.dicBusy.hasOwnProperty(city)) {
            selectCity = this.dicBusy[city];
        }
        else {
            // 达到指定城市数
            if (this.dic[city].length >= 5) {
                let rand = Math.round(Math.random() * (options.length - 1));
                selectCity = options[rand];
                this.dicBusy[city] = selectCity;
            }

        }
        return [options, selectCity]
    }
    // 初始化占线城市
    GenerateBusyCity() {
        // this.dic.
        for (let city in this.dic) {
            if (this.dic[city].length >= 5) {
                let options = [];
                options = this.dic[city];
                let rand = Math.round(Math.random() * (options.length - 1));
                this.dicBusy[city] = options[rand];
            }
        }

    }




    // 获取最优路径
    GetOptimalPath() {
        let arrLink: string[][] = [];
        if (this.dic.hasOwnProperty(this.beginCity)) {

            var options: string[];
            options = this.dic[this.beginCity];
            for (var index in options) {
                // 不属于占线的城市增加进去
                if (this.dicBusy.hasOwnProperty(this.beginCity) &&
                    this.dicBusy[this.beginCity] != options[index]) {
                    let arr = [];
                    arr.push(this.beginCity);
                    arr.push(options[index]);
                    arrLink.push(arr);
                }
            }
        }
        let result: string[][] = [];
        this.GetOptimalPathByLink(arrLink, result);
        return result;
    }
    GetOptimalPathByLink(arrLink: string[][], result: string[][]) {
        let addLink = [];
        let toDeleteIndex = [];
        let index: number = 0;
        for (index = 0; index < arrLink.length; index++) {
            // let option = arrLink[index];

            if (arrLink[index].length > 0) {
                let lastCity = arrLink[index][arrLink[index].length - 1];
                if (lastCity != this.endCity) {
                    // && this.dicBusy.hasOwnProperty(lastCity) == false
                    if (this.dic.hasOwnProperty(lastCity)) {
                        for (var i = 0; i < this.dic[lastCity].length; i++) {
                            if (arrLink[index].findIndex(_ => _ == this.dic[lastCity][i]) == -1
                                && this.dicBusy[lastCity] != this.dic[lastCity][i]) {
                                let link = JSON.parse(JSON.stringify(arrLink[index]));
                                link.push(this.dic[lastCity][i]);
                                addLink.push(link);
                            }
                            else {
                                // console.log("防止循环引用，如果路径中已经含有跳过");
                            }

                        }

                    }
                }
                else {
                    result.push(arrLink[index]);
                }

            }
        }
        if (result.length == 0) {
            this.GetOptimalPathByLink(addLink, result);
        }
        this.optimalPath = result[0].length;
        return;
    }


    CreateData() {

        this.dic["瑞金"] = ["九江","南昌","厦门","广州","福州"];
        this.dic["九江"] = ["南昌","瑞金","长沙","武汉","杭州"];
        this.dic["南昌"] = ["瑞金","九江","长沙","武汉","合肥"];
        this.dic["松潘"] = ["成都","重庆","兰州","雅安","泸定"];
        this.dic["泸定"] = ["雅安","拉萨","松潘","昆明","贵阳"];
        this.dic["雅安"] = ["重庆","成都","松潘","泸定","拉萨"];

        this.dic["成都"] = ["松潘","雅安","西安","兰州","重庆"];
        this.dic["厦门"] = ["台北","福州","广州","南昌","长沙"];
        this.dic["福州"] = ["台北","杭州","南昌","长沙","瑞金"];
        this.dic["西安"] = ["延安","兰州","郑州","成都","重庆"];

        this.dic["延安"] = ["西安","济南","西宁","兰州","郑州"];
        this.dic["贵阳"] = ["遵义","昆明","南宁","桂林","长沙"];

        this.dic["遵义"] = ["贵阳","重庆","长沙","桂林","成都"];
        this.dic["南宁"] = ["广州","桂林","贵阳","昆明","遵义"];
        this.dic["桂林"] = ["广州","南宁","长沙","昆明","贵阳"];
        this.dic["兰州"] = ["西宁","腊子口","西安","松潘","延安"];
        this.dic["腊子口"] = ["兰州","松潘","西宁","西安","拉萨"];
        this.dic["昆明"] = ["拉萨","南宁","贵阳","重庆","雅安"];

        this.dic["广州"] = ["厦门","桂林","南宁","长沙","台北"];
        this.dic["长沙"] = ["南昌","武汉","桂林","重庆","贵阳"];
        // this.dic["银川"] = ["呼和浩特","西宁","兰州","延安","腊子口"];
        // this.dic["呼和浩特"] = ["银川","长春","沈阳","哈尔滨","北京"];
        this.dic["合肥"] = ["南京","九江","武汉","济南","郑州"];
        this.dic["南京"] = ["杭州","上海","合肥","济南","九江"];
        // this.dic["石家庄"] = ["北京","天津","济南","郑州","太原"];
        this.dic["济南"] = ["延安","南京","合肥","郑州","西安"];
        // this.dic["太原"] = ["石家庄","北京","呼和浩特","银川","郑州"];
        this.dic["西宁"] = ["腊子口","拉萨","兰州","松潘","成都"];
        this.dic["郑州"] = ["济南","合肥","武汉","延安","西安"];
        this.dic["武汉"] = ["九江","合肥","长沙","南昌","郑州"];
        this.dic["杭州"] = ["上海","合肥","南京","南昌","福州"];
        this.dic["上海"] = ["杭州","南京"];
        this.dic["台北"] = ["福州","厦门"];
        this.dic["重庆"] = ["遵义","成都","雅安","贵阳","长沙"];
        // this.dic["北京"] = ["天津","石家庄","沈阳","呼和浩特","太原"];
        this.dic["拉萨"] = ["腊子口","昆明","雅安","西宁","泸定"];
        // this.dic["长春"] = ["哈尔滨","沈阳","呼和哈特"];
        // this.dic["哈尔滨"] = ["长春","呼和浩特"];
        // this.dic["天津"] = ["北京","沈阳","石家庄"];
        // this.dic["乌鲁木齐"] = ["拉萨","呼和浩特","西宁","兰州"];
        // this.dic["沈阳"] = ["北京","呼和浩特","长春","石家庄","天津"];
    }

}
