<template>
	<div class="telecom">
		<!-- 模块三-电信业务 -->
		<img alt="Vue logo" src="../assets/img/imgHomePage/business_pic.jpg">
		<!-- <HelloWorld msg="Welcome to Your Vue.js App"/> -->
		<div class="businessList">
			<ul class="busItemList">
				<li v-for="(item, index) in busItemList" :key="index" @click="changeTab(index)" class="businessItem" :class="{ active: currentIndex === index }">{{ item }}</li>
			</ul>
			<span @click="backStep" class="backStep">返 回</span>
			<span @click="saveData(1)" class="backStep saveData">保 存</span>
		</div>
		<!-- 开始 -->
		<img @click="startProcessing" class="startProcessing" :src="require(`../assets/img/businessProcess/${startProcessingImg}.png`)" alt="">
		<!-- 小唐 提示 -->
		<img class="cup2" @click="showAlert" src="../assets/img/businessProcess/cup2.png" alt="">
		<div class="cup3" v-show="showRobot">
			<div class="cup3_row"></div>
			<p class="Robot_tips">理想如炬，信念如钢。为深化学习长征精神，作战指挥部办公室《铭记历史、缅怀先烈、珍爱和平、开创未来》文件要求，各作战指挥单位开展电话学习会议（要求时间{{ requiredTime }}分钟），并下载红色教育影片（数据大小为{{ dataSize }}）。请帮助作战指挥员小唐去移动运营商办理一张能支撑上述任务且符合最小成本的移动SIM卡。请点击“开始业务办理”开始任务。 </p>
		</div>
		<div class="businessPart">
			<!-- 号码 class 拼接 :class="`busPart${0}`"-->
			<div class="busPart busPart0" v-show="currentIndex == 0">
				<div class="map">
					<img class="World_map" alt="World_map" src="../assets/img/imgHomePage/World_map.png">
					<div class="China_map" @click="China_map()">
						<img :class="{ active: shinyFlag }" v-show="showFlag" alt="China_map"
							src="../assets/img/imgHomePage/china_map.png">
					</div>
					<div class="mytips" v-show="tipsFlag">
						<span>点击地图上中国领土，进入下一步号码选择。</span>
						<span class="arrow"></span>
					</div>
					<div class="dialogFormBox" v-show="dialogFormVisible">
						<el-form :model="ruleForm" :rules="rules" ref="ruleForm" :label-width="formLabelWidth" class="demo-ruleForm">
							<el-form-item label="运营商类型" prop="codeType">
								<el-select v-model="ruleForm.codeType" placeholder="请选择">
									<el-option label="移动" value="移动"></el-option>
									<el-option label="电信" value="电信"></el-option>
									<el-option label="联通" value="联通"></el-option>
								</el-select>
							</el-form-item>
							<el-form-item v-if="ruleForm.codeType == '移动'" label="移动接入码" prop="code">
								<el-select v-model="ruleForm.code" placeholder="请选择">
									<el-option v-for="item in ruleFormData[0].data" :key="`${item}_1`" :label="item" :value="item"> </el-option>
								</el-select>
							</el-form-item>
							<el-form-item v-if="ruleForm.codeType == '电信'" label="移动接入码" prop="code">
								<el-select v-model="ruleForm.code" placeholder="请选择">
									<el-option v-for="item in ruleFormData[1].data" :key="`${item}_2`" :label="item" :value="item"> </el-option>
								</el-select>
							</el-form-item>
							<el-form-item v-if="ruleForm.codeType == '联通'" label="移动接入码" prop="code">
								<el-select v-model="ruleForm.code" placeholder="请选择">
									<el-option v-for="item in ruleFormData[2].data" :key="`${item}_3`" :label="item" :value="item"> </el-option>
								</el-select>
							</el-form-item>
							<el-form-item label="省份" :label-width="formLabelWidth" prop="city">
								<el-select v-model="ruleForm.city" placeholder="请选择">
									<el-option v-for="(cityItem, index) in cityFormData" :key="index" :label="cityItem.name" :value="cityItem.code"></el-option>
									</el-select>
							</el-form-item>
							<el-form-item label="移动用户号" prop="tel">
								<el-select v-model="ruleForm.tel" placeholder="请选择">
									<el-option v-for="(codeItem, index) in userCodeList" :key="index" :label="codeItem"
										:value="codeItem">
									</el-option>
								</el-select>
							</el-form-item>
						</el-form>
					</div>
				</div>
				<div class="telList">
					<span class="telTitle">号码选择</span>
					<ul class="tel">
						<li :class="`tel${index}`" v-for="(telItem, index) in telArray" :key="telItem">{{ telItem }} </li>
					</ul>
				</div>
			</div>
			<!-- 基础业务 -->
			<div class="busPart busPart1" v-show="currentIndex == 1">
				<ul class="basic_business">
					<li v-for="(basItem, index) in basicBusinessList" :key="index" @click="basicBusinessTab(index)" class="basicBusinessItem" :class="{ basicActive: currentBusinessIndex === index }">{{ basItem }} </li>
				</ul>
				<div class="basic_business_part">
					<div class="basicPart basicPart1" v-show="currentBusinessIndex == 0">
						<img class="businessSelection" alt="业务选择" src="../assets/img/businessProcess/added_service_img.jpg">
						<div class="tableStyle">
							<table width="650" height="350" align="center" border="1" cellspacing="0">
								<thead>
									<tr>
										<th colspan="6" style="height: 80px;">
											<span class="theadTitle">业务套餐选择</span>
											<ul class="tel">
												<li v-for="telItem in telArray" :key="telItem">{{ telItem }}</li>
											</ul>
											<p class="telDetails">提示：请依次办理国内通话和国内高速流量两种业务后，完通信流程模拟！</p>
										</th>
									</tr>
								</thead>
								<tbody>
									<tr align="center">
										<td>业务办理1</td>
										<td>国内通话（分钟）</td>
										<td>月费（元）</td>
										<td>国内接听</td>
										<td>套外语言</td>
										<td>套外流量</td>
									</tr>
									<tr align="center">
										<td>
											<div class="forRadio">
												<el-radio v-model="radio1" label="100|48">&nbsp;</el-radio>
											</div>
										</td>
										<td>100</td>
										<td>48</td>
										<td rowspan="7">免接听费</td>
										<td rowspan="7">0.19元/分钟</td>
										<td rowspan="7">5元1G</td>
									</tr>
									<tr align="center">
										<td>
											<div class="forRadio">
												<el-radio v-model="radio1" label="200|88">&nbsp;</el-radio>
											</div>
										</td>
										<td>200</td>
										<td>88</td>
									</tr>
									<tr align="center">
										<td>
											<div class="forRadio">
												<el-radio v-model="radio1" label="400|128">&nbsp;</el-radio>
											</div>
										</td>
										<td>400</td>
										<td>128</td>
									</tr>
									<tr align="center">
										<td>业务办理2</td>
										<td>国内高速流量</td>
										<td></td>
									</tr>
									<tr align="center">
										<td>
											<div class="forRadio">
												<el-radio v-model="radio2" label="3G流量500M|48">&nbsp;</el-radio>
											</div>
										</td>
										<td>3G流量500M</td>
										<td>48</td>
									</tr>
									<tr align="center">
										<td>
											<div class="forRadio">
												<el-radio v-model="radio2" label="4G流量5G|88">&nbsp;</el-radio>
											</div>
										</td>
										<td>4G流量5G</td>
										<td>88</td>
									</tr>
									<tr align="center">
										<td>
											<div class="forRadio">
												<el-radio v-model="radio2" label="5G流量20G|128">&nbsp;</el-radio>
											</div>
										</td>
										<td>5G流量20G</td>
										<td>128</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>

					<div class="basicPart basicPart2" v-show="currentBusinessIndex == 1">
						<div class="BG_color">
							<!-- 颜色修改 -->
							<div class="proLine proLine1" :style="{ 'background-color': BG_color_1 }"></div>
							<div class="proLine proLine2" :style="{ 'background-color': BG_color_2 }"></div>
							<div class="proLine proLine3" :style="{ 'background-color': BG_color_3 }"></div>
							<div class="proLine proLine4" :style="{ 'background-color': BG_color_4 }"></div>
							<div class="proLine proLine5" :style="{ 'background-color': BG_color_5 }"></div>

							<!-- 下面联动效果 -->
							<div>
								<div class="proLine proLine6" :style="{ 'background-color': BG_color_6 }"></div>
								<div class="proLine proLine7" :style="{ 'background-color': BG_color_7 }"></div>
								<div class="proLine proLine8" :style="{ 'background-color': BG_color_8 }"></div>

							</div>
						</div>

						<!-- 小唐人提示 -->
						<img class="robot_textTips" @click="robotTextTipsFn()"
							src="../assets/img/businessProcess/robot_textTips.png" alt="">
						<div class="textTips" v-show="robotTextTips_show">
							<div class="row"></div>
							<p class="text">军情似火，老一辈革命先烈海娃使用鸡毛信向八路军传递重要情报。让我们追随海娃脚步，踏上新时代通信的长征路，请你帮助小通信兵海娃到达胜利的终点。</p>
							<p>1、请从起点开始，完成通信被叫方号码选择；</p>
							<p>2、依次点击白色道路阅读任务信息后进入配置界面，完成配置将所有需要路径打通；</p>
							<p>3、相关情报请点击小通信兵海娃脚下的道路获取。</p>
						</div>

						<img class="progressLine" alt="业务过程" :src="require(`../assets/img/businessProcess/${progressLineImg}.png`)">
						<div class="businessProcess">
							<img class="miniIcon speechIcon" :style="{ cursor: cursorType1 }" alt="speechIcon" :src="require(`../assets/img/businessProcess/${speechImg}.png`)">
							<img class="miniIcon dataIcon" :style="{ cursor: cursorType2 }" alt="dataIcon" :src="require(`../assets/img/businessProcess/${dataImg}.png`)">
							<div class="miniIcon postmanBox">
								<img class="postman" alt="postman" src="../assets/img/businessProcess/postman.gif">
								<div class="Chicken_feather_letter" v-show="Chicken_feather_letter_state">
									<p class="dtailstext">{{ postmanBoxText }}</p>
									<div class="buttonStyle">
										<el-button type="primary" size="mini" round @click="letterCloseFn()">确认 </el-button>
									</div>
								</div>
							</div>

							<div class="proLine proLine1" @click="postman_process_one()"></div>
							<div class="proLine proLine2" @click="postman_process_two()"></div>
							<div class="proLine proLine3" @click="postman_process_three()"></div>
							<div class="proLine proLine4" @click="postman_process_four()">
								<img v-show="coreMachineRoom_state" class="coreMachineRoom" alt="coreMachineRoom" src="../assets/img/businessProcess/coreMachineRoom.png">
							</div>

							<!-- 点击选择 号码 -->
							<div class="start_phoneBox" @click="start_phoneBox_state = true"></div>

							<!-- 数据业务 三个电话号码 -->
							<div class="mytipsBox" v-show="showTelState">
								<div class="phoneBox phoneBox1">
									<div class="phone" @click="selectPhone('8618623458866')" v-show="showPhoneState_one"></div>
									<div class="mytips mytipsStyle mytips1" v-show="showTelState_one">
										<span>手机号：8618623458866</span>
										<span>请点击确定按钮并完成接下来数据业务流程任务。</span>
										<div class="buttonStyle">
											<el-button type="primary" size="mini" @click="businessProcess_two">确认 </el-button>
										</div>
										<span class="arrow"></span>
									</div>
								</div>
								<div class="phoneBox phoneBox2">
									<div class="phone" @click="selectPhone('8615368888866')" v-show="showPhoneState_two"></div>
									<div class="mytips mytipsStyle  mytips2" v-show="showTelState_two">
										<span>手机号：8615368888866</span>
										<span>请点击确定按钮并完成接下来数据业务流程任务。</span>
										<div class="buttonStyle">
											<el-button type="primary" size="mini" @click="businessProcess_two">确认 </el-button>
										</div>
										<span class="arrow"></span>
									</div>
								</div>
								<div class="phoneBox phoneBox3">
									<div class="phone" @click="selectPhone('8613745556006')" v-show="showPhoneState_three"></div>
									<div class="mytips mytipsStyle  mytips3" v-show="showTelState_three">
										<span>手机号：8613745556006</span>
										<span>请点击确定按钮并完成接下来数据业务流程任务。</span>
										<div class="buttonStyle">
											<el-button type="primary" size="mini" @click="businessProcess_two">确认 </el-button>
										</div>
										<span class="arrow"></span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- 增值业务 -->
			<div class="busPart  busPart2" v-show="currentIndex == 2">
				<img class="added_service_img" alt="added_service_img" src="../assets/img/businessProcess/added_service_img.jpg">
				<div class="added_service">
					<ul class="service_business">
						<li class="serviceBusinessItem">选择增值业务</li>
					</ul>
					<div class="serviceBox">
						<el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange"> 全选 </el-checkbox>
						<el-checkbox-group v-model="checkedService" @change="handleCheckedCitiesChange">
							<el-checkbox v-for="(serviceItem, index) in serviceList" :label="serviceItem" :key="index"> <span class="type" v-html="serviceItem.split('|')[0]"></span><span class="details" v-html="serviceItem.split('|')[1]"></span></el-checkbox>
						</el-checkbox-group>
					</div>
					<div class="bottom_button">
						<el-button type="primary" @click="determineServic()">查看详单</el-button>
					</div>
				</div>
			</div>
		</div>
		<!-- 参数弹框 -->
		<!-- 业务流程1 -->
		<el-dialog
		title="空口控制面协议"
		:model-value="speechVisible_one"
		:before-close="handleClose_one"
		width="850px">
			<div class="PadVariableclassname">
				<progressBar v-if="sweepingRobotProgressBarName > 1" :style="`width:${sweepingRobotProgressBarName}%`"
					:sweepingRobotProgressBarName="sweepingRobotProgressBarName"></progressBar>
			</div>
			<div style="height:460px;position: relative;">
				<div class="architecture">
					<img class="linePic architecturePic" alt="architecture" src="../assets/img/businessProcess/architecture.jpg"> <div class="architecturePicBox">
						<div class="Line_button">
							<el-button type="primary" @click="speech_one_Verification()">{{ speech_one_text }} </el-button>
							<el-button type="primary" @click="speech_one_Complete()">保 存</el-button>
						</div>
						<div class="show pointList">
							<ul class="business businessListAry">
								<li v-for="(item, index) in businessListAry" :key="index"><img :id="item" class="draggable" draggable="true" :src="require(`../assets/img/protocolImg/${item}.jpg`)" :alt="item"></li>
							</ul>
							<ul class="business dragList dragList1">
								<template v-for="(item, index) in answerList.slice(0, 5)" :key="index">
									<li v-if="item" :class="`droppableEle droppable${index} dropped`">
										<span class="delectPic" @click="delectPicDataFn(index, `droppable${index}`)"></span>
										<img draggable="false" :id="`recovery${index}`" :src="require(`../assets/img/protocolImg/${item}.jpg`)" :alt="`${item}`">
									</li>
									<li v-else :class="`droppableEle droppable${index}`" :data-fildid="`${index}`"></li>
								</template>
							</ul>
							<ul class="business dragList dragList2">
								<template v-for="(item, index) in answerList.slice(5, 10)" :key="index">
									<li v-if="item" :class="`droppableEle droppable${5 + index} dropped`">
										<span class="delectPic" @click="delectPicDataFn(5 + index, `droppable${5 + index}`)"></span>
										<img draggable="false" :id="`recovery1${index}`" :src="require(`../assets/img/protocolImg/${item}.jpg`)" :alt="`${item}`">
									</li>
									<li v-else :class="`droppableEle droppable${5 + index}`" :data-fildid="`${5 + index}`"></li>
								</template>
							</ul>
							<div id="conBox" class="Right_click">
								<img src="../assets/img/protocolImg/backOption.png" alt="撤销">
							</div>
							<img v-show="noActivated" ref="postmanStep" class="shake" alt="postman" src="../assets/img/businessProcess/postman.gif">
							<!-- <span class="peopleStep" v-for="(item,index) in stepItemArray" :key="index" :style="{top: item.top,left: item.left}"></span> -->
						</div>
						<img class="wifi wifi1" v-show="wifiState" :style="{ width: widthVlaue }" alt="architecture" src="../assets/img/businessProcess/wifi.gif">
						<img class="wifi wifi2" v-show="wifiState" :style="{ width: widthVlaue }" alt="architecture" src="../assets/img/businessProcess/wifi.gif">

					</div>
				</div>
			</div>
		</el-dialog>
		<!-- 业务流程1 -->
		<el-dialog title="数据前传" :model-value="speechVisible_two"
			:before-close="handleClose_two" width="850px">
			<div class="PadVariableclassname">
				<progressBar v-if="sweepingRobotProgressBarName > 1" :style="`width:${sweepingRobotProgressBarName}%`"
					:sweepingRobotProgressBarName="sweepingRobotProgressBarName"></progressBar>
			</div>
			<div style="height:460px;position: relative;">
				<div class="architecture">
					<img class="linePic architecturePic" alt="architecture"
						src="../assets/img/businessProcess/architecture1.jpg">
					<div class="architecturePicBox process2">
						<div class="Line_button">
							<el-button type="primary" @click="speech_two_Verification()">{{ speech_two_text }}
							</el-button>
							<el-button type="primary" @click="speech_two_Complete()">保 存</el-button>
						</div>
						<ul class="treeBox">
							<li @click="handleNodeClick(1)" :class="{ active: handleNodeClickIndex === 1 }">AAU</li>
							<li @click="handleNodeClick(2)" :class="{ active: handleNodeClickIndex === 2 }">BBU</li>
						</ul>
						<div class="treeListBox" v-show="treeListBoxFlag">
							<div v-show="treeItemFlag == 1">
								<el-form :model="treeListData_one" :label-width="formLabelWidth_tree" class="demo-ruleForm">
									<el-form-item label="IFFT" prop="IFFT">
										<el-select v-model="treeListData_one.IFFT" placeholder="请选择">
											<el-option label="1024" value="1024"></el-option>
											<el-option label="2048" value="2048"></el-option>
											<el-option label="4096" value="4096"></el-option>
										</el-select>
									</el-form-item>
									<el-form-item label="子载波间隔" prop="subcarrierSpacing">
										<el-select v-model="treeListData_one.subcarrierSpacing" placeholder="请选择">
											<el-option label="15" value="15"></el-option>
											<el-option label="30" value="30"></el-option>
											<el-option label="60" value="60"></el-option>
											<el-option label="120" value="120"></el-option>
											<el-option label="240" value="240"></el-option>
										</el-select>
									</el-form-item>
									<el-form-item label="采样位宽（2倍）" prop="samplingBitWidth">
										<el-input v-model="treeListData_one.samplingBitWidth" disabled="true">
										</el-input>
									</el-form-item>
									<el-form-item label="添加控制字WO" prop="controlWord">
										<el-input v-model="treeListData_one.controlWord" disabled="true"></el-input>
									</el-form-item>
									<el-form-item label="8B/10B编码开销" prop="codingOverhead">
										<el-input v-model="treeListData_one.codingOverhead" disabled="true"></el-input>
									</el-form-item>
									<el-form-item label="通道数" prop="numberChannels">
										<el-input v-model="treeListData_one.numberChannels" disabled="true"></el-input>
									</el-form-item>
									<el-form-item label="物理层" prop="physicalLayer">
										<el-select v-model="treeListData_one.physicalLayer" placeholder="请选择">
											<el-option label="high-PHY" value="high-PHY"></el-option>
											<el-option label="low-PHY" value="low-PHY"></el-option>
										</el-select>
									</el-form-item>
								</el-form>
								<div class="compressImg compressImg1">
									<img :style="{ width: compressImg_width1 }" alt="postman"
										src="../assets/img/businessProcess/postman.png">
								</div>

								<!-- slot="footer" -->
								<div class="dialog-footer" center>
									<el-button type="primary" @click="submitForm_Compression_one()">压 缩</el-button>
									<el-button type="primary" @click="submitForm_save_one()">关 闭</el-button>
								</div>
							</div>
							<div v-show="treeItemFlag == 2">
								<el-form :model="treeListData_two" :label-width="formLabelWidth_tree"
									class="demo-ruleForm">
									<el-form-item label="IFFT" prop="IFFT">
										<el-select v-model="treeListData_two.IFFT" placeholder="请选择">
											<el-option label="1024" value="1024"></el-option>
											<el-option label="2048" value="2048"></el-option>
											<el-option label="4096" value="4096"></el-option>
										</el-select>
									</el-form-item>
									<el-form-item label="子载波间隔" prop="subcarrierSpacing">
										<el-select v-model="treeListData_two.subcarrierSpacing" placeholder="请选择">
											<el-option label="15" value="15"></el-option>
											<el-option label="30" value="30"></el-option>
											<el-option label="60" value="60"></el-option>
											<el-option label="120" value="120"></el-option>
											<el-option label="240" value="240"></el-option>
										</el-select>
									</el-form-item>
									<el-form-item label="采样位宽（2倍）" prop="samplingBitWidth">
										<el-input v-model="treeListData_two.samplingBitWidth" disabled="true">
										</el-input>
									</el-form-item>
									<el-form-item label="添加控制字WO" prop="controlWord">
										<el-input v-model="treeListData_two.controlWord" disabled="true"></el-input>
									</el-form-item>
									<el-form-item label="8B/10B编码开销" prop="codingOverhead">
										<el-input v-model="treeListData_two.codingOverhead" disabled="true"></el-input>
									</el-form-item>
									<el-form-item label="通道数" prop="numberChannels">
										<el-input v-model="treeListData_two.numberChannels" disabled="true"></el-input>
									</el-form-item>
									<el-form-item label="物理层" prop="physicalLayer">
										<el-select v-model="treeListData_two.physicalLayer" placeholder="请选择">
											<el-option label="high-PHY" value="high-PHY"></el-option>
											<el-option label="low-PHY" value="low-PHY"></el-option>
										</el-select>
									</el-form-item>
								</el-form>
								<div class="compressImg compressImg2">
									<img :style="{ width: compressImg_width2 }" alt="postman"
										src="../assets/img/businessProcess/postman.png">
								</div>
								<div class="dialog-footer" center>
									<el-button type="primary" @click="submitForm_Compression_two()">解压缩</el-button>
									<el-button type="primary" @click="submitForm_save_two()">关 闭</el-button>
								</div>
							</div>
						</div>
						<img v-show="calaShakeFlag" ref="calaShake" class="calaShake"
							:style="{ width: calaShake_width }" alt="postman"
							src="../assets/img/businessProcess/postman.gif">
						<!-- 题干提示 -->
						<img class="robot_textTips" @click="questionStemTipsFn()"
							src="../assets/img/businessProcess/robot_textTips.png" alt="">
						<div class="textTips" v-show="questionStemTips_show">
							<div class="row"></div>
							<p class="text">
								5G基站空口的大带宽和高速率引起基站AAU至BBU间的前传带宽急剧增长,对基站接入传输带来新挑战。已知在sub-6G情况下使用的带宽为100Mhz，请结合以上信息完成5G前传数据的传输。
							</p>
						</div>

					</div>
				</div>
			</div>
		</el-dialog>
		<!-- 业务流程3 -->
		<el-dialog title="光纤通信" :model-value="speechVisible_three" :close-on-click-modal="false"
			:before-close="handleClose_three" width="850px">
			<div class="PadVariableclassname">
				<progressBar v-if="sweepingRobotProgressBarName > 1" :style="`width:${sweepingRobotProgressBarName}%`"
					:sweepingRobotProgressBarName="sweepingRobotProgressBarName"></progressBar>
			</div>
			<div style="height:460px;position: relative;">
				<div class="architecture">
					<div class="line_correct_error" v-show="line_correct_error_state">
						<div class="line_state line_state1" :style="{ 'background-color': line_correct_error_state1 }"> </div>
						<div class="line_state line_state2" :style="{ 'background-color': line_correct_error_state2 }"> </div>
						<div class="line_state line_state3" :style="{ 'background-color': line_correct_error_state3 }"> </div>
					</div>
					<img class="linePic architecturePic" alt="architecture" :src="srcCheckData">
					<div class="architecturePicBox process3">
						<div class="Line_button">
							<el-button v-show="show_three_Verification" type="primary" @click="speech_three_Verification"> {{ roadEnvelope_text }} </el-button>
							<el-button type="primary" @click="speech_three_Complete">保 存</el-button>
						</div>
						<ul class="basic_business speech4">
							<li v-for="(basItem, index) in businessListData_three" :key="index" @click="speech_three_Tab(index)" class="basicBusinessItem" :class="{ basicActive: speech_three_Index === index }">{{ basItem }}</li>
						</ul>
						<div class="business_part">
							<div class="basicPart basicPart1" v-show="speech_three_Index == 0">
								<div class="shortFromBox shortFromBox1">
									<span>单/多模：</span>
									<el-select class="shortFrom" v-model="lineSimulationData.singleMode_multimode"
										placeholder="请选择">
										<el-option v-for="item in singleMode_multimode_options" :key="item.value"
											:label="item.label" :value="item.value">
										</el-option>
									</el-select>
								</div>
								<div class="shortFromBox shortFromBox2">
									<span>光纤长度：</span>
									<el-select class="shortFrom" v-model="lineSimulationData.fiberLength"
										placeholder="请选择">
										<el-option v-for="item in fiberLength_options" :key="item.value"
											:label="item.label" :value="item.value">
										</el-option>
									</el-select>
								</div>

								<div class="showButton">
									<el-button type="primary" @click="effectDisplay(1)">{{ effectDisplay_text }}
									</el-button>
								</div>

								<div class="shortFromBox shortFromBox3">
									<span>光纤类型：</span>
									<el-select class="shortFrom" v-model="lineSimulationData.fiberType"
										placeholder="请选择">
										<el-option v-for="item in singleMode_multimode_options" :key="item.value"
											:label="item.label" :value="item.value">
										</el-option>
									</el-select>
								</div>
								<div class="shortFromBox shortFromBox4">
									<span>光纤距离：</span>
									<el-select class="shortFrom" v-model="lineSimulationData.fiberDistance"
										placeholder="请选择">
										<el-option v-for="item in fiberLength_options" :key="item.value"
											:label="item.label" :value="item.value">
										</el-option>
									</el-select>
								</div>
								<div class="randomEnvelopeBox" :style="{ boxShadow: randomShadow }">
									<img class="envelopePic" alt="随机信件"
										:src="require(`../assets/img/businessProcess/${randomEvelopePic}.png`)">
								</div>
								<div class="randomEnvelopeBox1">
									<img alt="数据信件" v-show="dataEnvelopeState"
										:src="require(`../assets/img/businessProcess/${dataEnvelopeSrc}.png`)">
								</div>
								<img class="miniIcon postman" alt="postman"
									src="../assets/img/businessProcess/postman.gif">

								<!-- 信件动画 -->
								<div class="letterAnimation">
									<img v-show="singleLetter_State" class="letter_animation  letter_animation0"
										alt="信件动画"
										:src="require(`../assets/img/businessProcess/${dataEnvelopeSrc}.png`)">
									<img v-show="manyLetter_State1" class="letter_animation letter_animation1"
										alt="信件动画"
										:src="require(`../assets/img/businessProcess/${dataEnvelopeSrc}.png`)">
									<img v-show="manyLetter_State2" class="letter_animation letter_animation2"
										alt="信件动画"
										:src="require(`../assets/img/businessProcess/${dataEnvelopeSrc}.png`)">
									<img v-show="manyLetter_State3" class="letter_animation letter_animation3"
										alt="信件动画"
										:src="require(`../assets/img/businessProcess/${dataEnvelopeSrc}.png`)">
								</div>
								<!-- 基础信件提示 -->
								<img class="letter_robot" @click="show_letterMsg"
									src="../assets/img/businessProcess/cup2.png" alt="">
								<div class="letterTips" v-show="showletterMsg">
									<p>请选择不同选项组合进行实验，并注意观察实验结果。根据实验结果选出右侧鸡毛信件对应的选项。</p>
								</div>

							</div>

							<div class="basicPart basicPart2" v-show="speech_three_Index == 1">
								<div class="machineRoom" @click="machineRoomFn">
									<img v-show="baseStationRoom_state" class="baseStationRoom" alt="baseStationRoom"
										src="../assets/img/businessProcess/baseStationRoom.png">

								</div>
								<span class="randomKM randomKM1">{{ randomKM1 }}KM</span>
								<span class="randomKM randomKM2">{{ randomKM2 }}KM</span>
								<span class="randomKM randomKM3">{{ randomKM3 }}KM</span>
								<div class="shortFromBox shortFromBox1">
									<span>单/多模：</span>
									<el-select class="shortFrom" v-model="km_value_one" placeholder="请选择">
										<el-option v-for="item in singleMode_multimode_options" :key="item.value"
											:label="item.label" :value="item.value">
										</el-option>
									</el-select>
								</div>
								<div class="shortFromBox shortFromBox2">
									<span>单/多模：</span>
									<el-select class="shortFrom" v-model="km_value_two" placeholder="请选择">
										<el-option v-for="item in singleMode_multimode_options" :key="item.value"
											:label="item.label" :value="item.value">
										</el-option>
									</el-select>
								</div>
								<div class="shortFromBox shortFromBox3">
									<span>单/多模：</span>
									<el-select class="shortFrom" v-model="km_value_three" placeholder="请选择">
										<el-option v-for="item in singleMode_multimode_options" :key="item.value"
											:label="item.label" :value="item.value">
										</el-option>
									</el-select>
								</div>
								<div class="incompleteEnvelopeBox">
									<img class="incompleteEnvelope" v-show="incompleteEnvelopeState" alt="残缺/完整信件"
										:src="require(`../assets/img/businessProcess/${incompleteEnvelopeSrc}.png`)">
								</div>
								<!-- 信件沿路动画 -->
								<img class="roadEnvelope" alt="残缺/完整信件" v-show="roadEnvelopeState"
									:src="require(`../assets/img/businessProcess/${incompleteEnvelopeSrc}.png`)">

								<!-- 基础信件提示 -->
								<img class="letter_robot" @click="show_letterMsg_two"
									src="../assets/img/businessProcess/robot_textTips.png" alt="">
								<div class="letterTips" v-show="showletterMsg_two">
									<p>已知：</p>
									<p>单模：已知此类光纤每千米信号强度衰减为0.5dB/KM;</p>
									<p>多模：已知此类光纤每千米信号强度衰减为3dB/KM。</p>
									<p>请按照指挥部要求完成通信线缆搭建，要求如下：</p>
									<p>1、进行基站机房配置，确保信息传输成功；</p>
									<p>2、配置通信线缆类型，确保光纤通信线缆总衰减不超过{{ maximum_loss_value }}dB；</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</el-dialog>
		<!-- 业务流程4 -->
		<el-dialog title="核心网" :model-value="speechVisible_four" :close-on-click-modal="false"
			:before-close="handleClose_four" width="850px">
			<div class="PadVariableclassname">
				<progressBar v-if="sweepingRobotProgressBarName > 1" :style="`width:${sweepingRobotProgressBarName}%`"
					:sweepingRobotProgressBarName="sweepingRobotProgressBarName"></progressBar>
			</div>
			<div style="height:460px;position: relative;">
				<div class="architecture">
					<img class="linePic architecturePic" alt="architecture"
						src="../assets/img/businessProcess/businessBG.jpg">
					<div class="architecturePicBox process4">
						<div class="Line_button">
							<el-button type="primary" @click="speech_four_Complete">保 存</el-button>
						</div>
						<ul class="basic_business speech4">
							<template v-for="(item, index) in businessListData">
								<li :key="item.text" v-if="item.show" @click="speech_four_Tab(index)"
									class="basicBusinessItem" :class="{ basicActive: speech_four_Index === index }">
									{{ item.text }}</li>
							</template>
						</ul>
						<div class="business_part">
							<img class="letter_robot" @click="show_Msg" src="../assets/img/businessProcess/cup2.png"
								alt="">
							<div class="letterTips" v-show="showMsg">
								<p>{{ showMsg_part }}</p>
							</div>

							<div class="basicPart basicPart1" v-show="speech_four_Index == 0">
								<img class="businessBG" alt="网络架构" src="../assets/img/businessProcess/businessBG1.jpg">
								<div class="basicPartBox newBasicPartBox">
									<!-- 弹框 -->
									<div id="selectConBox" v-show="selectConBoxFlag" class="layui-colla-content Topic_content">
										<p class="TopicContent"><span></span>题目内容</p>
										<ul class="TopicList">
											<li v-for="item in TopicList" :key="item" @click="childrenItem(item)">
												<span class="ban"></span> {{ item }}
											</li>
										</ul>
									</div>

									<ul class="listDataStyle">
										<li v-for="(valueItem, index) in valueItemData" :key="valueItem" :ref="`newBasic${index}`" :class="`newBasic newBasic${index}`" @click="newBasicThing(valueItem, `newBasic${index}`, index)" :rightKeyData="valueItem" :questionScore="`${questionScoreData[index]}`">
											<p>{{ valueItemDataItem[index] }}</p>
										</li>
									</ul>
									<div class="right_button">
										<!-- 效果展示有问题 -->
										<el-button type="success" @click="checkAnswer_check"> {{ network_architecture_text }}</el-button>
									</div>
								</div>
							</div>
							<div class="basicPart basicPart2" v-show="speech_four_Index == 1">
								<div class="myProLine myProLine2">
									<div class="topSelectBox" :style="{ 'background-color': topSelect_color }"></div>
									<img class="businessBG businessBG3" alt="业务流程"
										src="../assets/img/businessProcess/businessBG3.png">
									<div class="basicPartBox newBasicPartBox2">
										<ul class="topSelect  topSelect_two">
											<li v-for="(item, index) in optionList_top_one" :key="item" :class="`topSelectItem${index}`" :ref="`topItem${index}`">
												{{ item }}
												<span v-show="item ? true : false" class="delectPic1" @click="delectPicFn_one(index)"></span>
											</li>
										</ul>
										<ul class="bottomSelect">
											<li v-for="(imgItem, index) in optionList_one" :key="imgItem" @click="clickImg_one(imgItem, index)"> {{ imgItem }} </li>
										</ul>
										<div class="bottom_button">
											<el-button type="success" @click="checkAnswer_two"> {{ communication_process_two_text }}</el-button>
										</div>
									</div>
								</div>

							</div>
							<div class="basicPart basicPart2" v-show="speech_four_Index == 2">
								<div class="myProLine myProLine1">
									<div class="topSelectBox" :style="{ 'background-color': topSelect_color_one }"> </div>
									<img class="businessBG businessBG2" alt="业务流程" src="../assets/img/businessProcess/businessBG4.png">
									<div class="basicPartBox newBasicPartBox1">
										<ul class="topSelect topSelect_one">
											<li v-for="(item, index) in optionList_top" :key="item" :class="`topSelectItem${index}`" :ref="`topItem${index}`">
											{{ item }}
												<span v-show="item ? true : false" class="delectPic1" @click="delectPicFn(index)"></span>
											</li>
										</ul>
										<ul class="bottomSelect">
											<li v-for="(imgItem, index) in optionList" :key="imgItem" @click="clickImg(imgItem, index)"> {{ imgItem }} </li>
										</ul>
										<div class="bottom_button">
											<el-button type="success" @click="checkAnswer_one"> {{ communication_process_one_text }}</el-button>
										</div>
									</div>
								</div>
							</div>

						</div>
					</div>
				</div>
			</div>
		</el-dialog>
		<!-- 基站机房 -->
		<el-dialog title="基站机房" :model-value="speechVisible_five" :close-on-click-modal="false"
			:before-close="handleClose_five" :modal=false width="850px">
			<div class="PadVariableclassname">
				<progressBar v-if="sweepingRobotProgressBarName > 1" :style="`width:${sweepingRobotProgressBarName}%`"
					:sweepingRobotProgressBarName="sweepingRobotProgressBarName"></progressBar>
			</div>
			<div style="height:400px;position: relative;overflow: auto;">
				<div class="formulaBox">
					<!-- <div class="btnSave">
             <mqf>
            <el-button  type="primary" @click="speech_five_Complete">保 存</el-button>
          </div> -->
					<p>当光进入光纤中心传播时，光纤纤芯的折射率n1比包层折射率n2高，而纤芯的损耗比包层低，这样光会发生全反射现象，其光能量主要在纤芯内传输，借助于接连不断地全反射，光可以从一端传导到另一端。
						数值孔径NA：只有在某个角度范围内的入射光可以在光纤中传输，角度α的正弦值即为数值孔径。
						<br />
						<img class="formula" alt="公式" src="../assets/img/businessProcess/formula.png">
						<br />
						请完成纤芯和包层折射率的选择，并根据公式计算出角度α，在下方进行调整，确保光能量可在光纤内部完成传输。
					</p>
				</div>
				<div class="architecture">
					<img class="linePic architecturePic" alt="architecture"
						src="../assets/img/businessProcess/srcCheck_two_1.jpg">
					<div class="architecturePicBox process5">
						<div class="computer_data computer_data1">
							<span>纤芯n1：</span>
							<el-select class="shortFrom" v-model="computer_room_data.fiberCore" placeholder="请选择">
								<el-option label="1.4492" value="1.4492"></el-option>
								<el-option label="1.444" value="1.444"></el-option>
							</el-select>
						</div>
						<div class="computer_data computer_data2">
							<span>包层n2：</span>
							<el-select class="shortFrom" v-model="computer_room_data.cladding" placeholder="请选择">
								<el-option label="1.4492" value="1.4492"></el-option>
								<el-option label="1.444" value="1.444"></el-option>
							</el-select>
						</div>
						<div class="row_point_box">
							<img class="srcCheck_row" alt="srcCheck_row"
								:style="{ transform: 'rotate(' + rowDegValue + 'deg)' }" :ref="srcCheckRow"
								src="../assets/img/businessProcess/srcCheck_row.png">

							<span class="rowPoint" :class="`rowPoint${n}`" @click="changeDeg(n, index)"
								v-for="(n, index) in row_point_list" :key="index"
								:style="{'background':(`${this.realRowDegValue == n ? 'red' : ''}`)}" :title="n"></span>
						</div>
					</div>
				</div>
				<div class="btnSave" style="text-align: center;">
					<el-button type="primary" @click="speech_five_Complete">保 存</el-button>
				</div>
			</div>
		</el-dialog>
		<!-- 增值业务 -->
		<el-dialog title="业务详单" :model-value="determineServic_state" :close-on-click-modal="false"
			:before-close="handleClose_Servic" width="850px">
			<div class="PadVariableclassname">
				<progressBar v-if="sweepingRobotProgressBarName > 1" :style="`width:${sweepingRobotProgressBarName}%`"
					:sweepingRobotProgressBarName="sweepingRobotProgressBarName"></progressBar>
			</div>
			<div style="height:450px;position: relative;">
				<div class="architecture" style="text-align: center;">
					<div class="tableStyle">
						<table width="650" height="350" align="center" border="1" cellspacing="0">
							<tbody>
								<tr align="center">
									<td>客户名称:</td>
									<td colspan="3">{{ customerName }}</td>
								</tr>
								<tr align="center">
									<td>申请号码:</td>
									<td colspan="3">
										<ul class="tel">
											<li v-for="telItem in telArray" :key="telItem">{{ telItem }}</li>
										</ul>
									</td>
								</tr>
								<tr align="center">
									<td rowspan="4">
										资费套餐
									</td>
									<td rowspan="2">语音</td>
									<td>国内通话（分钟）</td>
									<td>月费（元）</td>
								</tr>
								<tr align="center">
									<td>{{radioVal_service_1}}</td>
									<td>{{radioVal_service_2}}</td>
								</tr>
								<tr align="center">
									<td rowspan="2">数据</td>
									<td>国内流量（M）</td>
									<td>月费（元）</td>
								</tr>
								<tr align="center">
									<td>{{ radioVal_service_3 }}</td>
									<td>{{ radioVal_service_4 }}</td>
								</tr>
								<tr align="center">
									<td>
										增值业务：
									</td>
									<td colspan="3" class="detailedTypeBox">
										<ul class="value_added_service">
											<li class="addedItem" v-for="(item, index) in checkedService" :key="index">
												<p class="addedService" v-html="item.split('|')[0]"></p>
												<p class="addedPrice" v-html="item.split('|')[1]"></p>
											</li>
										</ul>
									</td>
								</tr>
								<tr align="center">
									<td>
										合计：
									</td>
									<td colspan="3"> {{ parseFloat(radioVal_service_2) + parseFloat(radioVal_service_4) + totalPrice }} 元</td>
								</tr>
							</tbody>
						</table>

						<table width="650" height="50" align="center" border="1" cellspacing="0" style="margin-top: 5px;">
							<tbody>
								<tr align="center">
									<td style="width: 160px;">
										历史记录：
									</td>
									<td colspan="3" class="detailedTypeBox">
										<div class="detailedType speech">
											<span class="type">语音详单:</span>
											<el-button @click="detailed_phonetic_list()">播 放</el-button>
										</div>
										<div class="detailedType data">
											<span class="type">数据详单:</span>
											<el-button @click="detailed_data_sheet()">播 放</el-button>
										</div>
									</td>
								</tr>
							</tbody>
						</table>

					</div>
				</div>
			</div>
		</el-dialog>
		<!-- start 被叫号码 -->
		<el-dialog title="被叫号码" :model-value="start_phoneBox_state" :before-close="handleClose_phoneBox"
			:close-on-click-modal="false" width="450px">
			<div style="height:200px;position: relative;">
				<el-form>
					<el-form-item label="被叫号码" :label-width="formLabelWidth">
						<el-select v-model="start_phoneBox_value" placeholder="请选择">
							<el-option label="8618623458866" value="8618623458866"></el-option>
							<el-option label="8615368888866" value="8615368888866"></el-option>
							<el-option label="8613745556006" value="8613745556006"></el-option>
						</el-select>
					</el-form-item>
				</el-form>
			</div>
			<div class="dialog-footer" center>
				<el-button @click="start_phoneBox_Cancel()">取 消</el-button>
				<el-button type="primary" @click="determineCellPhoneNumber()">确 定</el-button>
			</div>
		</el-dialog>
		<!-- 业务详单 -->
		<el-dialog :title="historyTitle" :model-value="businessDetails_state"
			:before-close="handleClose_businessDetails" :modal=false width="850px">
			<div class="PadVariableclassname">
				<progressBar v-if="sweepingRobotProgressBarName > 1" :style="`width:${sweepingRobotProgressBarName}%`"
					:sweepingRobotProgressBarName="sweepingRobotProgressBarName"></progressBar>
			</div>
			<div style="position: relative;">
				<div class="architecture" style="text-align: center;">
					<div class="businessDetails_play" style="height:150px;" v-show="businessDetails_type == 1">
						<audio controls ref="audioEle">
							<source :src="require(`../assets/img/businessDetails/${playMp3}.mp3`)">
						</audio>
					</div>
					<div class="businessDetails_play" style="height:450px;" v-show="businessDetails_type == 2">
						<video width="320" height="240" controls ref="videoEle">
							<source :src="require(`../assets/img/businessDetails/${playMp4}.mp4`)" type="video/mp4">
						</video>
					</div>
				</div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	// @ is an alias to /src
	// import HelloWorld from '@/components/HelloWorld.vue'
	// import dialogForm from '@/components/common/dialogForm.vue'
	import progressBar from "../components/common/progressBar.vue";
	import $ from 'jquery' //先引入再使用
	import {telecommunicationServiceEcho,telecommunicationServiceSave} from "../api/topics";
	const serviceOptions = ["呼叫转移|5元/月", "全球呼|10元/月", "短信费|3元/月", "语音短信|3元/月", "彩信费|5元/月", "GPRS流量费|3元/月", "主叫显示|3元/月","手机报及彩铃|3元/月",];
	const cityFormData_Array = [{"name": "河北省","code": "3585"},{"name": "山西省","code": "7221"},{"name": "辽宁省","code": "6789"},{
			"name": "吉林省",
			"code": "7927"
		},
		{
			"name": "黑龙江省",
			"code": "3329"
		},
		{
			"name": "江苏省",
			"code": "1883"
		},
		{
			"name": "浙江省",
			"code": "1725"
		},
		{
			"name": "安徽省",
			"code": "4549"
		},
		{
			"name": "福建省",
			"code": "8018"
		},
		{
			"name": "江西省",
			"code": "2952"
		},
		{
			"name": "山东省",
			"code": "7921"
		},
		{
			"name": "河南省",
			"code": "4454"
		},
		{
			"name": "湖北省",
			"code": "5335"
		},
		{
			"name": "湖南省",
			"code": "6660"
		},
		{
			"name": "广东省",
			"code": "1057"
		},
		{
			"name": "海南省",
			"code": "9392"
		},
		{
			"name": "四川省",
			"code": "9331"
		},
		{
			"name": "贵州省",
			"code": "7584"
		},
		{
			"name": "云南省",
			"code": "6936"
		},
		{
			"name": "陕西省",
			"code": "8149"
		},
		{
			"name": "甘肃省",
			"code": "7437"
		},
		{
			"name": "青海省",
			"code": "5807"
		},
		{
			"name": "台湾省",
			"code": "9317"
		},
		{
			"name": "北京市",
			"code": "9038"
		},
		{
			"name": "内蒙古自治区",
			"code": "1745"
		},
		{
			"name": "广西壮族自治区",
			"code": "8415"
		},
		{
			"name": "西藏自治区",
			"code": "4805"
		},
		{
			"name": "宁夏回族自治区",
			"code": "7788"
		},
		{
			"name": "新疆维吾尔自治区",
			"code": "7551"
		},
		{
			"name": "香港特别行政区",
			"code": "7273"
		},
		{
			"name": "澳门特别行政区",
			"code": "6936"
		},
		{
			"name": "天津市",
			"code": "3810"
		},
		{
			"name": "上海市",
			"code": "1375"
		},
		{
			"name": "重庆市",
			"code": "3790"
		},
	];
	/* Fisher–Yates shuffle 洗牌算法*/
	function shuffleSelf(array, size) {
		var index = -1,
			length = array.length,
			lastIndex = length - 1;
		size = size === undefined ? length : size;
		while (++index < size) {
			var rand = index + Math.floor(Math.random() * (lastIndex - index + 1)),
				value = array[rand];
			array[rand] = array[index];
			array[index] = value;
		}
		array.length = size;
		return array;
	};
	export default {
		name: 'TelecomView',
		data() {
			return {
				answer_data: {},

				customerName: this.$store.state.realname,
				radio1: "",
				radio2: "",
				// historyTitle:"",
				businessDetailsType: "语音详单:",
				businessDetailsTitle: "3G-100",
				// totalPrice:0,
				playMp3: "3G-100",
				playMp4: "3G-100",
				businessDetails_type: 1,
				cursorType1: "pointer",
				cursorType2: "not-allowed",
				currentIndex: 0,
				startProcessingImg: "startProcessingImg1",
				startProcessingImg_state: false,
				coreMachineRoom_state: false,
				baseStationRoom_state: false,
				timerShiny: null,
				coreMachineRoom_timerShiny: null,
				baseStationRoom_timerShiny: null,
				speechImg: "speech_open",
				dataImg: "data_close",
				progressLineImg: "progressLine1",
				processcompletion_state_one: false,
				processcompletion_state_two: false,
				processcompletion_state_three: false,
				processcompletion_state_four: false,
				handleNodeClickIndex: 0,
				dataSize: "",
				requiredTime: "",
				maximum_loss_value: null,
				letterClose_index: "0",
				Chicken_feather_letter_state: false,
				postmanBoxText: "",
				start_phoneBox_state: false,
				start_phoneBox_value: "",
				incompleteEnvelopeSrc: "envelope1",
				baseStation_machineRoom: false,
				rowDegValue: '58',
				realRowDegValue: '15',
				row_point_list: ['-15', '-14', '-13', '-12', '-11', '-10', '-9', '-8', '-7', '-6', '-5', '-4', '-3', '-2',
					'-1', '0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15'
				],
				degData: ['-56', '-54', '-52', '-48', '-44', '-40', '-36', '-32', '-28', '-24', '-20', '-16', '-12', '-8',
					'-4', '0', '4', '8', '12', '16', '20', '24', '28', '32', '36', '40', '44', '48', '51', '55', '58'
				],
				radioVal_service_1: "100",
				radioVal_service_2: "48",
				radioVal_service_3: "3G流量500M",
				radioVal_service_4: "48",
				determineServic_state: false,
				businessDetails_state: false,
				checkAll: false,
				checkedService: [],
				serviceList: serviceOptions,
				isIndeterminate: true,
				calaShake_width: "10%",
				calaShake_state_one: false,
				calaShake_state_two: false,
				randomKM_data: [0.5, 10, 100],
				randomKM1: "",
				randomKM2: "",
				randomKM3: "",
				randomKM1_state: false,
				randomKM2_state: false,
				randomKM3_state: false,
				singleLetter_State: false,
				manyLetter_State1: false,
				manyLetter_State2: false,
				manyLetter_State3: false,
				showTelState: false,
				showTelState_one: false,
				showTelState_two: false,
				showTelState_three: false,
				showPhoneState_one: false,
				showPhoneState_two: false,
				showPhoneState_three: false,
				robotTextTips_show: true,
				questionStemTips_show: true,
				dataEnvelopeState: false,
				incompleteEnvelopeState: false,
				roadEnvelopeState: false,
				dataEnvelopeSrc: "envelope1",
				line_correct_error_state: true,
				line_correct_error_state1: "",
				line_correct_error_state2: "",
				line_correct_error_state3: "",
				compressImg_width1: "100%",
				compressImg_width2: "100%",
				randomEvelopePic: "", //随机信件
				randomShadow: "2px 5px 15px #7e7d8d",
				speech_one_text: "效果展示",
				speech_two_text: "效果展示",
				effectDisplay_text: "效果展示",
				roadEnvelope_text: "效果展示",
				network_architecture_state: false,
				/* 核心网 */
				communication_process_one_state: false,
				communication_process_two_state: false,
				network_architecture_text: "效果展示",
				communication_process_one_text: "效果展示",
				communication_process_two_text: "效果展示",
				selectConBoxFlag: false,
				optionList: shuffleSelf(['AMF', 'AUSF', 'UDM', 'SMF', 'UPF', '公网'], 6),
				correctOptionList: ['AMF', 'AUSF', 'UDM', 'SMF', 'UPF', '公网'],
				optionList_top: ['', '', '', '', '', ''],
				optionList_one: shuffleSelf(['AMF', 'AUSF', 'UDM', 'SMF', 'UPF', 'IMS', '被叫'], 7),
				correctOptionList_one: ['AMF', 'AUSF', 'UDM', 'SMF', 'UPF', 'IMS', '被叫'],
				optionList_top_one: ['', '', '', '', '', '', ''],
				TopicList: ['', '', '', ''],
				parentTopicList: [[],[],[],[],[],[],[],[],[]],
				valueItemData: ['网络切片选择功能', '认证服务功能', '用户数据管理功能', '接入和移动管理功能', '会话管理功能', '策略控制功能', '应用功能', '用户面功能','数据网络'],
				speechDataAnswerList: [],
				questionScoreData: ['11', '11', '11', '11', '11', '11', '11', '11', '12'],
				valueItemDataItem: ['', '', '', '', '', '', '', '', ''],
				busItemList: ["号码办理", "基础业务办理", "增值业务办理"], //父盒子title,子盒子序号
				basicBusinessList: ["业务选择", "通信流程模拟"],
				businessListData: [{
						"text": "网络架构",
						"show": true
					},
					{
						"text": "语音通信流程",
						"show": true
					},
					{
						"text": "数据通信流程",
						"show": false
					},
				],
				businessListData_three: ["光纤通信基础", "光纤通信线路模拟"],
				srcCheckData: require('@/assets/img/businessProcess/srcCheck_one.jpg'),
				currentBusinessIndex: 0,
				speech_four_Index: 0,
				speech_three_Index: 0,
				telArray: ['', '', '', '', '', '', '', '', '', '', '', '', ''],
				dialogFormVisible: false,
				speechVisible_one: false,
				speechVisible_two: false,
				speechVisible_three: false,
				speechVisible_four: false,
				speechVisible_five: false,
				ruleForm: {
					codeType: '',
					code: '',
					city: '',
					tel: ''
				},
				cityFormData: cityFormData_Array,
				userCodeList: [
					'9343', '3877', '9341', '9231', '4033', '4039', '5874', '3469', '2089', '1710',
					'3290', '7474', '2302', '2647', '9575', '5796', '4979', '8533', '4344', '3955',
					'6749', '1663', '2889', '6296', '7766', '3083', '2288', '4008', '9130', '2501',
					'4951', '5200', '8298', '9805', '9281', '7997', '1786', '6641', '2850', '4514',
					'3823', '5410', '7937', '9675', '3468', '8233', '9290', '8800', '9437', '6509',
					'7931', '3555', '7147', '3600', '5591', '6628', '2835', '2040', '5766', '2663',
					'9325', '8382', '3079', '4909', '8132', '9934', '2389', '1606', '5710', '4805',
				],
				ruleFormData: [{
						type: '移动',
						data: [134, 135, 136, 137, 138, 139, 144, 147, 148, 150, 151, 152, 157, 158, 159, 172, 178,
							182, 183, 184, 187, 188, 195, 197, 198
						]
					},
					{
						type: '电信',
						data: [133, 149, 153, 173, 177, 180, 181, 189, 190, 191, 193, 199]
					},
					{
						type: '联通',
						data: [130, 131, 132, 145, 155, 156, 166, 167, 171, 175, 176, 185, 186, 196]
					}
				],
				rules: {
					codeType: [{
						required: true,
						message: '请选择运营商类型',
						trigger: 'change'
					}],
					code: [{
						required: true,
						message: '请选择移动接入码',
						trigger: 'change'
					}],
					city: [{
						required: true,
						message: '请选择省份',
						trigger: 'change'
					}],
					tel: [{
						required: true,
						message: '请选择移动用户号',
						trigger: 'change'
					}],
				},
				formLabelWidth: '120px',
				formLabelWidth_tree: '140px',
				toggletimer: '', //显示隐藏（地图）
				shinyFlag: false,
				clickNum_toggletimer: 0,
				showFlag: false,
				tipsFlag: false,
				businessListSrc: '../assets/img/protocolImg/',
				businessListAry: ["PHY", "IP", "GTP-U", "SCTP", "UDP", "MAC", "NG-AP", "RLC", "PDCP", "NAS", "SDAP", "RRC",
					"F1-AP", "DLL"
				],
				draggableElements: '',
				droppableElements: '',
				dragItemList: [],
				targetID: "",
				recoveryIndex: 0,
				noActivated: false,
				wifiState: false,
				verState_one: false,
				verState_letter: false,
				roadEnvelopeState_letter: false,
				calaShakeFlag: true,
				top_left: [{
						'top': '10%',
						'left': '40%'
					},
					{
						'top': '70%',
						'left': '40%'
					},
					{
						'top': '70%',
						'left': '80%'
					},
					{
						'top': '10%',
						'left': '80%'
					},
				],
				clickNum_one: 0,
				clickNum_letter: 0,
				roadEnvelope_letter: 0,
				answerData: false,
				answerData_four_one: false,
				answerData_four_two: false,
				answerData_four_three: false,
				clickNum1: 0,
				stepItemArray: [],
				timer: null,
				treeListBoxFlag: false,
				treeItemFlag: 1,
				treeListData_one: {
					IFFT: '',
					subcarrierSpacing: '',
					samplingBitWidth: '15',
					controlWord: '16/15',
					codingOverhead: '10/8',
					numberChannels: '64',
					physicalLayer: '',
				},
				treeListData_two: {
					IFFT: '',
					subcarrierSpacing: '',
					samplingBitWidth: '15',
					controlWord: '16/15',
					codingOverhead: '10/8',
					numberChannels: '64',
					physicalLayer: '',
				},
				showRobot: true,
				showletterMsg: true,
				showMsg: true,
				showMsg_part: "图中为5g网络架构图，为了了解和掌握5g网络架构，请给图中的核心网网元选择对应的网元功能。",
				showletterMsg_two: true,
				newItem: [],
				currentIndexNum: -1,
				currentIndexNum_one: -1,
				overalRef: "",
				overalIndex: 0,
				img_this: "",
				answerList: ['', '', '', '', '', '', '', '', '', ''],
				correctAnswerList: ['RRC', 'PDCP', 'RLC', 'MAC', 'PHY', 'RRC', 'PDCP', 'RLC', 'MAC', 'PHY'],
				widthVlaue: "22%",
				speechCheckPage_index: 1,
				speechCheckDataOne: true,
				speechCheckDataTwo: false,
				BG_color_1: '#FFF',
				BG_color_2: '#FFF',
				BG_color_two_Verification: true,
				BG_color_3: '#FFF',
				BG_color_4: '#FFF',
				BG_color_5: '#FFF',
				BG_color_6: '#FFF',
				topSelect_color: "rgba(255,255,255,0)",
				topSelect_color_one: "rgba(255,255,255,0)",
				lineSimulationData: {
					singleMode_multimode: '',
					fiberLength: '',
					fiberType: '',
					fiberDistance: ''
				},
				computer_room_data: {
					fiberCore: '',
					cladding: ''
				},
				singleMode_multimode_options: [{
					value: '单模',
					label: '单模'
				}, {
					value: '多模',
					label: '多模'
				}],
				fiberLength_options: [{
					value: '0.5KM',
					label: '0.5KM'
				}, {
					value: '10KM',
					label: '10KM'
				}, {
					value: '100KM',
					label: '100KM'
				}],
				km_value_one: '',
				km_value_two: '',
				km_value_three: '',
				show_three_Verification: false,
				compress_mini_array: ['614.592', '1229.184', '2458.368'],
				compress_middle_array: ['4916.736', '9833.472'],
				compress_max_array: ['19666.94', '39333.88'],
				initRuleFormState: true,
				submitForm_Compression_one_state: false,
				submitForm_Compression_two_state: false,
				sweepingrobotTimer: null,
				sweepingRobotProgressBarName: 0,
			}
		},
		components: {
			progressBar
			// HelloWorld
			// dialogForm

		},
		props: [
			'off'
		],
		computed: {
			historyTitle() {
				return "历史记录:" + this.businessDetailsType + '/' + this.businessDetailsTitle
			},
			totalPrice() {
				return this.checkedService.reduce(function(preValue, item) {
					return preValue + parseFloat(item.split('|')[1])
				}, 0)
			},
			codeTypeStatus: function() {
				return this.ruleForm.codeType;
			},
			codeStatus: function() {
				return this.ruleForm.code;
			},
			cityStatus: function() {
				return this.ruleForm.city;
			},
			telStatus: function() {
				return this.ruleForm.tel;
			},
		},
		watch: {
			checkedService: {
				handler(newValue, oldValue) {
					// this.getSession()
				},deep: true
			},
			ruleForm: {
				handler(newValue, oldValue) {
					// this.getSession()
				},deep: true
			},
			answerList: {
				handler(newValue, oldValue) {
					// this.getSession()
				},deep: true
			},
			lineSimulationData: {
				handler(newValue, oldValue) {
					// this.getSession()
				},deep: true
			},
			computer_room_data: {
				handler(newValue, oldValue) {
					// this.getSession()
				},deep: true
			},
			km_value_three: {
				handler(newValue, oldValue) {
					// this.getSession()
				},deep: true
			},
			radio1(){
				this.getSession()
			},
			radio2(){
				this.getSession()
			},
			km_value_one(newVal, oldVal) {
				if (newVal == "单模") {
					this.line_correct_error_state1 = "#85C226";
				} else if (newVal == "多模") {
					this.line_correct_error_state1 = "#E67817";
				}
			},
			km_value_two(newVal, oldVal) {
				if (newVal == "单模") {
					this.line_correct_error_state2 = "#85C226";
				} else if (newVal == "多模") {
					this.line_correct_error_state2 = "#E67817";
				}
			},
			km_value_three(newVal, oldVal) {
				if (newVal == "单模") {
					this.line_correct_error_state3 = "#85C226";
				} else if (newVal == "多模") {
					this.line_correct_error_state3 = "#E67817";
				}
			},
			codeTypeStatus: function(newVal, oldVal) {
				if (this.initRuleFormState) { //初始化
					this.initRuleFormState = false;
				} else {
					if (newVal != oldVal) {
						this.ruleForm.code = "";
					}
				}
			},
			codeStatus: function(newVal, oldVal) {
				this.ruleForm.code = newVal.toString();
				var newNumber2 = newVal.toString().split('');
				this.telArray[2] = newNumber2[0];
				this.telArray[3] = newNumber2[1];
				this.telArray[4] = newNumber2[2];
			},
			cityStatus: function(newVal, oldVal) {
				this.ruleForm.city = newVal.toString();
				var newNumber3 = newVal.toString().split('');
				this.telArray[5] = newNumber3[0];
				this.telArray[6] = newNumber3[1];
				this.telArray[7] = newNumber3[2];
				this.telArray[8] = newNumber3[3];
			},
			telStatus: function(newVal, oldVal) {
				this.ruleForm.tel = newVal.toString();
				var newNumber4 = newVal.toString().split('');
				this.telArray[9] = newNumber4[0];
				this.telArray[10] = newNumber4[1];
				this.telArray[11] = newNumber4[2];
				this.telArray[12] = newNumber4[3];
			},
		},
		filters: {},

		created: function() {
			//随机信件
			if (this.randomEvelopePic == "") {
				this.randomEvelopePic = "envelope" + Math.floor(Math.random() * 6 + 1);
			}

			/* 首页 题目随机值 */
			if (this.dataSize == "") {
				this.dataSize = ['2G', '10G'][Math.floor(Math.random() * 2 + 0)];
			}
			if (this.requiredTime == "") {
				this.requiredTime = ['150', '250'][Math.floor(Math.random() * 2 + 0)];
			}

			let that = this;
			if (this.randomKM1 == "" && this.randomKM2 == "" && this.randomKM3 == "") {
				this.randomKM1 = this.randomKM_data[Math.floor(Math.random() * 3 + 0)];
				this.randomKM2 = this.randomKM_data[Math.floor(Math.random() * 3 + 0)];
				this.randomKM3 = this.randomKM_data[Math.floor(Math.random() * 3 + 0)];

				/* 最大损耗表 */
				let maximumLossArray = [{
						"KM_Value": [0.5, 0.5, 0.5],
						"maximumLoss_value": 4.5
					},
					{
						"KM_Value": [0.5, 0.5, 10],
						"maximumLoss_value": 8
					},
					{
						"KM_Value": [0.5, 0.5, 100],
						"maximumLoss_value": 53
					},
					{
						"KM_Value": [0.5, 10, 10],
						"maximumLoss_value": 11.5
					},
					{
						"KM_Value": [0.5, 10, 100],
						"maximumLoss_value": 56.5
					},
					{
						"KM_Value": [0.5, 100, 0.5],
						"maximumLoss_value": 53
					},
					{
						"KM_Value": [0.5, 100, 100],
						"maximumLoss_value": 101.5
					},
					{
						"KM_Value": [10, 10, 10],
						"maximumLoss_value": 15
					},
					{
						"KM_Value": [10, 10, 100],
						"maximumLoss_value": 60
					},
					{
						"KM_Value": [10, 100, 100],
						"maximumLoss_value": 105
					},
					{
						"KM_Value": [100, 100, 100],
						"maximumLoss_value": 150
					}
				];

				let randomLoss = [this.randomKM1, this.randomKM2, this.randomKM3];

				for (var i = 0; i < maximumLossArray.length; i++) {
					(function(i) {
						if (maximumLossArray[i]["KM_Value"].sort().toString() == randomLoss.sort().toString()) {
							that.maximum_loss_value = maximumLossArray[i]["maximumLoss_value"];
						}
					})(i);
				}
			}

			/* 开始业务办理按钮 */
			this.timerShiny = setInterval(function() {
				that.startProcessingImg_state = !that.startProcessingImg_state;
				if (that.startProcessingImg_state) {
					that.startProcessingImg = "startProcessingImg1";
				} else {
					that.startProcessingImg = "startProcessingImg2";
				}
			}, 500);

			setTimeout(function() {
				window.clearInterval(that.timerShiny);
				that.startProcessingImg_state = false;
				that.startProcessingImg = "startProcessingImg2";
			}, 5000);

		},
		methods: {
			/* 语音详单/数据详单 */
			detailed_phonetic_list() {
				this.businessDetails_state = true;
				this.businessDetails_type = 1;
				this.businessDetailsType = "语音详单";
			},
			detailed_data_sheet() {
				this.businessDetails_state = true;
				this.businessDetails_type = 2;
				this.businessDetailsType = "数据详单:";
			},
			/* 确定流程底图 */
			determineCellPhoneNumber() {
				this.start_phoneBox_state = false;
				if (this.start_phoneBox_value == "8618623458866") {
					this.progressLineImg = "progressLine1";
					this.showTelState_one = true;
					this.showPhoneState_one = true;
					this.showTelState_two = false;
					this.showTelState_three = false;
					this.showPhoneState_two = false;
					this.showPhoneState_three = false;
				} else if (this.start_phoneBox_value == "8615368888866") {
					this.progressLineImg = "progressLine2";
					this.showTelState_two = true;
					this.showTelState_one = false;
					this.showTelState_three = false;
					this.showPhoneState_two = true;
					this.showPhoneState_one = false;
					this.showPhoneState_three = false;
				} else if (this.start_phoneBox_value == "8613745556006") {
					this.progressLineImg = "progressLine3";
					this.showTelState_three = true;
					this.showTelState_two = false;
					this.showTelState_one = false;
					this.showPhoneState_three = true;
					this.showPhoneState_two = false;
					this.showPhoneState_one = false;
				}
			},
			/* 通信流程 删除 */
			delectPicFn(eleIndex) {
				this.optionList_top[eleIndex] = "";
			},
			delectPicFn_one(eleIndex) {
				this.optionList_top_one[eleIndex] = "";
			},
			start_phoneBox_Cancel() {
				// this.start_phoneBox_value = "";
				this.start_phoneBox_state = false;
			},
			speech_five_Complete() {


				this.speechVisible_five = true;
				this.baseStation_machineRoom = false;
				// 基站机房 参数
				if (parseFloat(this.realRowDegValue) >= -7 && parseFloat(this.realRowDegValue) <= 7 && this
					.computer_room_data.fiberCore == "1.4492" && this.computer_room_data.cladding == "1.444") {
					this.baseStation_machineRoom = true;
				}
				this.progressBar();
				this.getSession()
			},
			/* 光纤通信线路模拟 校验 */
			threePage_road_state_Verification() {
				this.randomKM1_state = false;
				this.randomKM2_state = false;
				this.randomKM3_state = false;
				if ((this.randomKM1 == "0.5" && this.km_value_one == "单模") || (this.randomKM1 == "0.5" && this
						.km_value_one == "多模") || (this.randomKM1 == "10" && this.km_value_one == "单模") || (this
						.randomKM1 == "100" && this.km_value_one == "单模")) {
					this.randomKM1_state = true;
				}
				if ((this.randomKM2 == "0.5" && this.km_value_two == "单模") || (this.randomKM2 == "0.5" && this
						.km_value_two == "多模") || (this.randomKM2 == "10" && this.km_value_two == "单模") || (this
						.randomKM2 == "100" && this.km_value_two == "单模")) {
					this.randomKM2_state = true;
				}
				if ((this.randomKM3 == "0.5" && this.km_value_three == "单模") || (this.randomKM3 == "0.5" && this
						.km_value_three == "多模") || (this.randomKM3 == "10" && this.km_value_three == "单模") || (this
						.randomKM3 == "100" && this.km_value_three == "单模")) {
					this.randomKM3_state = true;
				}
			},
			speech_three_Verification() {
				if (this.roadEnvelope_letter == 0) {
					this.roadEnvelopeState_letter = !this.roadEnvelopeState_letter;
					this.roadEnvelope_letter++;
					this.roadEnvelopeState_letter ? this.roadEnvelope_text = "取消效果展示" : this.roadEnvelope_text = "效果展示";
					if (this.roadEnvelope_text == "取消效果展示") { //校 验
						this.roadEnvelopeState = true;
						this.threePage_road_state_Verification();
						if (this.baseStation_machineRoom && this.randomKM1_state && this.randomKM2_state && this.randomKM3_state) {
							this.incompleteEnvelopeSrc = "envelope1";
						} else {
							this.incompleteEnvelopeSrc = "incompleteEnvelope";
						}
						var that = this;
						setTimeout(function() {
							$(".roadEnvelope").animate({"top": '61%',"left": '8%'}, 1000, "linear", function() {
								$(".roadEnvelope").animate({"top": '61%',"left": '88%'}, 1000, "linear", function() {
									that.roadEnvelopeState = false;
									that.incompleteEnvelopeState = true;
									that.roadEnvelope_letter = 0;
								})
							})
						}, 200);
					} else { //"取消校验"
						this.roadEnvelope_letter = 0;
						this.incompleteEnvelopeState = false;
						$(".roadEnvelope").css({"top": '28%',"left": '8%'});
					}
				} else {
					this.$confirm('待当前信件动画执行完！', '提示', {
							confirmButtonText: '确定',
							showCancelButton: false,
						})
						.then(_ => {
							done();
						})
						.catch(_ => {});
				}
			},
			speech_three_Complete() {
				// 判断当前是否是第一次道路走完
				this.speechVisible_three = true;
				this.processcompletion_state_three = true;
				/* 光纤通信基础 */
				this.effectDisplay(2);
				/* 光纤通信线路模拟 */
				this.threePage_road_state_Verification();
				if (this.lineSimulationData.singleMode_multimode == this.lineSimulationData.fiberType && this.lineSimulationData.fiberLength == this.lineSimulationData.fiberDistance && this.dataEnvelopeSrc ==this.randomEvelopePic && this.baseStation_machineRoom && this.randomKM1_state && this.randomKM2_state && this.randomKM3_state) {
					this.BG_color_3 = "green";
				} else {
					this.BG_color_3 = "red";
				}
				this.judgmentComplete();
				this.progressBar()
				this.getSession()
			},
			/* 旋转角度 */

			changeDeg(degValue, degIndex) {
				$(`.rowPoint`).css({"background-color": "#409EFF"});
				$(`.rowPoint${degValue}`).css({"background-color": "red"});
				this.rowDegValue = this.degData[degIndex];
				this.realRowDegValue = degValue;
			},
			/* 选择增值业务 确定*/
			determineServic() {
				this.determineServic_state = true;
				this.playMp3 = parseFloat(this.radio2) + 'G-' + parseFloat(this.radio1);
				this.playMp4 = parseFloat(this.radio2) + 'G-' + parseFloat(this.radio1);
				this.businessDetailsTitle = parseFloat(this.radio2) + 'G-' + parseFloat(this.radio1);
			},
			/* 选择增值业务 */
			handleCheckAllChange(val) {
				this.checkedService = val ? serviceOptions : [];
				this.isIndeterminate = false;
			},
			handleCheckedCitiesChange(value) {
				let checkedCount = value.length;
				this.checkAll = checkedCount === this.serviceList.length;
				this.isIndeterminate = checkedCount > 0 && checkedCount < this.serviceList.length;
			},
			//业务提示
			robotTextTipsFn() {
				this.robotTextTips_show = !this.robotTextTips_show;
			},
			questionStemTipsFn() {
				this.questionStemTips_show = !this.questionStemTips_show;
			},
			//效果展示
			effectDisplay_animate() {
				if (this.clickNum_letter == 0) {
					if (this.lineSimulationData.singleMode_multimode == "单模") {
						this.singleLetter_State = true;
					} else if (this.lineSimulationData.singleMode_multimode == "多模") {
						this.manyLetter_State1 = true;
					}
					this.verState_letter = !this.verState_letter;
					this.clickNum_letter++;
					this.verState_letter ? this.effectDisplay_text = "取消效果展示" : this.effectDisplay_text = "效果展示";
					this.randomShadow = "2px 5px 15px #7e7d8d";
					if (this.effectDisplay_text == "取消效果展示") { //校 验
						var that = this;
						if (this.dataEnvelopeSrc == this.randomEvelopePic) {
							this.randomShadow = "2px 5px 15px #0c66ff";
						} else {
							this.randomShadow = "2px 5px 15px #7e7d8d";
						}
						if (this.lineSimulationData.singleMode_multimode == "单模") {
							$(".letter_animation0").css({"top": '0%',"left": '0%'});
							setTimeout(function() {
								$(".letter_animation0").animate({"top": '38%',"left": '26%'}, 1000, "linear", function() {
									$(".letter_animation0").animate({"top": '16%',"left": '60%'}, 1000, "linear", function() {
										$(".letter_animation0").animate({"top": '38%',"left": '90%'}, 1000, "linear", function() {
											that.dataEnvelopeState = true;
											that.singleLetter_State = false;
											that.clickNum_letter = 0;
										})
									})
								})
							}, 200);
						} else if (this.lineSimulationData.singleMode_multimode == "多模") {
							$(".letter_animation1,.letter_animation2").css({"top": '0%',"left": '0%'});
							$(".letter_animation3").css({"top": '38%',"left": '0%'});
							setTimeout(function() {
								this.manyLetter_State1 = true;
								$(".letter_animation1").animate({"top": '38%',"left": '26%'}, 1000, "linear", function() {
									$(".letter_animation1").animate({"top": '16%',"left": '60%'}, 1000, "linear", function() {
										$(".letter_animation1").animate({"top": '38%',"left": '90%'}, 1000, "linear", function() {
											that.dataEnvelopeState = true;
											that.manyLetter_State1 = false;
										})
									});
									setTimeout(function() {
										that.manyLetter_State2 = true;
										$(".letter_animation2").animate({
											"top": '38%',
											"left": '26%'
										}, 1000, "linear", function() {
											$(".letter_animation2").animate({
												"top": '16%',
												"left": '60%'
											}, 1000, "linear", function() {
												$(".letter_animation2").animate({
														"top": '38%',
														"left": '90%'
													}, 1000, "linear",
													function() {
														that.manyLetter_State2 =
															false;
													})
											})
										})
									}, 200);
									setTimeout(function() {
										that.manyLetter_State3 = true;
										$(".letter_animation3").animate({
											"top": '16%',
											"left": '26%'
										}, 1000, "linear", function() {
											$(".letter_animation3").animate({
												"top": '38%',
												"left": '60%'
											}, 1000, "linear", function() {
												$(".letter_animation3").animate({
														"top": '16%',
														"left": '90%'
													}, 1000, "linear",
													function() {
														that.manyLetter_State3 =
															false;
														that.clickNum_letter =
															0;
													})
											})
										})
									}, 800);
								})
							}, 200);

						}
					} else { //"取消校验"
						this.clickNum_letter = 0;
						this.dataEnvelopeState = false;
						this.randomShadow = "2px 5px 15px #7e7d8d";
						if (this.lineSimulationData.singleMode_multimode == "单模") {
							$(".letter_animation0").css({
								"top": '0%',
								"left": '0%'
							});
							this.singleLetter_State = false;
						} else if (this.lineSimulationData.singleMode_multimode == "多模") {
							$(".letter_animation1,.letter_animation2").css({
								"top": '0%',
								"left": '0%'
							});
							$(".letter_animation3").css({
								"top": '38%',
								"left": '0%'
							});
							this.manyLetter_State1 = false;
							this.manyLetter_State2 = false;
							this.manyLetter_State3 = false;
						}
					}
				} else {
					this.$confirm('待当前信件动画执行完！', '提示', {
							confirmButtonText: '确定',
							showCancelButton: false,
						})
						.then(_ => {
							done();
						})
						.catch(_ => {});
				}
			},
			effectDisplay(displayType) {
				if (this.lineSimulationData.singleMode_multimode != "" && this.lineSimulationData.fiberLength != "") {
					if (this.lineSimulationData.singleMode_multimode == "单模" && this.lineSimulationData.fiberLength ==
						"0.5KM") {
						this.dataEnvelopeSrc = "envelope1";
					} else if (this.lineSimulationData.singleMode_multimode == "单模" && this.lineSimulationData
						.fiberLength == "10KM") {
						this.dataEnvelopeSrc = "envelope2";
					} else if (this.lineSimulationData.singleMode_multimode == "单模" && this.lineSimulationData
						.fiberLength == "100KM") {
						this.dataEnvelopeSrc = "envelope3";
					} else if (this.lineSimulationData.singleMode_multimode == "多模" && this.lineSimulationData
						.fiberLength == "0.5KM") {
						this.dataEnvelopeSrc = "envelope4";
					} else if (this.lineSimulationData.singleMode_multimode == "多模" && this.lineSimulationData
						.fiberLength == "10KM") {
						this.dataEnvelopeSrc = "envelope5";
					} else if (this.lineSimulationData.singleMode_multimode == "多模" && this.lineSimulationData
						.fiberLength == "100KM") {
						this.dataEnvelopeSrc = "envelope6";
					}

					if (displayType == 1) {
						/* 信件动画 */
						this.effectDisplay_animate();
					}

				} else {
					if (displayType == 1) {
						this.$confirm('请选择单/多模,光纤长度后效果展示！', '提示', {
								confirmButtonText: '确定',
								showCancelButton: false,
							})
							.then(_ => {
								done();
							})
							.catch(_ => {});
					}
				}
			},

			//物理层过程
			businessProcess_two() {
				if (this.speechCheckPage_index == 2) {
					this.businessListData[2]["show"] = true;
					this.speech_four_Index = 2;
					this.speechVisible_four = true;
				}
			},
			selectPhone(phoneIndex) {
				if (phoneIndex == "8618623458866") {
					this.showTelState_one = !this.showTelState_one;
				} else if (phoneIndex == "8615368888866") {
					this.showTelState_two = !this.showTelState_two;
				} else if (phoneIndex == "8613745556006") {
					this.showTelState_three = !this.showTelState_three;
				}
			},
			judgmentComplete() {
				if (this.processcompletion_state_one && this.processcompletion_state_two && this.processcompletion_state_three && this.processcompletion_state_four) {
					this.speechImg = "speech_close",
						this.dataImg = "data_open";
					this.showTelState = true;
					this.cursorType1 = "not-allowed";
					this.cursorType2 = "pointer";
					this.speechCheckPage_index = 2;
					if (this.BG_color_1 == "green") {
						this.BG_color_6 = "green"; //手机道路
					} else {
						this.BG_color_6 = "red"; //手机道路
					}
					if (this.BG_color_2 == "green") {
						this.BG_color_7 = "green"; //手机道路
					} else {
						this.BG_color_7 = "red"; //手机道路
					}
					if (this.BG_color_3 == "green") {
						this.BG_color_8 = "green"; //手机道路
					} else {
						this.BG_color_8 = "red"; //手机道路
					}

					if (this.start_phoneBox_value !== "") {
						this.determineCellPhoneNumber();
					}

				}
			},
			// 网络架构
			speech_four_Complete() {
				// 判断当前是否是第一次道路走完
				this.processcompletion_state_four = true;
				this.speechVisible_four = true;
				/* 语音/数据呼叫下的核心网 */
				if (this.speech_four_Index == 0 || this.speech_four_Index == 1) {
					// 5G网络架构
					let speechAnswerList = [];
					$(".listDataStyle .newBasic").each(function(index, element) {
						if ($(element).find("p").length > 0) {
							speechAnswerList.push($(element).find("p").html());
						} else {
							speechAnswerList.push("");
						}
					});
					this.speechDataAnswerList = speechAnswerList;
					// 对比答案
					if (JSON.stringify(this.valueItemData) == JSON.stringify(speechAnswerList)) {
						this.answerData_four_one = true;
					} else {
						this.answerData_four_one = false;
					}

					// 5G物理层过程 2
					if (JSON.stringify(this.correctOptionList_one) == JSON.stringify(this.optionList_top_one)) {
						this.answerData_four_three = true;
					} else {
						this.answerData_four_three = false;
					}

					if (this.answerData_four_one && this.answerData_four_three) { //道路渲染
						this.BG_color_4 = "green"; // 第四段路
					} else {
						this.BG_color_4 = "red";
					}

				} else if (this.speech_four_Index == 2) {
					// 5G物理层过程 1
					if (JSON.stringify(this.correctOptionList) == JSON.stringify(this.optionList_top)) {
						this.answerData_four_two = true;
					} else {
						this.answerData_four_two = false;
					}

					if (this.answerData_four_one && this.answerData_four_two) { //道路渲染
						this.BG_color_5 = "green";
					} else {
						this.BG_color_5 = "red";
					}

					if (this.answerData_four_one && this.answerData_four_three && this.answerData_four_two) { //道路渲染
						this.BG_color_4 = "green"; // 第四段路
					} else {
						this.BG_color_4 = "red";
					}
				}
				this.judgmentComplete();
				this.progressBar()
				this.saveData() //核心网

			},
			machineRoomFn() {
				this.speechVisible_five = true;


			},
			// 核心网 校验 物理层过程1
			checkAnswer_one() {
				this.communication_process_one_state = !this.communication_process_one_state;
				this.communication_process_one_state ? this.communication_process_one_text = "取消效果展示" : this.communication_process_one_text = "效果展示";
				if (this.communication_process_one_text == "取消效果展示") { //校 验
					if (JSON.stringify(this.correctOptionList) == JSON.stringify(this.optionList_top)) {
						this.answerData_four_two = true;
						this.topSelect_color_one = "green";
					} else {
						this.answerData_four_two = false;
						this.topSelect_color_one = "red";
					}
				} else {
					this.topSelect_color_one = "rgba(255,255,255,0)";
					this.answerData_four_two = false;
				}
			},
			//校验 物理层过程2
			checkAnswer_two() {
				this.communication_process_two_state = !this.communication_process_two_state;
				this.communication_process_two_state ? this.communication_process_two_text = "取消效果展示" : this.communication_process_two_text = "效果展示";
				if (this.communication_process_two_text == "取消效果展示") { //校 验
					if (JSON.stringify(this.correctOptionList_one) == JSON.stringify(this.optionList_top_one)) {
						this.answerData_four_three = true;
						this.topSelect_color = "green";
					} else {
						this.answerData_four_three = false;
						this.topSelect_color = "red";
					}
				} else {
					this.topSelect_color = "rgba(255,255,255,0)";
					this.answerData_four_three = false;
				}
			},
			//校验 5G网络架构
			checkAnswer_check() {
				this.network_architecture_state = !this.network_architecture_state;
				this.network_architecture_state ? this.network_architecture_text = "取消效果展示" : this.network_architecture_text = "效果展示";
				if (this.network_architecture_text == "取消效果展示") { //校 验
					$(".newBasic").each(function(index, element) {
						if ($(element).attr('rightkeydata') == $(element).find("p").html()) {
							/* 添加对勾 */
							if ($(element).find(".Yes_No").length == 0) {
								$(element).append('<span class="Yes_No Yes"></span>');
							} else if ($(element).find(".Yes_No").length > 0) {
								$(element).find(".Yes_No").removeClass("No Yes").addClass("Yes");
							}
						} else {
							/* 添加叉叉 */
							if ($(element).find(".Yes_No").length == 0) {
								$(element).append('<span class="Yes_No No"></span>');
							} else if ($(element).find(".Yes_No").length > 0) {
								$(element).find(".Yes_No").removeClass("No Yes").addClass("No");
							}
						}
					});
				} else {
					$(".newBasic").each(function(index, element) {
						// $(element).find("p").html("");
						$(element).find("span").remove();
					});
				}
			},
			//确认
			submitForm(formName, index) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						this.currentIndex = index;
					} else {
						return false;
					}
				});
			},

			clickImg(data) {
				let newIndex = this.optionList_top.findIndex(item => item == "")
				if (newIndex > -1) {
					this.optionList_top[newIndex] = data;
				}
			},
			clickImg_one(data) {
				let newIndex = this.optionList_top_one.findIndex(item => item == "")
				if (newIndex > -1) {
					this.optionList_top_one[newIndex] = data;
				}
			},
			childrenItem(data) {
				this.valueItemDataItem[this.overalIndex] = data;
				this.selectConBoxFlag = false;
			},
			newBasicThing(data, refName, index) {
				this.overalRef = refName;
				this.overalIndex = index;
				var newRandom = ['网络切片选择功能', '认证服务功能', '用户数据管理功能', '接入和移动管理功能', '会话管理功能', '策略控制功能', '应用功能', '用户面功能','数据网络'];
				var OptionIndex = newRandom.indexOf(data);
				this.newItem = newRandom.splice(OptionIndex, 1);
				Array.prototype.random = function() {
					var idx = Math.floor((Math.random() * this.length));
					var n = this.splice(idx, 1)[0];
					return n;
				}
				var i = 0;
				var a = [];
				while (i++ < 3) {
					a.push(newRandom.random());
				}
				/* 保存固定选项 */
				if (this.parentTopicList[index].length == 0) {
					this.parentTopicList[index] = shuffleSelf(a.concat(this.newItem), 4); //打乱数组
				}
				this.TopicList = this.parentTopicList[index];
				this.selectConBoxFlag = true;
				if (refName != "newBasic6" && refName != "newBasic5" && refName != "newBasic8") {
					$("#selectConBox").css("left", parseFloat($('.' + refName).css('left')) + 30 + "px");
					$("#selectConBox").css("top", parseFloat($('.' + refName).css('top')) + 25 + "px");
				} else if (refName == "newBasic6" || refName == "newBasic5" || refName == "newBasic8") {$("#selectConBox").css("left", parseFloat($('.' + refName).css('left')) - 300 + "px");
					$("#selectConBox").css("top", parseFloat($('.' + refName).css('top')) + 30 + "px");
				}
			},
			/* 第四模块 切换页面 */
			speech_four_Tab: function(index) {
				this.speech_four_Index = index;
				this.showMsg = true;
				if (index == 0) {
					this.showMsg_part = "图中为5g网络架构图，为了了解和掌握5g网络架构，请给图中的核心网网元选择对应的网元功能。";
				} else if (index == 1 || index == 2) {
					this.showMsg_part = "请根据现在的通信业务类型，完成对应核心网基本流程填写。";
				}
			},
			speech_three_Tab: function(index) {
				this.speech_three_Index = index;
				if (index == 0) {
					this.srcCheckData = require('@/assets/img/businessProcess/srcCheck_one.jpg');
					this.show_three_Verification = false;
				} else if (index == 1) {
					this.srcCheckData = require('@/assets/img/businessProcess/srcCheck_two.png');
					this.show_three_Verification = true;

					let that = this;
					this.baseStationRoom_timerShiny = setInterval(function() {
						that.baseStationRoom_state = !that.baseStationRoom_state;
					}, 500);

					setTimeout(function() {
						window.clearInterval(that.baseStationRoom_timerShiny);
						that.baseStationRoom_state = true;
					}, 5000);

				}
			},
			/* 小唐人提示 */
			showAlert() {
				this.showRobot = !this.showRobot;
			},
			/* 基础信件提示 */
			show_letterMsg() {
				this.showletterMsg = !this.showletterMsg;
			},
			show_Msg() {
				this.showMsg = !this.showMsg;
			},
			/* 基础信件提示 */
			show_letterMsg_two() {
				this.showletterMsg_two = !this.showletterMsg_two;
			},
			/* 压 缩 */
			submitForm_Compression_one() {
				if ((this.treeListData_one.IFFT == "4096" && this.treeListData_one.subcarrierSpacing == "30" && this.treeListData_one.physicalLayer == "low-PHY") || (this.treeListData_one.IFFT == "4096" && this.treeListData_one.subcarrierSpacing == "60" && this.treeListData_one.physicalLayer == "low-PHY")){
					this.calaShake_state_one = true;
					this.compressImg_width1 = "60%";
					this.compressImg_width2 = "60%";
					this.submitForm_Compression_one_state = true;
				} else {
					this.calaShake_state_one = false;
					this.compressImg_width1 = "100%";
					this.compressImg_width2 = "100%";
					this.submitForm_Compression_one_state = false;
				}
			},
			/* 压缩的关闭 */
			submitForm_save_one() {
				this.treeListBoxFlag = false;
			},
			/* 解压缩 */
			submitForm_Compression_two() {
				// 子载波间隔 30 对应采样点数 4096
				// 子载波间隔 60 对应采样点数 2048
				if ((this.treeListData_one.IFFT == this.treeListData_two.IFFT && this.treeListData_two.IFFT == "4096" &&
						this.treeListData_one.subcarrierSpacing == this.treeListData_two.subcarrierSpacing && this
						.treeListData_two.subcarrierSpacing == "30" && this.treeListData_two.physicalLayer == "high-PHY"
						) || (this.treeListData_one.IFFT == this.treeListData_two.IFFT && this.treeListData_two.IFFT ==
						"4096" && this.treeListData_one.subcarrierSpacing == this.treeListData_two.subcarrierSpacing &&
						this.treeListData_two.subcarrierSpacing == "60" && this.treeListData_two.physicalLayer ==
						"high-PHY")) {
					this.calaShake_state_two = true;
					this.compressImg_width2 = "100%";
					this.submitForm_Compression_two_state = true;
				} else {
					this.calaShake_state_two = false;
					this.submitForm_Compression_two_state = false;
				}
			},
			/* 解压缩的关闭 */
			submitForm_save_two() {
				this.treeListBoxFlag = false;
			},
			//节点树
			handleNodeClick(dataIndex) {
				this.treeItemFlag = dataIndex;
				this.treeListBoxFlag = true;
				this.handleNodeClickIndex = dataIndex;
			},
			//通信流程模拟1 完成 、 校验
			speech_two_Complete() {
				this.progressBar()
				this.speechVisible_two = true;
				this.processcompletion_state_two = true;

				if (this.calaShake_state_one && this.calaShake_state_two) {
					this.BG_color_2 = "green";
				} else {
					this.BG_color_2 = "red";
				}
				this.judgmentComplete();
				this.saveData()
			},
			speech_two_Verification() {
				// 子载波间隔 30 对应采样点数 4096
				// 子载波间隔 60 对应采样点数 2048
				if (this.BG_color_two_Verification) {
					this.BG_color_two_Verification = false;
					this.BG_color_two_Verification ? this.speech_two_text = "效果展示" : this.speech_two_text = "取消效果展示";
					$(".calaShake").css({"width": '10%',"left": '33%',"top": '37%'});
					if (this.calaShake_state_one) {
						let that = this;
						setTimeout(function() {
							$(".calaShake").animate({"width": '6%',"top": '45%'}, 500, "linear", function() {
								that.calaShake_width = "6%";
							});
						}, 1000)
						setTimeout(function() {
							$(".calaShake").animate({"top": '45%',"left": '33%'}, 500, "linear", function() {
								$(".calaShake").animate({"top": '82%',"left": '33%'}, 500, "linear", function() {
									$(".calaShake").animate({"top": '82%',"left": '55%'}, 250, "linear", function() {
										// 管道中间
										if (that.calaShake_state_two) {
											$(".calaShake").animate({"top": '82%',"left": '74%'}, 500, "linear", function() {
												$(".calaShake").animate({"top": '37%',"left": '74%'}, 500, "linear",function() {
													that.BG_color_two_Verification =true;
													that.BG_color_two_Verification ?that.speech_two_text ="效果展示" : that.speech_two_text ="取消效果展示";
													$(".calaShake").animate({"width": '10%'}, 500,"linear",function() {
																that.calaShake_width ="10%";
															});
												})
											})
										} else {
											that.BG_color_two_Verification = true;

											that.BG_color_two_Verification ? that.speech_two_text = "效果展示" : that.speech_two_text = "取消效果展示";
											that.$confirm('请正确选择BBU完成解压缩,才能执行校验后半段动画！','提示', {
														confirmButtonText: '确定',
														showCancelButton: false,
													})
												.then(_ => {
													done();
												})
												.catch(_ => {});
										}
									})
								})
							})
						}, 2000);
					} else {
						this.BG_color_two_Verification = true;
						this.BG_color_two_Verification ? this.speech_two_text = "效果展示" : this.speech_two_text = "取消效果展示";
						this.$confirm('请正确选择AAU完成压缩,才能执行校验前半段动画！', '提示', {
								confirmButtonText: '确定',
								showCancelButton: false,
							})
							.then(_ => {
								done();
							})
							.catch(_ => {});
					}
				} else {
					this.$confirm('待校验结束！', '提示', {
							confirmButtonText: '确定',
							showCancelButton: false,
						})
						.then(_ => {
							done();
						})
						.catch(_ => {});
				}
			},
			//空口控制面协议
			speech_one_Complete() {
				this.processcompletion_state_one = true;
				this.speechVisible_one = true;
				let dataAnswerList = [];
				$(".droppableEle").each(function(index, element) {
					if ($(element).find("img").length > 0) {
						dataAnswerList.push($(element).find("img").attr("alt"));
					} else {
						dataAnswerList.push("");
					}
				});
				this.answerList = dataAnswerList;
				// 对比答案
				if (JSON.stringify(this.correctAnswerList) == JSON.stringify(dataAnswerList)) {
					this.answerData = true;
				} else {
					this.answerData = false;
				}

				if (this.answerData) { //道路渲染
					this.BG_color_1 = "green";
				} else {
					this.BG_color_1 = "red";
				}
				this.judgmentComplete();
				this.getSession();
				this.progressBar();
			},
			speech_one_Verification() {
				if (this.clickNum_one == 0) {
					this.verState_one = !this.verState_one;
					this.clickNum_one++;
					this.verState_one ? this.speech_one_text = "取消效果展示" : this.speech_one_text = "效果展示";
					if (this.speech_one_text == "取消效果展示") { //校 验
						$("#conBox").hide();
						this.noActivated = true;
						this.wifiState = true;
						var currentArray = [];
						var that = this;
						let dataAnswerList = [];
						$(".droppableEle").each(function(index, element) {
							if ($(element).find("img").length > 0) {
								dataAnswerList.push($(element).find("img").attr("alt"));
							} else {
								dataAnswerList.push("");
							}
						});
						// 对比答案
						if (JSON.stringify(this.correctAnswerList) == JSON.stringify(dataAnswerList)) {
							this.answerData = true;
							this.wifiState = true;
						} else {
							this.answerData = false;
							this.wifiState = false;
						}

						var colorBG = this.answerData ? 'green' : 'red';
						this.timer = setInterval(function() {
							currentArray.push({top: window.getComputedStyle(that.$refs.postmanStep).top,left: window.getComputedStyle(that.$refs.postmanStep).left});
							that.stepItemArray = currentArray;
							$(".pointList").append('<span class="peopleStep" style="top:' + (parseFloat(window.getComputedStyle(that.$refs.postmanStep).top) + 70) + 'px;left: ' + (parseFloat(window.getComputedStyle(that.$refs.postmanStep).left) + 20) +'px;background-color:' + colorBG + '"></span>');
						}, 5);

						setTimeout(function() {
							$(".shake").animate({"top": '10%',"left": '40%'}, 500, "linear", function() {
								$(".shake").animate({"top": '60%',"left": '40%'}, 500, "linear", function() {
									$(".shake").animate({"top": '60%',"left": '80%'}, 500, "linear", function() {
										$(".shake").animate({"top": '10%',"left": '80%'}, 500, "linear", function() {
											that.clickNum_one = 0;
											window.clearInterval(that.timer);
										})
									})
								})
							})
						}, 1000);
					} else { //"取消校验"
						this.noActivated = false;
						this.wifiState = false;
						this.clickNum_one = 0;
						$("#conBox").hide();
						$(".pointList .peopleStep").remove();
						$(".shake").css({
							"top": '10%',
							"left": '40%'
						});
					}
				} else {
					this.$confirm('待当前动画执行完！', '提示', {
							confirmButtonText: '确定',
							showCancelButton: false,
						})
						.then(_ => {
							done();
						})
						.catch(_ => {});
				}
			},
			//关闭
			handleClose_one() {this.speechVisible_one = false;},
			handleClose_two() {this.speechVisible_two = false;},
			handleClose_three() {this.speechVisible_three = false;},
			handleClose_four() {this.speechVisible_four = false;},
			handleClose_five() {this.speechVisible_five = false;},
			handleClose_Servic() {this.determineServic_state = false;},
			handleClose_businessDetails() {
				this.businessDetails_state = false;
				/* 暂停语音，视频 */
				this.$refs.audioEle.pause();
				this.$refs.videoEle.pause();
			},
			handleClose_phoneBox(){
				this.start_phoneBox_state= false;
			},
			//返回
			/* 流程 邮件员 */
			/* 点击邮递员信息框 关闭按钮 */
			letterCloseFn() {
				this.Chicken_feather_letter_state = false;
				if (this.letterClose_index == "1") {
					this.speechVisible_one = true; // 空口控制面协议
					let that = this;
					setTimeout(function() {
						that.initiateGame();
					}, 2000);
				} else if (this.letterClose_index == "2") {
					this.speechVisible_two = true;
				} else if (this.letterClose_index == "3") {
					this.speechVisible_three = true;
				} else if (this.letterClose_index == "4") {
					this.speechVisible_four = true;
				}
			},
			postman_process_one() {
				if (this.start_phoneBox_value !== "") {
					/* 邮递员显示到路段中间，显示提示，关闭提示，弹框展示 */
					$(".postmanBox").css({
						"left": "18%",
						"top": "20%"
					});
					this.postmanBoxText = "将左侧备选项中模块拖至右侧对应位置，使小通信兵能顺利通过。";
					this.Chicken_feather_letter_state = true;
					this.letterClose_index = "1";
				} else {
					this.$confirm('点击闪光手机，完成被叫号码选择后，点击白色道路阅读任务信息后进入配置界面。', '提示', {
							confirmButtonText: '确定',
							showCancelButton: false,
						})
						.then(_ => {
							done();
						})
						.catch(_ => {});
				}
			},
			postman_process_two() {
				if (this.start_phoneBox_value !== "") {
					$(".postmanBox").css({
						"left": "30%",
						"top": "20%"
					});
					this.postmanBoxText = "为AAU与BBU选择参数及配置，使小通信兵顺利通过管道。";
					this.Chicken_feather_letter_state = true;
					this.letterClose_index = "2";
				} else {
					this.$confirm('点击闪光手机，完成被叫号码选择后，点击白色道路阅读任务信息后进入配置界面', '提示', {
							confirmButtonText: '确定',
							showCancelButton: false,
						})
						.then(_ => {
							done();
						})
						.catch(_ => {});
				}
			},
			postman_process_three() {
				if (this.start_phoneBox_value !== "") {
					$(".postmanBox").css({
						"left": "52%",
						"top": "24%"
					});
					this.postmanBoxText = "根据指挥官小唐的提示，帮助小通信兵完成光纤通信基础与光纤通信线路模拟任务。";
					this.Chicken_feather_letter_state = true;
					this.letterClose_index = "3";
				} else {
					this.$confirm('点击闪光手机，完成被叫号码选择后，点击白色道路阅读任务信息后进入配置界面', '提示', {
							confirmButtonText: '确定',
							showCancelButton: false,
						})
						.then(_ => {
							done();
						})
						.catch(_ => {});
				}
			},
			postman_process_four() {
				if (this.start_phoneBox_value !== "") {
					$(".postmanBox").css({
						"left": "75%",
						"top": "35%"
					});
					this.postmanBoxText = "根据指挥官小唐的提示，帮助小通信兵完成核心机房任务。";
					this.Chicken_feather_letter_state = true;
					this.letterClose_index = "4";
				} else {
					this.$confirm('点击闪光手机，完成被叫号码选择后，点击白色道路阅读任务信息后进入配置界面', '提示', {
							confirmButtonText: '确定',
							showCancelButton: false,
						})
						.then(_ => {
							done();
						})
						.catch(_ => {});
				}
			},
			initiateGame() {
				this.draggableElements = document.querySelectorAll(".draggable");
				this.droppableElements = document.querySelectorAll(".droppableEle");
				this.draggableElements.forEach(elem => {
					elem.addEventListener("dragstart", this.dragStart);
				});
				this.droppableElements.forEach(elem => {
					elem.addEventListener("dragenter", this.dragEnter);
					elem.addEventListener("dragover", this.dragOver);
					elem.addEventListener("dragleave", this.dragLeave);
					elem.addEventListener("drop", this.drop);
				});

				$(".draggable").mousedown(function() {
					this.targetID = $(this).attr("id");
				});

			},
			dragStart(event) {
				// 拖网源
				this.targetID = event.target.id;
			},
			dragEnter(event) {
				if (event.target.classList && event.target.classList.contains("droppableEle") && !event.target.classList.contains("dropped")) {
					event.target.classList.add("droppable-hover");
				}
			},
			dragOver(event) {
				if (event.target.classList && event.target.classList.contains("droppableEle") && !event.target.classList.contains("dropped")) {
					event.preventDefault();
				}
			},
			dragLeave(event) {
				if (event.target.classList && event.target.classList.contains("droppableEle") && !event.target.classList.contains("dropped")) {
					event.target.classList.remove("droppable-hover");
				}
			},
			drop(event) {
				event.preventDefault();
				event.stopPropagation(); //阻止冒泡
				if (!event.target.classList.contains("dropped")) {
					if (this.targetID) {
						this.answerList[event.target.dataset.fildid] = this.targetID;
						event.target.classList.add("dropped");
					}
				}
			},
			// 撤回、删除
			delectPicDataFn(indexFlag, classNmae) {
				$('.' + classNmae).parent("li").removeClass("dropped");
				this.answerList[indexFlag] = "";
				let that = this;
				this.$nextTick(function() {
					that.initiateGame();
				})
			},
			//返回
			backStep() {
				this.getSession()
				this.$router.push({
					name: 'CognitionView'
				})
			},
			getSession() {
				this.answer_data = {
					"ruleForm": this.ruleForm, //运营商类型
					"telArray": this.telArray, //电话号码
					"radio1": this.radio1, //业务套餐选择
					"radio2": this.radio2,
					"start_phoneBox_value": this.start_phoneBox_value, //被叫号码
					"answerList": this.answerList, //空口协议
					"treeListData_one": this.treeListData_one,
					"treeListData_two": this.treeListData_two, //数据前传1,2
					"lineSimulationData": this.lineSimulationData, //光纤通信基础
					"realRowDegValue": this.realRowDegValue, //显示的旋转度数
					'rowDegValue': this.rowDegValue, //实际旋转度数
					"computer_room_data": this.computer_room_data, //光纤通信线路模拟 基站机房
					"km_value_one": this.km_value_one,
					"km_value_two": this.km_value_two,
					"km_value_three": this.km_value_three, //光纤通信线路模拟 传输机房1,2,3
					"line_correct_error_state1": this.line_correct_error_state1,
					"line_correct_error_state2": this.line_correct_error_state2,
					"line_correct_error_state3": this.line_correct_error_state3, //光纤跳线颜色
					"valueItemDataItem": this.valueItemDataItem,
					// "speechDataAnswerList":this.speechDataAnswerList,//核心网 网络架构
					"optionList_top": this.optionList_top, //核心网 语音通信流程
					"optionList_top_one": this.optionList_top_one, //核心网 数据通信流程
					"checkedService": this.checkedService, //选择增值业务
					"requiredTime": this.requiredTime, //首页 题目随机值
					"dataSize": this.dataSize,
					"submitForm_Compression_one_state": this.submitForm_Compression_one_state, //压缩 解压缩
					"submitForm_Compression_two_state": this.submitForm_Compression_two_state,
					"randomEvelopePic": this.randomEvelopePic, //随机信件
					"randomKM1": this.randomKM1, //随机距离
					"randomKM2": this.randomKM2,
					"randomKM3": this.randomKM3,
					"maximum_loss_value": this.maximum_loss_value, //光纤通信线缆总衰减
					"BG_color_1": this.BG_color_1,
					"BG_color_2": this.BG_color_2,
					"BG_color_3": this.BG_color_3,
					"BG_color_4": this.BG_color_4,
					"BG_color_5": this.BG_color_5,
					"BG_color_6": this.BG_color_6,
					"BG_color_7": this.BG_color_7,
					"BG_color_8": this.BG_color_8,

					'processcompletion_state_one': this.processcompletion_state_one,
					'processcompletion_state_two': this.processcompletion_state_two,
					'processcompletion_state_three': this.processcompletion_state_three,
					'processcompletion_state_four': this.processcompletion_state_four,
				};
				sessionStorage.setItem("answer_data", JSON.stringify(this.answer_data))
				this.getSave();

			},

			getSave() {
				let dataobj1 = new FormData();
				dataobj1.append("answer_data", sessionStorage.getItem("answer_data"));
				dataobj1.append('user_id', this.$store.state.user_id);
				dataobj1.append('data_sign', 'tel_service');
				dataobj1.append('task_id', this.$store.state.taskId);
				dataobj1.append('template_id', this.$store.state.template_id);
				telecommunicationServiceSave(dataobj1).then((res) => {})
			},
			//保存数据 - 本页面
			saveData(i) {
				// console.log(that);
				this.answer_data = {
					"ruleForm": this.ruleForm, //运营商类型
					"telArray": this.telArray, //电话号码
					"radio1": this.radio1, //业务套餐选择
					"radio2": this.radio2,
					"start_phoneBox_value": this.start_phoneBox_value, //被叫号码
					"answerList": this.answerList, //空口协议
					"treeListData_one": this.treeListData_one,
					"treeListData_two": this.treeListData_two, //数据前传1,2
					"lineSimulationData": this.lineSimulationData, //光纤通信基础
					"realRowDegValue": this.realRowDegValue, //显示的旋转度数
					'rowDegValue': this.rowDegValue, //实际旋转度数
					"computer_room_data": this.computer_room_data, //光纤通信线路模拟 基站机房
					"km_value_one": this.km_value_one,
					"km_value_two": this.km_value_two,
					"km_value_three": this.km_value_three, //光纤通信线路模拟 传输机房1,2,3
					"line_correct_error_state1": this.line_correct_error_state1,
					"line_correct_error_state2": this.line_correct_error_state2,
					"line_correct_error_state3": this.line_correct_error_state3, //光纤跳线颜色
					"valueItemDataItem": this.valueItemDataItem,
					// "speechDataAnswerList":this.speechDataAnswerList,//核心网 网络架构
					"optionList_top": this.optionList_top, //核心网 语音通信流程
					"optionList_top_one": this.optionList_top_one, //核心网 数据通信流程
					"checkedService": this.checkedService, //选择增值业务
					"requiredTime": this.requiredTime, //首页 题目随机值
					"dataSize": this.dataSize,
					"submitForm_Compression_one_state": this.submitForm_Compression_one_state, //压缩
					"submitForm_Compression_two_state": this.submitForm_Compression_two_state,//解压缩
					"randomEvelopePic": this.randomEvelopePic, //随机信件
					"randomKM1": this.randomKM1, //随机距离
					"randomKM2": this.randomKM2,
					"randomKM3": this.randomKM3,
					"maximum_loss_value": this.maximum_loss_value, //光纤通信线缆总衰减
					"BG_color_1": this.BG_color_1,
					"BG_color_2": this.BG_color_2,
					"BG_color_3": this.BG_color_3,
					"BG_color_4": this.BG_color_4,
					"BG_color_5": this.BG_color_5,
					"BG_color_6": this.BG_color_6,
					"BG_color_7": this.BG_color_7,
					"BG_color_8": this.BG_color_8,
					'processcompletion_state_one': this.processcompletion_state_one,
					'processcompletion_state_two': this.processcompletion_state_two,
					'processcompletion_state_three': this.processcompletion_state_three,
					'processcompletion_state_four': this.processcompletion_state_four,
				};
				sessionStorage.setItem("answer_data", JSON.stringify(this.answer_data))

				let dataobj = new FormData();
				dataobj.append("answer_data", sessionStorage.getItem("answer_data"));
				dataobj.append('user_id', this.$store.state.user_id);
				dataobj.append('data_sign', 'tel_service');
				dataobj.append('task_id', this.$store.state.taskId);
				dataobj.append('template_id', this.$store.state.template_id);

				telecommunicationServiceSave(dataobj).then((res) => {
					if (res.data.code == 200 && i == 1) {
						this.$message({
							message: '保存成功',
							type: 'success'
						});
					}
				})
			},
			//进度条动画
			progressBar() {
				if (this.sweepingRobotProgressBarName == 0 && this.sweepingRobotProgressBarName <= 110) {
					this.sweepingrobotTimer = setInterval(() => {
						this.sweepingRobotProgressBarName += 5;
					}, 100);
				}
				setInterval(() => {
					if (this.sweepingRobotProgressBarName >= 105) {
						clearInterval(this.sweepingrobotTimer);
						this.sweepingRobotProgressBarName = 0;
					}
				}, 100);
			},
			//开始业务办理
			startProcessing() {
				if (this.currentIndex == 1 || this.currentIndex == 2) {
					this.$confirm('您好！您正在业务办理中,如需修改号码请返回“号码”页操作！', '提示', {
							confirmButtonText: '确定',
							showCancelButton: false,
						})
						.then(_ => {
							done();
						})
						.catch(_ => {});

				} else if (this.currentIndex == 0) {
					this.showFlag = true;
					this.tipsFlag = true;
					if (this.clickNum_toggletimer == 0) {
						this.toggletimer = setInterval(this.shinyChange, 500); // 注意: 第一个参数为方法名的时候不要加括号;
						this.clickNum_toggletimer++;
					}
				}
			},
			shinyChange() {
				this.shinyFlag = !this.shinyFlag;
			},
			over() {
				window.clearInterval(this.toggletimer);
			},
			//号码页面重置
			changeTab: function(index) {
				this.getSession()
				if (index == 1) {
					if (this.ruleForm.codeType == "") {
						this.$confirm('请完成号码选择！', '提示', {
								confirmButtonText: '确定',
								showCancelButton: false,
							})
							.then(_ => {
								done();
							})
							.catch(_ => {});

					} else {
						this.submitForm('ruleForm', index);
					}
					this.showRobot = false;
				} else if (index == 2) {
					if (this.ruleForm.codeType == "" || !this.radio1 || !this.radio2) {
						this.$confirm('请完成号码选择，选择选择业务选择下的业务办理1和业务办理2！', '提示', {
								confirmButtonText: '确定',
								showCancelButton: false,
							})
							.then(_ => {
								done();
							})
							.catch(_ => {});
					} else {
						this.submitForm('ruleForm', index);
					}

					this.showRobot = false;
				} else {
					this.currentIndex = index;
				}
			},
			// 基础业务
			 basicBusinessTab: function (index) {
				this.getSession()
				if (index == "1") {
					// 校验业务选择
					if (this.radio1 && this.radio2 && this.radio1 !== "" && this.radio2 !== "") {
						this.currentBusinessIndex = index;
						this.radioVal_service_1 = this.radio1.split("|")[0];
						this.radioVal_service_2 = this.radio1.split("|")[1];
						this.radioVal_service_3 = this.radio2.split("|")[0];
						this.radioVal_service_4 = this.radio2.split("|")[1];
						let that = this;
						this.coreMachineRoom_timerShiny = setInterval(function() {
							that.coreMachineRoom_state = !that.coreMachineRoom_state;
						}, 500);
						setTimeout(function() {
							window.clearInterval(that.coreMachineRoom_timerShiny);
							that.coreMachineRoom_state = true;
						}, 5000);

					} else {
						this.$confirm('请先选择业务选择下的业务办理1和业务办理2！', '提示', {
								confirmButtonText: '确定',
								showCancelButton: false,
							})
							.then(_ => {
								done();
							})
							.catch(_ => {});
					}
				} else if (index == "0") {
					this.currentBusinessIndex = index;
				}
			},
			// 号码部分四个页面切换
			changePage: function(state) {
				if (state == 'back') {
					this.pageIndexFlag--;
				} else if (state == 'next') {
					this.pageIndexFlag++;
				}
				if (this.pageIndexFlag == 0) {
					this.backFlag = false;
					this.nextFlag = true;
					this.confirmTelFlag = false;
				} else if (this.pageIndexFlag == 3) {
					this.backFlag = false;
					this.nextFlag = false;
					this.confirmTelFlag = true;
					var newNumber = (this.selectData.provinceData + this.selectData.userData).split('');
					var Num = 0;
					for (var i = 5; i < 13; i++) {
						this.telArray[i] = newNumber[Num];
						Num++;
					}
				} else {
					this.backFlag = true;
					this.nextFlag = true;
					this.confirmTelFlag = false;
				}
			},
			//中国地图
			China_map: function() {
				this.dialogFormVisible = true;
				this.telArray[0] = 8;
				this.telArray[1] = 6;
			}
		},
		mounted() {
			// 回显
			let dataobj = new FormData();
			dataobj.append('user_id', this.$store.state.user_id);
			dataobj.append('data_sign', 'tel_service');
			dataobj.append('value_sign', 'all');
			dataobj.append('task_id', this.$store.state.taskId);
			dataobj.append('template_id', this.$store.state.template_id);
			let that = this
			telecommunicationServiceEcho(dataobj).then((res) => {
				if (res.data.code == 200) {
					this.watchOff = 1;
					let resultData = JSON.parse(res.data.data);
					if(!resultData) return;
					that.ruleForm = resultData.ruleForm; //运营商类型
					if (resultData.ruleForm.codeType) {
						that.ruleForm.code = resultData.ruleForm.code;
					}
					that.telArray = resultData.telArray; //电话号码
					that.radio1 = resultData.radio1; //业务套餐选择
					that.radio2 = resultData.radio2;
					that.start_phoneBox_value = resultData.start_phoneBox_value; //被叫号码
					that.answerList = resultData.answerList; //空口协议
					that.treeListData_one = resultData.treeListData_one;
					that.treeListData_two = resultData.treeListData_two; //数据前传1,2
					that.lineSimulationData = resultData.lineSimulationData; //光纤通信基础
					that.realRowDegValue = resultData.realRowDegValue; //旋转度数
					that.rowDegValue = resultData.rowDegValue, //实际旋转度数
					console.log(that.realRowDegValue,that.rowDegValue);
					that.computer_room_data = resultData.computer_room_data; //光纤通信线路模拟 基站机房
					that.km_value_one = resultData.km_value_one;
					that.km_value_two = resultData.km_value_two;
					that.km_value_three = resultData.km_value_three; //光纤通信线路模拟 传输机房1,2,3
					that.line_correct_error_state1 = resultData.line_correct_error_state1;
					that.line_correct_error_state2 = resultData.line_correct_error_state2;
					that.line_correct_error_state3 = resultData.line_correct_error_state3; //光纤跳线颜色
					that.valueItemDataItem = resultData.valueItemDataItem;
					// that.speechDataAnswerList = resultData.speechDataAnswerList;//核心网 网络架构
					that.submitForm_Compression_one_state = resultData.submitForm_Compression_one_state;
					that.submitForm_Compression_two_state = resultData.submitForm_Compression_two_state;
					that.optionList_top = resultData.optionList_top; //核心网 语音通信流程
					that.optionList_top_one = resultData.optionList_top_one; //核心网 数据通信流程
					that.checkedService = resultData.checkedService; //选择增值业务
					that.requiredTime = resultData.requiredTime; //首页 题目随机值
					that.dataSize = resultData.dataSize;
					that.randomEvelopePic = resultData.randomEvelopePic; //随机信件
					that.randomEvelopePic = resultData.randomEvelopePic; //随机信件
					that.randomKM1 = resultData.randomKM1; //随机距离
					that.randomKM2 = resultData.randomKM2;
					that.randomKM3 = resultData.randomKM3;
					that.maximum_loss_value = resultData.maximum_loss_value; //光纤通信线缆总衰减
					that.BG_color_1 = resultData.BG_color_1;
					that.BG_color_2 = resultData.BG_color_2;
					that.BG_color_3 = resultData.BG_color_3;
					that.BG_color_4 = resultData.BG_color_4;
					that.BG_color_5 = resultData.BG_color_5;
					that.BG_color_6 = resultData.BG_color_6;
					that.BG_color_7 = resultData.BG_color_7;
					that.BG_color_8 = resultData.BG_color_8;
					that.processcompletion_state_one = resultData.processcompletion_state_one,
					that.processcompletion_state_two = resultData.processcompletion_state_two,
					that.processcompletion_state_three = resultData.processcompletion_state_three,
					that.processcompletion_state_four = resultData.processcompletion_state_four,
					// this.judgmentComplete();
					this.determineCellPhoneNumber();
					if(that.submitForm_Compression_one_state){
						that.submitForm_Compression_one();
					}
					if(that.submitForm_Compression_two_state){
						that.submitForm_Compression_two();
					}

				}

			})

		},
		beforeDestroy() {
			clearInterval(this.toggletimer);
		},
	}
</script>
<!-- scope 必须是这个，不然就没有网络架构的效果显示 -->
<style lang="scss" scope>
	// // /deep/.el-dialog{
	// //   --el-dialog-width:60rem !important;
	// // }



	.row_point_box {
		position: absolute;
		width: 22% !important;
		height: 65% !important;
		top: 30% !important;
		left: 7% !important;
	}


	@import '../assets/css/dialogForm.scss';
	@import '../assets/css/tooltip.scss';
	@import '../assets/css/TelecomView.scss';
</style>
