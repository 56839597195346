import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store' // 引入vuex
import './assets/css/baseStyle.css'
import './assets/css/Intelligent/newcs.css'
import $ from 'jquery'
// import _ from "lodash"

//引用基础js文件
// import util from './assets/js/util.js'
// app.config.globalProperties.$util = util;
// 使用方法
// this.$util.show();

//引用 element   createApp添加.use(ElmentPlus)
import ElmentPlus from 'element-plus';
import 'element-plus/dist/index.css'

createApp(App).use(router).use(ElmentPlus).use(store).mount('#app')
