<template>
	<div class="winBox" v-if="showOff">
		<div class="box">
			<div class="header"><img @click="showOff = false" src="../assets/img/imgHomePage/deleteicon1.png" alt="" /></div>

		</div>

		</div>
		<div class="conter">
			<el-scrollbar height="100%">
				<p v-for="item in dataAttrs" :key="item" class="scrollbar-demo-item"
					:class="{ colorRed: item.indexOf('错误') > -1 }">
					{{ getDayTimer(systemTime) }}:{{ item }}
				</p>
			</el-scrollbar>
		</div>
</template>

<script>
	import {getDayTimer} from "../utils/tools.js"
	export default{
		porps:{
			tipsObj:{
				type: Array,
				default () {
				    return {}
				}
			},
		},
		data() {
			return {
				showOff: false,
				systemTime:new Date(),
				dataAttrs: null //新增一个对象来动态更新
			}
		},

		methods:{

			sing(){
				this.showOff = true;
			}
		},
		created(){

		},

		mounted() {
			this.dataAttrs = this.tipsObj; //用props中的对象对data中的对象赋初始值
		},

		watch: { // 监听到数据然后赋值
		      "tipsObj":{
		        // 监听数据发生变化的处理函数
		        handler(newV) {
		          this.dataAttrs = JSON.parse(JSON.stringify(newV));//将监听到修改的值赋予到dataAttrs中
		        },
		        // 是否开启深度监听，由于我们上面props中是一个Object对象，所以需要开启深度监听功能
		        deep: true
		      }
		}
	}

</script>

<style lang="scss" scoped>
	.box{
		display: flex;
		width: 100%;
		height: 15%;
		background-image: url("../assets/img/imgHomePage/give.png");
		background-size: 100%,100%;
		background-repeat: no-repeat;
		border-bottom: 2px solid #eee;
		/* background-image:url(../assets/img/imgHomePage/give.png) cover; */
		.header{
			position: absolute;
			top: 2%;
			right: 11px;
			width: 23px;
			height: 23px;
			cursor: pointer;
			z-index: 99;
			img{
				width: 100%;
				height: 100%;
			}
		}
	}
	.winBox {
	    width: 22%;
	    height: 42%;
	    position: absolute;
	    left: 3%;
	    top: 6%;
	    font-size: 14px;
		border: solid 2px #007cc2;
		background: #fff;
		z-index: 999;
	}
	.conter{
		overflow:auto;
	}

</style>
