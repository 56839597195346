<!-- 现代通信 -->
<template >
  <div class="basemap">
    <img
      class="baseimg"
      src="../../../assets/img/imgdevelopment/communication/basemap.jpg"
      alt=""
    />
    <!-- 开始按钮 -->
    <el-button type="primary" class="begin" @click="start" v-show="show"
      >开&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;始</el-button
    >
    <!-- 结束按钮 -->
    <div>
      <!-- <el-button
        type="primary"
        class="begin"
        @click="finishclick"
        v-show="finish"
        >重&nbsp;新&nbsp;开&nbsp;始</el-button
      > -->
      <!-- 答题正确 和错误展示 -->
      <div class="incobox" v-show="finish">
        <!-- 正确 -->
        <div style="top: 12%; left: 14%; position: absolute">
          <img
            class="inco"
            src="../../../assets/img/imgdevelopment/ancientpeople/correct.png"
            alt=""
          />
          <span style="font-size: 24px; position: absolute; left: 34%; top: 6%"
            >{{ correct }}次</span
          >
        </div>
        <div style="top: 52%; left: 14%; position: absolute">
          <img
            class="inco"
            src="../../../assets/img/imgdevelopment/ancientpeople/mistake.png"
            alt=""
          />
          <span style="font-size: 24px; position: absolute; left: 34%; top: 6%"
            >{{ mistake }}次</span
          >
        </div>
      </div>
    </div>

    <!-- 卫星1号 -->
    <div>
        <img
        style="width: 8%; left: 16%; top: 3%; text-align: center"
        class="satellite"
        :src="satellite1"
        alt=""
      />

      <el-button
        ref="Array"
        type="primary"
        class="but"
        id="buttonbox"
        style="left: 17%; top: 22%; text-align: center; height: 6%"
        v-if="animation"
        @click="butclick(butname)"
      >
        <span class="but1box">{{ butname }}</span></el-button
      >
    </div>

    <!-- 卫星2号 -->
    <div>
      <img
        style="left: 36%; top: 6%"
        class="satellite"
        :src="satellite2"
        alt=""
      />
      <el-button
        ref="Array"
        type="primary"
        class="but2"
        id="buttonbox"
        style="left: 37%; top: 32%; text-align: center; height: 6%"
        v-if="animation2"
        @click="butclick(butname2)"
        ><span class="but1box">{{ butname2 }}</span>
      </el-button>
    </div>

    <!-- 卫星3号 -->
    <div>
      <img
        style="left: 74%; top: 6%;"
        class="satellite"
        :src="satellite3"
        alt=""
      />
      <el-button
        ref="Array"
        type="primary"
        class="but3"
        id="buttonbox"
        style="left: 72%; top: 24%; text-align: center; height: 6%"
        v-if="animation3"
        @click="butclick(butname3)"
      >
        <span class="but1box"> {{ butname3 }}</span>
      </el-button>
    </div>

    <!-- 图片元素 -->
    <div>
      <img
        @click="imgclick(item.id, index)"
        class="element"
        id="elet"
        v-for="(item, index) in pictureArray"
        :src="item.imgsrc"
        alt=""
        :style="{ left: item.left, top: item.top, width: item.width }"
      />
    </div>
    <!-- 电波 -->
    <div>
      <img
        v-for="(item, idex) in pictureList"
        ref="list"
        class="element2"
        :style="{ left: item.left, top: item.top }"
        src="../../../assets/img/imgdevelopment/communication/radio.gif"
        alt=""
      />
    </div>

    <div>
      <!-- 小唐人logo -->
      <img
        class="dialong3"
        @click="showif"
        src="../../../assets/img/imgdevelopment/logo.png"
        alt=""
      />
      <!-- 小唐人弹出框开始 -->
      <div class="logodialogue" v-show="dialogVisible">
        <span>
          无线电波在现代技术中被广泛应用。无线电频谱资源是一种稀缺的重要战略自然资源，请你本实验中通过对不同无线电波频段的应用选择，完成对无线电波应用场景的学习。
        </span>
        <div class="logodialoguex"></div>
      </div>

      <!-- 结束 -->
      <div class="logodialogue" v-show="indialogVisible">
        <span>
          &emsp;
          长征途中党中央依靠电台无线电通信，运筹于帷幄之中，决胜于千里之外。虽然无线电通信在红军发展早期并不成熟，但在战火磨砺中不断壮大，最终成为长征胜利的坚强保障。
          无线电通信将需要传送的声音、文字、数据、图像等电信号调制在无线电波上，经空间和物理线路传送到对端。二十世纪七十年代，马丁库帕发明了手机，正式开启了移动通信时代。如今，卫星通信、WIFI、蓝牙通信等多种通信方式百花齐放。新时代的作战指挥部不仅可通过无线电台通信，而且可利用多种多样的现代通信方式进行信息传递，实现智能通信。
        </span>
        <div class="logodialoguex"></div>
      </div>
    </div>

    <!-- 正确和错误提示 -->
    <div>
      <!-- 正确 -->
      <img
        class="incoimg1"
        v-show="incoshow1"
        src="../../../assets/img/imgdevelopment/communication/correctimg1.png"
        alt=""
      />
      <!-- 错误 -->
      <img
        class="incoimg2"
        v-show="incoshow2"
        src="../../../assets/img/imgdevelopment/communication/mistakeimg.png"
        alt=""
      />
    </div>

    <!-- 答题全部正确时弹框 -->
    <el-dialog
      v-model="dialogVisibleshow"
      title="小唐人提示"
      width="30%"
      :before-close="handleClose"
    >
      <img
        style="width: 44%; margin-left: 28%; margin-top: -20px"
        src="../../../assets/img/imgdevelopment/ancientpeople/succeed.png"
        alt=""
      />
      <hr style="width: 90%; background-color: #e4e4e3; margin: 10px auto" />

      <h3 style="margin: 2%">
        <p style="font-size: 20px; text-align: center; color: #0074c5;margin: 16px;">
          <span>您答题次数为<span style="color:red">{{ tolo }}</span>次,</span>
          <span>正确次数为<span style="color:red">{{ correct }}</span>次,</span>
          <span>错误次数为<span style="color:red">{{ mistake }}</span>次!</span>
        </p>
        <span style="color: #ec4515; margin-left: 12.5%;font-size: calc(100vw * 20 / 1920);">现代通信优点：</span>
        通话双方可在移动过程中的实现通信
      </h3>
      <h3>
        <span style="color: #40b489; margin-left: 14%;font-size: calc(100vw * 20 / 1920);">现代通信缺点：</span>
        <p style="margin-left: 40%; margin-top: -4%">
          稳定性差，信息通信场景的多样化，是无线频谱资源严重受限，未来需要更有效更可靠的通信方式满足经济社会全面发展要求。
        </p>
      </h3>
      <template #footer>
        <span class="dialog-footer">
          <!-- <el-button
            style="margin-right: 22%; width: 28%; background: #549335"
            type="success"
            @click="finishclick"
            >重来一次</el-button
          > -->
          <el-button
            style="
              background: #0074c5;
              width: 28%;
              margin: auto;
              display: block;
            "
            type="primary"
            @click="confirmbut"
            >确&ensp;&ensp;&ensp;定</el-button
          >
        </span>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import { holographic } from "../../../api/topics";
export default {
  data() {
    return {
      correctArray: [], //做正确后的数组
      tolo: "0", //做题总数
      lock: false,
      finish: false,
      dialogVisible: true,
      dialogVisibleshow: false,
      indialogVisible: false,
      innerVisible: false,
      show: true,
      animation: false,
      animation2: false,
      animation3: false,
      frequency: "", //点击按钮后获取的名字
      subscript: "", //下标
      iconindex: "0",
      buttonindex: "0",
      satellite1: require("../../../assets/img/imgdevelopment/communication/satellite1.png"),
      satellite2: require("../../../assets/img/imgdevelopment/communication/satellite2.png"),
      satellite3: require("../../../assets/img/imgdevelopment/communication/satellite3.png"),
      buttonbox: [
        "极低频 3-30Hz",
        "超低频 30-300Hz",
        "特低频 300Hz-3000Hz",
        "甚低频 3kHz-30kHz",
        "低频 30kHz-300kHz",
        "中频 300kHz-3000kHz",
        "高频 3MHz-30MHz",
        "甚高频 30MHz-300MHz",
        "特高频 300MHz-3000MHz",
        "超高频 3GHz-30GHz",
        "极高频 30GHz-300GHz",
      ],
      butname: "极低频 3-30Hz",
      butname2: "",
      butname3: "",
      mistake: 0, //错误的数量
      correct: 0, //正确的数量
      incoshow1: false, //正确提示
      incoshow2: false, //错误提示
      pictureList: [
        {
          top: "60%",
          left: "19%",
          // 下标 0 航空飞机
        },
        {
          top: "58%",
          left: "30%",
          // 下标 1 微波炉
        },
        {
          top: "75%",
          left: "30%",
          // 下标 2 军事通信设备
        },
        {
          top: "66%",
          left: "37.5%",
          // 下标 3 交流电
        },
        {
          top: "56%",
          left: "43.5%",
          // 下标 5 音频电话
        },
        {
          top: "80%",
          left: "46.5%",
          // 下标 6 人体扫描仪
        },
        {
          top: "56%",
          left: "56%",
          // 下标 6 矿山
        },
        {
          top: "54%",
          left: "62%",
          // 下标 7 潜水艇
        },
        {
          top: "75%",
          left: "62%",
          // 下标 8 雷达
        },
        {
          top: "81%",
          left: "69.5%",
          // 下标 9  电视
        },
        {
          top: "65%",
          left: "73%",
          // 下标 10 船舶
        },
      ],
      pictureArray: [
        {
          width: "8%",
          top: "64%",
          left: "15%",
          imgsrc: require("../../../assets/img/imgdevelopment/communication/aircraft.png"),
          id: "中频 300kHz-3000kHz",
        },
        {
          width: "5%",
          top: "61%",
          left: "29%",
          imgsrc: require("../../../assets/img/imgdevelopment/communication/microwave.png"),
          id: "特高频 300MHz-3000MHz",
        },
        {
          width: "6%",
          top: "76%",
          left: "28%",
          imgsrc: require("../../../assets/img/imgdevelopment/communication/Military.png"),
          id: "高频 3MHz-30MHz",
        },
        {
          width: "7%",
          top: "68%",
          left: "35%",
          imgsrc: require("../../../assets/img/imgdevelopment/communication/transmission.png"),
          id: "超低频 30-300Hz",
        },
        {
          width: "5%",
          top: "58%",
          left: "42%",
          imgsrc: require("../../../assets/img/imgdevelopment/communication/phone.png"),
          id: "甚低频 3kHz-30kHz",
        },
        {
          width: "8%",
          top: "82%",
          left: "44%",
          imgsrc: require("../../../assets/img/imgdevelopment/communication/scanner.png"),
          id: "极高频 30GHz-300GHz",
        },
        {
          width: "6%",
          top: "58%",
          left: "53%",
          imgsrc: require("../../../assets/img/imgdevelopment/communication/diggings.png"),
          id: "特低频 300Hz-3000Hz",
        },
        {
          width: "5%",
          top: "56%",
          left: "61%",
          imgsrc: require("../../../assets/img/imgdevelopment/communication/submarine.png"),
          id: "极低频 3-30Hz",
        },
        {
          width: "4%",
          top: "77%",
          left: "60%",
          imgsrc: require("../../../assets/img/imgdevelopment/communication/radar.png"),
          id: "超高频 3GHz-30GHz",
        },
        {
          width: "5%",
          top: "82%",
          left: "68%",
          imgsrc: require("../../../assets/img/imgdevelopment/communication/radio.png"),
          id: "甚高频 30MHz-300MHz",
        },
        {
          width: "6%",
          top: "66%",
          left: "72%",
          imgsrc: require("../../../assets/img/imgdevelopment/communication/ship.png"),
          id: "低频 30kHz-300kHz",
        },
      ],
    };
  },
  components: {},

  methods: {
    // 开始按钮
    start() {
      this.dialogVisible = false;
      this.finish = true;
      // 替换图片
      this.show = false;
      this.satellite1 = require("../../../assets/img/imgdevelopment/communication/animation1.gif");
      this.satellite2 = require("../../../assets/img/imgdevelopment/communication/animation2.gif");
      this.satellite3 = require("../../../assets/img/imgdevelopment/communication/animation3.gif");
      //开始执行动画效果
      setTimeout(this.getElevatorList, 4000);
      setTimeout(this.getElevatorList2, 4000);
      setTimeout(this.getElevatorList3, 4000);
    },
    // 重新开始按钮
    finishclick() {
      this.$router.go(0);
    },

    // 定时器函数   动画
    getElevatorList() {
      this.animation = true;
      // 随机数据
      setInterval(() => {
        let random = Math.floor(Math.random() * (this.buttonbox.length - 0));
        this.butname = this.buttonbox[random];
        this.lock = false;
      }, 12000);
    },
    getElevatorList2() {
      // 按钮动画间隔
      this.animation2 = true;
      // 随机数据
      setInterval(() => {
        let random = Math.floor(Math.random() * (this.buttonbox.length - 0));
        this.butname2 = this.buttonbox[random];
        this.lock = false;
      }, 12000);
    },
    getElevatorList3() {
      this.animation3 = true;
      setInterval(() => {
        let random = Math.floor(Math.random() * (this.buttonbox.length - 0));
        this.butname3 = this.buttonbox[random];
        this.lock = false;
      }, 12000);
    },

    // 按钮
    butclick(val) {
      // console.log(val);
      this.frequency = val;
      this.buttonindex = "1";
      this.iconindex = "";
      // console.log(this.frequency);
    },
    // 图片按钮
    imgclick(val, index) {
      if (this.lock == true) {
        return false;
      }
      // console.log(val);
      // console.log(index);
      this.iconindex = "1";
      // 判断成功时候执行
      if (this.frequency == val) {
        // document.getElementById("buttonbox").style.display="none";
        this.correct++;
        this.incoshow1 = true;
        const index2 = this.buttonbox.findIndex((item) => item === val);
        this.buttonbox.splice(index2, 1);
        this.correctArray.push(val);
        // console.log(this.correctArray)
        this.$refs.list[index].style.opacity = "1";
        this.lock = true;
        setTimeout(() => {
          this.incoshow1 = false;
        }, 2000);
        // 调用VUEX保存数据
        // this.tolo = this.correct + this.mistake;
        // // console.log(this.tolo)
        // this.$store.commit("sum_modernCommuni", [
        //   this.tolo,
        //   this.correct,
        //   this.mistake,
        //   this.correctArray,
        //   this.buttonbox,
        // ]);
      }
      //判断失败时候执行
      if (this.iconindex == this.buttonindex) {
        if (this.frequency != val) {
          this.mistake++;
          this.incoshow2 = true;
          this.buttonindex = "";
          setTimeout(() => {
            this.incoshow2 = false;
          }, 2000);
        }
      }

      if (this.buttonbox.length < 1) {
        this.animation = false;
        this.animation2 = false;
        this.animation3 = false;
        this.dialogVisibleshow = true;

        this.tolo = this.correct + this.mistake;
        // console.log(this.tolo)
        this.$store.commit("sum_modernCommuni", [
          this.tolo,
          this.correct,
          this.mistake,
          this.correctArray,
          this.buttonbox,
        ]);
      }
      this.frequency = "";
    },
    initialize() {
      if (this.butname == "极低频") {
      } else if (this.butname == "超低频") {
      } else if (this.butname == "特低频") {
      } else if (this.butname == "甚低频") {
      } else if (this.butname == "低频") {
      } else if (this.butname == "中频") {
      } else if (this.butname == "高频") {
      } else if (this.butname == "甚高频") {
      } else if (this.butname == "特高频") {
      } else if (this.butname == "超高频") {
      } else if (this.butname == "极高频") {
      }
    },
    // 弹框确定按钮
    confirmbut() {
      this.dialogVisibleshow = false;
      this.indialogVisible = true;
      this.dialogVisible = false;
      let data = "Replay?6;"
      let obj = new FormData();
      obj.append("data", data);
      holographic(obj).then((res) => {
        console.log(res)
      });

    },
    // 小唐人按钮
    showif() {
      this.dialogVisible = !this.dialogVisible;
      this.indialogVisible = false;
    },
  },
  mounted() {
    this.initialize();
  },
};
</script>
<style  scoped lang="scss">
.basemap {
  width: 100%;
  position: relative;
  box-sizing: border-box;
  padding: 10px;
}
/* 底图img */
.baseimg {
  width: 100%;
  position: relative;
  box-sizing: border-box;
}
// 开始按钮
.begin {
  width: 10%;
  height: 6%;
  position: absolute;
  right: 87%;
  top: 4%;
  background: #037dc6;
  font-size: 18px;
}
// 卫星
.satellite {
  width: 12%;
  position: absolute;
  left: 0%;
  top: 0%;
  z-index: 99999999;
}
//扩大按钮范围
.but1box {
  width: 100%;
  height: 12%;
}
// 卫星按钮
.but {
  background: -webkit-linear-gradient(#d7edfb, #1e8dc4);
  position: absolute;
  top: 0%;
  left: 0%;
  color: #1c1a27;
  font-weight: 600;
  font-size: 15px;
  z-index: 9999;
  text-align: center;
  animation-name: example;
  animation-duration: 36s;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite; //  动画播放次数,infinite：一直播放
}
.but:hover {
  color: rgb(117, 6, 6) !important;
}
.but2:hover {
  color: rgb(117, 6, 6) !important;
}
.but3:hover {
  color: rgb(117, 6, 6) !important;
}
.but:focus {
  color: rgb(119, 4, 4);
  background: -webkit-linear-gradient(#ffffff, #ff9100);
}
.but2:focus {
  color: rgb(119, 4, 4);
  background: -webkit-linear-gradient(#ffffff, #ff9100);
}
.but3:focus {
  color: rgb(119, 4, 4);
  background: -webkit-linear-gradient(#ffffff, #ff9100);
}

@keyframes example {
  0% {
    top: 20%;
  }
  30% {
    opacity: 1;
  }
  33% {
    top: 94%;
    // transform: rotate(-1460deg);
    opacity: 0;
  }
  35%{
    top: 20%;
    // transform: rotate(-1460deg);
    opacity: 0;
  }
  95% {
    // top: 110%;
    opacity: 0;
  }
  /* 50% { right:600; top: 200px;} */
  100% {
    // top: 110%;
    // transform: rotate(-1460deg);
    opacity: 0;
  }
}
// 按钮2
.but2 {
  background: -webkit-linear-gradient(#d7edfb, #1e8dc4);
  position: absolute;
  top: 0%;
  left: 0%;
  font-weight: 600;
  font-size: 15px;
  z-index: 9999;
  color: #1c1a27;
  text-align: center;
  animation-name: example2;
  animation-duration: 36s;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite; //  动画播放次数,infinite：一直播放
  opacity: 0;
}

@keyframes example2 {
  0% {
    top: 20%;
    opacity: 0;
  }
  20% {
    top: 20%;
    opacity: 0;
  }
  30% {
    top: 20%;
    opacity: 0;
  }
  33% {
    opacity: 0;
  }
  34% {
    top: 20%;
    opacity: 1;
  }
  62% {
    opacity: 1;
  }
  67% {
    top: 94%;
    opacity: 0;
  }
  69%{
    top: 20%;
    opacity: 0;
  }
  79%{
    top: 20%;
    opacity: 0;
  }
  95% {
    top: 20%;
    opacity: 0;
  }
  /* 50% { right:600; top: 200px;} */
  100% {
    top: 20%;
    // transform: rotate(-1460deg);
    opacity: 0;
  }
}
// 按钮3
.but3 {
  background: -webkit-linear-gradient(#d7edfb, #1e8dc4);
  position: absolute;
  top: 0%;
  left: 0%;
  font-weight: 600;
  font-size: 15px;
  text-align: center;
  z-index: 9999;
  color: #1c1a27;
  animation-name: example3;
  animation-duration: 36s;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite; //  动画播放次数,infinite：一直播放
  opacity: 0;
}
@keyframes example3 {
  0% {
    top: 20%;
  }
  30% {
    top: 20%;
    opacity: 0;
  }
  67% {
    opacity: 0;
  }
  68% {
    top: 20%;
    opacity: 1;
  }
  95% {
    opacity: 1;
  }
  100% {
    top: 94%;
    opacity: 0;
  }
}
// 底部元素
.element {
  position: absolute;
  top: 0%;
  left: 0%;
  cursor: pointer;
}
//电波
.element2 {
  position: absolute;
  cursor: pointer;
  width: 2%;
  transform: rotate(270deg);
  opacity: 0;
}
// 小唐人logo
.dialong3 {
  position: absolute;
  left: 93%;
  top: 2%;
  width: 6%;
  // height: 22%;
  cursor: pointer;
}

// 图标展示区域
.incobox {
  width: 10%;
  height: 20%;
  position: absolute;
  background: #d2d3d8;
  top: 6%;
  left: 3%;
  border-radius: 10px;
  opacity: 0.8;
}
.incobox > span {
  position: absolute;
  left: 50%;
}
// 正确和错误展示图标
.inco {
  width: 25%;
}
// 正确提示
.incoimg1 {
  width: 25%;
  position: absolute;
  top: 20%;
  left: 36%;
  z-index: 9999999999999;
  animation-name: incoimg1;
  animation-duration: 2s;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
  opacity: 0;
}
@keyframes incoimg1 {
  0% {
    opacity: 0;
  }
  60% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
// 错误提示
.incoimg2 {
  width: 25%;
  position: absolute;
  top: 27%;
  left: 35%;
  z-index: 9999999999999;
  animation-name: incoimg2;
  animation-duration: 2s;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
}
@keyframes incoimg2 {
  0% {
    opacity: 0;
  }
  60% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
/deep/ .el-dialog__header {
  background: #d7edfb !important;
  margin-right: 0 !important;
}

.logodialogue {
  position: absolute;
  right: 8%;
  top: 13%;
  width: 30%;
  background-color: #d7edfb;
  border-radius: 10px;
  border: 1px solid #eee;
  padding: 12px;
  font-size: 16px;
  letter-spacing: 1px;
  z-index: 99999999;
  text-indent: 2em;
}
.logodialoguex {
  position: absolute;
  top: 10%;
  right: -3%;
  width: 0px;
  height: 0px;
  border-top: 10px solid #d7edfb;
  border-left: 10px solid #d7edfb;
  border-right: 10px solid transparent;
  border-bottom: 10px solid transparent;
}
h3{
  font-size: calc(100vw * 16 / 1920);
}
h3>span{
  font-size: 16px;
}
h3>p{
  font-size: calc(100vw * 16 / 1920);
}
*{
   font-size: calc(100vw * 20 / 1920);
}
</style>
